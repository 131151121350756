import { useEffect } from "react";
import { Button } from "antd";
import { connect } from "react-redux";
//user imports
import PropertyDatabaseFilters from "./PropertyDatabaseFilters";
import AntdDatePicker from "./filters-componets/AntdDatePicker";
import { collectionFilters } from "views/app-views/Collections/CollectionFilters/CollectionFilters";
import {
  applyCollectionFilters,
  setCollectionFilteredData,
  resetCollectionFilters,
  changeCollectionFiltersData,
} from "redux/actions/Collection";
import ManualReviewFilters from "./ManualReviewFilters";

const SideNavFilters = (props) => {
  const {
    lessFilters,
    collectionsData,
    applyCollectionFilters,
    setCollectionFilteredData,
    resetCollectionFilters,
    collectionFiltersData,
    changeCollectionFiltersData,
    propertyDatabaseFiltersIsValid,
  } = props;

  const collectionFilterHandler = async (data, filters) => {
    applyCollectionFilters();
    const filteredData = await collectionFilters(data, filters);
    setCollectionFilteredData(filteredData);
  };

  useEffect(() => {
    if (collectionsData !== null && collectionFiltersData.date !== "") {
      collectionFilterHandler(collectionsData, collectionFiltersData);
    }
    //eslint-disable-next-line
  }, [collectionFiltersData, collectionsData]);

  const resetCollectionFiltersHandler = () => {
    resetCollectionFilters();
  };

  if (lessFilters) {
    return (
      <div className="sidenav-filters">
        <div className="sidenav-filters--div1">
          <Button
            type="link"
            style={{ padding: "0px" }}
            className="sidenav-filter-button"
          >
            Filter
          </Button>
          <div
            style={{ padding: "0px", cursor: "pointer" }}
            className="sidenav-filter-button"
            onClick={() => resetCollectionFiltersHandler()}
          >
            Reset all
          </div>
        </div>
        <AntdDatePicker
          title="From date"
          data={collectionFiltersData}
          setData={changeCollectionFiltersData}
          name="date"
        />
      </div>
    );
  }

  return (
    <>
      {propertyDatabaseFiltersIsValid ? (
        <PropertyDatabaseFilters />
      ) : (
        <ManualReviewFilters />
      )}
    </>
  );
};
const mapStateToProps = ({ propertyDatabase, collection }) => {
  const { lessFilters, propertyDatabaseFiltersIsValid } = propertyDatabase;
  const { collectionData, collectionsData, collectionFiltersData } = collection;
  return {
    lessFilters,
    collectionsData,
    collectionFiltersData,
    collectionData,
    propertyDatabaseFiltersIsValid,
  };
};

const mapDispatchToProps = {
  applyCollectionFilters,
  setCollectionFilteredData,
  resetCollectionFilters,
  changeCollectionFiltersData,
};
export default connect(mapStateToProps, mapDispatchToProps)(SideNavFilters);
