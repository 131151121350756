import {
  HomeOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "property database",
    path: `${APP_PREFIX_PATH}/home`,
    title: "Property database",
    icon: HomeOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "collections",
    path: `${APP_PREFIX_PATH}/collections`,
    title: "Collections",
    icon: EditOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "review",
    path: `${APP_PREFIX_PATH}/review`,
    title: "Need manual review",
    icon: ExclamationCircleOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "reports",
    path: `${APP_PREFIX_PATH}/reports`,
    title: "Trend reports",
    icon: FileTextOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
