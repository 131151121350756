import { useState } from "react";
import { Input } from "antd";

const InputValueBox = (props) => {
  const { minValue, maxValue, onValueChange, propertyFiltersData, name } =
    props;

  const [validationError, setValidationError] = useState(false);

  console.log(validationError);
  const minValueHandler = (e) => {
    let value = e.target.value.replaceAll(",", "");
    if (Number(value) === 0 || Number(value)) {
      setValidationError(false);
      onValueChange({
        ...propertyFiltersData,
        [name]: [Number(value), maxValue],
      });
    } else {
      setValidationError(true);
    }
  };

  const maxValueHandler = (e) => {
    let value = e.target.value.replaceAll(",", "");
    if (Number(value) === 0 || Number(value)) {
      onValueChange({
        ...propertyFiltersData,
        [name]: [minValue, Number(value)],
      });
    } else {
      setValidationError(true);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "8px",
        }}
      >
        <p>Min</p>
        <p>Max</p>
      </div>
      <div className="sidenav-input-wrapper">
        <div>
          <Input
            placeholder="min price"
            value={minValue.toLocaleString()}
            onChange={minValueHandler}
          />
        </div>
        <>&nbsp;</>
        <span style={{ margin: "0px 2px" }}>-</span>
        <>&nbsp;</>
        <div>
          <Input
            placeholder="max price"
            value={maxValue.toLocaleString()}
            onChange={maxValueHandler}
          />
        </div>
      </div>
      {/* {validationError && <p style={{ color: "red" }}>Numbers only</p>} */}
    </>
  );
};

export default InputValueBox;
