// import { useState, useEffect } from "react";
import { Button, Spin } from "antd";
import { connect } from "react-redux";
import SideNavCheckBox from "./filters-componets/SideNavCheckBox";
import SideNavSelectBox from "./filters-componets/SideNavSelectBox";
import SliderTitle from "./filters-componets/SliderTitle";
// import SwitchFilter from "./filters-componets/SwitchFilter";
import AntdDatePicker from "./filters-componets/AntdDatePicker";
import RangeSlider from "react-range-slider-input";
import {
  setFilteredData,
  applyPropertyFilters,
  resetFilters,
  changePropertyFiltersData,
  setPropertyFilterLoading,
} from "redux/actions/PropertyDatabase";
import {
  citiesFilterOption,
  propertyTypeFilterOptions,
  statusFilterOptions,
  antIcon,
  valueInMillion,
  roomsOption,
} from "configs/FiltersConfig";
import InputValueBox from "./filters-componets/InputValueBox";
import InputValue from "./filters-componets/InputValue";

const PropertyDatabaseFilters = (props) => {
  const {
    propertyFiltersMaxValues,
    propertyFiltersLoading,
    filterResetMessage,
    propertyFiltersData,
    resetFilters,
    changePropertyFiltersData,
    setPropertyFilterLoading,
  } = props;
  let priceMaxValue, arvMaxVale;
  if (propertyFiltersMaxValues.length > 0) {
    priceMaxValue = Number(propertyFiltersMaxValues[0]);
    arvMaxVale = Number(propertyFiltersMaxValues[5]);
  }

  // const [priceDefaultValue, setPriceDefaultValue] = useState([
  //   0,
  //   propertyFiltersData.price[1] === null
  //     ? 10000000
  //     : propertyFiltersData.price[1],
  // ]);

  // const [marks, setMarks] = useState({
  //   bedRoomMarks: {},
  //   bathRoomMarks: {},
  //   priceMarks: {},
  //   arvMarks: {},
  // });
  // useEffect(() => {
  //   if (propertyFiltersMaxValues.length > 0) {
  // setMarks({
  //   bedRoomMarks: {
  //     0: "Min",
  //     [bedroomsMaxValue]: "Max",
  //   },
  //   bathRoomMarks: {
  //     0: "Min",
  //     [bathRoomsMaxValue]: "Max",
  //   },
  //   priceMarks: {
  //     0: "Min",
  //     [priceMaxValue]: "Max",
  //   },
  //   arvMarks: {
  //     0: "Min",
  //     [arvMaxVale]: "Max",
  //   },
  // });
  //     setPriceDefaultValue([0, Number(propertyFiltersMaxValues[0])]);
  //   }

  //   //eslint-disable-next-line;
  // }, [propertyFiltersMaxValues.length]);

  const resetPropertyFiltersHanlder = () => {
    setPropertyFilterLoading();
    setTimeout(() => {
      resetFilters(propertyFiltersMaxValues);
    }, 300);
  };

  return (
    <>
      {propertyFiltersLoading ? (
        <div style={{ textAlign: "center", marginTop: "25px" }}>
          <Spin indicator={antIcon} />
        </div>
      ) : (
        <>
          {propertyFiltersMaxValues.length > 0 ? (
            <div className="sidenav-filters" style={{ position: "relative" }}>
              <div className="sidenav-filters--div1">
                <div
                  style={{ padding: "0px", cursor: "default" }}
                  className="sidenav-filter-button"
                >
                  Filter
                </div>
                <div
                  style={{ padding: "0px", cursor: "pointer" }}
                  className="sidenav-filter-button"
                  onClick={() => resetPropertyFiltersHanlder()}
                >
                  Reset all
                </div>
              </div>

              <InputValue
                value={propertyFiltersData.searchByName}
                onValueChange={changePropertyFiltersData}
                propertyFiltersData={propertyFiltersData}
                name="searchByName"
                title="Search by name"
              />

              <div className="sidenav-filters--div2">
                <p className="sidenav-heading1">Parish</p>
                <div className="sidenav-scrollbar">
                  <SideNavCheckBox
                    options={citiesFilterOption}
                    data={propertyFiltersData}
                    setData={changePropertyFiltersData}
                    name="parish"
                  />
                </div>
              </div>

              <SideNavSelectBox
                title="Property type"
                optionsList={propertyTypeFilterOptions}
                data={propertyFiltersData}
                setData={changePropertyFiltersData}
                name="propertyType"
                value={propertyFiltersData.propertyType}
                mode="multiple"
              />
              <SideNavSelectBox
                title="Status"
                optionsList={statusFilterOptions}
                data={propertyFiltersData}
                setData={changePropertyFiltersData}
                name="status"
                value={propertyFiltersData.status}
                mode="multiple"
              />
              <AntdDatePicker
                title="From date"
                data={propertyFiltersData}
                setData={changePropertyFiltersData}
                name="minDate"
              />
              <AntdDatePicker
                title="To date"
                data={propertyFiltersData}
                setData={changePropertyFiltersData}
                name="maxDate"
              />
              {/* To be added later
    
                  <div className="sidenav-filters--div4">
                    <p className="sidenav-heading1">Size (sq ft)</p>
                    <Slider
                      range
                      marks={marks}
                      defaultValue={[0, 1000]}
                      style={{ width: 200 }}
                    />
                  </div> */}

              <div className="sidenav-filters--div9">
                <SliderTitle
                  title="Price ($)"
                  minValue={valueInMillion(propertyFiltersData.price[0])}
                  maxValue={valueInMillion(propertyFiltersData.price[1])}
                />
                <RangeSlider
                  min={0}
                  max={priceMaxValue}
                  step={priceMaxValue / 200}
                  onInput={(value) =>
                    changePropertyFiltersData({
                      ...propertyFiltersData,
                      price: value,
                    })
                  }
                  defaultValue={[
                    propertyFiltersData.price[0],
                    propertyFiltersData.price[1] === null
                      ? priceMaxValue
                      : propertyFiltersData.price[1],
                  ]}
                />
              </div>

              <InputValueBox
                minValue={propertyFiltersData.price[0]}
                maxValue={propertyFiltersData.price[1]}
                onValueChange={changePropertyFiltersData}
                propertyFiltersData={propertyFiltersData}
                name="price"
              />

              <div className="sidenav-filters--div8">
                <SliderTitle
                  title="ARV"
                  minValue={valueInMillion(propertyFiltersData.arv[0])}
                  maxValue={valueInMillion(propertyFiltersData.arv[1])}
                />
                <RangeSlider
                  min={0}
                  max={arvMaxVale}
                  step={arvMaxVale / 200}
                  onInput={(value) =>
                    changePropertyFiltersData({
                      ...propertyFiltersData,
                      arv: value,
                    })
                  }
                  defaultValue={[
                    propertyFiltersData.arv[0],
                    propertyFiltersData.arv[1] === null
                      ? arvMaxVale
                      : propertyFiltersData.arv[1],
                  ]}
                />
              </div>
              <InputValueBox
                minValue={propertyFiltersData.arv[0]}
                maxValue={propertyFiltersData.arv[1]}
                onValueChange={changePropertyFiltersData}
                propertyFiltersData={propertyFiltersData}
                name="arv"
              />
              <div className="sidenav-filters--div8">
                <SliderTitle
                  title="Number of bedrooms"
                  minValue=""
                  maxValue=""
                />
                <SideNavCheckBox
                  options={roomsOption}
                  data={propertyFiltersData}
                  setData={changePropertyFiltersData}
                  name="bedrooms"
                />
              </div>
              <div className="sidenav-filters--div8">
                <SliderTitle
                  title="Number of bathrooms"
                  minValue=""
                  maxValue=""
                />
                <SideNavCheckBox
                  options={roomsOption}
                  data={propertyFiltersData}
                  setData={changePropertyFiltersData}
                  name="bathrooms"
                />
              </div>
              {/* <div style={{ marginTop: "20px" }}>
                <SwitchFilter
                  title={"Sold in the past"}
                  data={propertyFiltersData}
                  setData={changePropertyFiltersData}
                  name="soldInPast"
                  value={propertyFiltersData.soldInPast}
                  marginTopIsValid={true}
                />
              </div> */}
              {/* <SwitchFilter
                title="More landvaluation properties"
                data={propertyFiltersData}
                setData={changePropertyFiltersData}
                name="undefinedDataUnits"
                value={propertyFiltersData.undefinedDataUnits}
                marginTopIsValid={true}
              /> */}
            </div>
          ) : (
            <div className="filed-error-wrapper">
              {filterResetMessage ? (
                <div>
                  <p>Filters can't be applided</p>

                  <Button
                    type="link"
                    style={{ padding: "0px" }}
                    onClick={() => resetPropertyFiltersHanlder()}
                  >
                    Try Again
                  </Button>
                </div>
              ) : (
                <div className="filed-error-wrapper">
                  <p>Filters can't be applided</p>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ propertyDatabase }) => {
  const {
    data,
    propertyFiltersMaxValues,
    propertyFiltersLoading,
    filterResetMessage,
    propertyFiltersData,
    applyFiltersIsValid,
    propertyDatabaseFiltersIsValid,
  } = propertyDatabase;
  return {
    data,
    propertyFiltersMaxValues,
    propertyFiltersLoading,
    filterResetMessage,
    propertyFiltersData,
    applyFiltersIsValid,
    propertyDatabaseFiltersIsValid,
  };
};

const mapDispatchToProps = {
  setFilteredData,
  applyPropertyFilters,
  resetFilters,
  changePropertyFiltersData,
  setPropertyFilterLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertyDatabaseFilters);
