import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./PropertyDatabase`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/collections`}
          component={lazy(() => import(`./Collections`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/collections/:collectionId`}
          component={lazy(() => import(`./Collections/CollectionDetails`))}
          exact
        />

        <Route
          path={`${APP_PREFIX_PATH}/review`}
          component={lazy(() => import(`./ManualReview`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/review/details/:propertyId`}
          component={lazy(() => import(`./ManualReview/ManualReviewDetails`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/review/edit-details/:propertyId`}
          component={lazy(() =>
            import(`./ManualReview/EditManualReviewDetails`)
          )}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/reports`}
          component={lazy(() => import(`./Reports`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/reports/:reportId`}
          component={lazy(() => import(`./Reports/ReportDetails`))}
          exact
        />
        {/* <Route
          path={`${APP_PREFIX_PATH}/reports/new-report`}
          component={lazy(() => import(`./Reports/NewReport`))}
          exact
        /> */}
        <Route
          path={`${APP_PREFIX_PATH}/home/propertydetails/:propertyId`}
          component={lazy(() => import(`./PropertyDatabase/PropertyDetails`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/home/edit-details/:propertyId`}
          component={lazy(() =>
            import(`./PropertyDatabase/EditPropertyDetails`)
          )}
          exact
        />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
