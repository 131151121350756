import { FOR_SALE, FOR_RENT, RENTED } from "configs/FiltersContants";

const statusResults = (item, filteringData) => {
  let availabilityCheckerForSale = false;
  let availabilityCheckerForRent = false;
  let availabilityCheckerForRented = false;
  let availabilityCheckerFSAndFR = false;
  let availabilityCheckerFSAndR = false;
  let availabilityCheckerFRAndR = false;
  let availabilityCheckerAll = false;
  let availabilityForSale = filteringData.status.some(
    (item) => item === FOR_SALE
  );
  let availabilityForRent = filteringData.status.some(
    (item) => item === FOR_RENT
  );

  let availabilityForRented = filteringData.status.some(
    (item) => item === RENTED
  );
  if (availabilityForSale && availabilityForRent && availabilityForRented) {
    availabilityCheckerAll = true;
  } else if (availabilityForSale && availabilityForRent) {
    availabilityCheckerFSAndFR = true;
  } else if (availabilityForSale && availabilityForRented) {
    availabilityCheckerFSAndR = true;
  } else if (availabilityForRent && availabilityForRented) {
    availabilityCheckerFRAndR = true;
  } else if (availabilityForRent) {
    availabilityCheckerForRent = true;
  } else if (availabilityForSale) {
    availabilityCheckerForSale = true;
  } else if (availabilityForRented) {
    availabilityCheckerForRented = true;
  }
  return (
    (availabilityCheckerForSale
      ? item.property_database_listings[0].is_sale === true
      : item) &&
    (availabilityCheckerForRent
      ? item.property_database_listings[0].is_rent === true
      : item) &&
    (availabilityCheckerForRented
      ? item.property_database_listings[0].rented === true
      : item) &&
    (availabilityCheckerAll ? item : item) &&
    (availabilityCheckerFSAndFR
      ? item.property_database_listings[0].is_sale === true ||
        item.property_database_listings[0].is_rent === true
      : item) &&
    (availabilityCheckerFSAndR
      ? item.property_database_listings[0].is_sale === true ||
        item.property_database_listings[0].rented === true
      : item) &&
    (availabilityCheckerFRAndR
      ? item.property_database_listings[0].is_rent === true ||
        item.property_database_listings[0].rented === true
      : item)
  );
};

export const manualReviewFilters = (
  data,
  filteringData,
  bedRoomsMaxValue,
  bathRoomsMaxValue
) => {
  return new Promise((resolve, reject) => {
    let filteredData;
    if (filteringData.importedToPropertiesDatabase) {
      filteredData = data.filter((item) => {
        return item.added_to_properties === true;
      });
    } else {
      filteredData = data.filter((item) => {
        const CGZ = filteringData.cities.length > 0;
        const PGZ = filteringData.propertyType.length > 0;
        const AGZ = filteringData.status.length > 0;
        const CNGZ = filteringData.cities.length === 0;
        const PNGZ = filteringData.propertyType.length === 0;
        const ANGZ = filteringData.status.length === 0;
        if (
          item.manual_review_listings.length === 0 ||
          item.bathrooms === null ||
          item.bathrooms === null ||
          item.property_type === null ||
          item.parish === null
        ) {
          return false;
        }
        let bedRoomsEqualsFour = filteringData.bedrooms.some((el) => el === 4);
        if (bedRoomsEqualsFour) {
          for (let i = 5; i <= bedRoomsMaxValue; i++) {
            filteringData.bedrooms.push(i);
          }
        }
        let bathRoomsEqualsFour = filteringData.bathrooms.some(
          (el) => el === 4
        );
        if (bathRoomsEqualsFour) {
          for (let i = 5; i <= bathRoomsMaxValue; i++) {
            filteringData.bathrooms.push(i);
          }
        }
        let dateAdded = new Date(item.manual_review_listings[0].date_added);
        let dateMinValue = filteringData.minDate;
        let dateMaxValue = filteringData.maxDate;

        if (CGZ && PGZ && AGZ) {
          const statusResultsResponse = statusResults(item, filteringData);
          return (
            item.added_to_properties === false &&
            (filteringData.bedrooms.length > 0
              ? item.bedrooms ===
                filteringData.bedrooms.find((el) => el === item.bedrooms)
              : item) &&
            (filteringData.bathrooms.length > 0
              ? item.bathrooms ===
                filteringData.bathrooms.find((el) => el === item.bathrooms)
              : item) &&
            dateAdded >= dateMinValue &&
            dateAdded <= dateMaxValue &&
            item.manual_review_listings[0].price >= filteringData.price[0] &&
            item.manual_review_listings[0].price <= filteringData.price[1] &&
            item.property_type ===
              filteringData.propertyType.find(
                (el) => el === item.property_type
              ) &&
            item.parish ===
              filteringData.cities.find((el) => el === item.parish) &&
            statusResultsResponse
          );
        }

        if (CGZ && PGZ && ANGZ) {
          return (
            item.added_to_properties === false &&
            (filteringData.bedrooms.length > 0
              ? item.bedrooms ===
                filteringData.bedrooms.find((el) => el === item.bedrooms)
              : item) &&
            (filteringData.bathrooms.length > 0
              ? item.bathrooms ===
                filteringData.bathrooms.find((el) => el === item.bathrooms)
              : item) &&
            dateAdded >= dateMinValue &&
            dateAdded <= dateMaxValue &&
            item.manual_review_listings[0].price >= filteringData.price[0] &&
            item.manual_review_listings[0].price <= filteringData.price[1] &&
            item.property_type ===
              filteringData.propertyType.find(
                (el) => el === item.property_type
              ) &&
            item.parish ===
              filteringData.cities.find((el) => el === item.parish)
          );
        }
        if (CGZ && PNGZ && AGZ) {
          const statusResultsResponse = statusResults(item, filteringData);
          return (
            item.added_to_properties === false &&
            (filteringData.bedrooms.length > 0
              ? item.bedrooms ===
                filteringData.bedrooms.find((el) => el === item.bedrooms)
              : item) &&
            (filteringData.bathrooms.length > 0
              ? item.bathrooms ===
                filteringData.bathrooms.find((el) => el === item.bathrooms)
              : item) &&
            dateAdded >= dateMinValue &&
            dateAdded <= dateMaxValue &&
            item.manual_review_listings[0].price >= filteringData.price[0] &&
            item.manual_review_listings[0].price <= filteringData.price[1] &&
            item.parish ===
              filteringData.cities.find((el) => el === item.parish) &&
            statusResultsResponse
          );
        }
        if (CGZ && PNGZ && ANGZ) {
          return (
            item.added_to_properties === false &&
            (filteringData.bedrooms.length > 0
              ? item.bedrooms ===
                filteringData.bedrooms.find((el) => el === item.bedrooms)
              : item) &&
            (filteringData.bathrooms.length > 0
              ? item.bathrooms ===
                filteringData.bathrooms.find((el) => el === item.bathrooms)
              : item) &&
            dateAdded >= dateMinValue &&
            dateAdded <= dateMaxValue &&
            item.manual_review_listings[0].price >= filteringData.price[0] &&
            item.manual_review_listings[0].price <= filteringData.price[1] &&
            item.parish ===
              filteringData.cities.find((el) => el === item.parish)
          );
        }
        if (CNGZ && PGZ && AGZ) {
          const statusResultsResponse = statusResults(item, filteringData);
          return (
            item.added_to_properties === false &&
            (filteringData.bedrooms.length > 0
              ? item.bedrooms ===
                filteringData.bedrooms.find((el) => el === item.bedrooms)
              : item) &&
            (filteringData.bathrooms.length > 0
              ? item.bathrooms ===
                filteringData.bathrooms.find((el) => el === item.bathrooms)
              : item) &&
            dateAdded >= dateMinValue &&
            dateAdded <= dateMaxValue &&
            item.manual_review_listings[0].price >= filteringData.price[0] &&
            item.manual_review_listings[0].price <= filteringData.price[1] &&
            item.property_type ===
              filteringData.propertyType.find(
                (el) => el === item.property_type
              ) &&
            statusResultsResponse
          );
        }
        if (CNGZ && PGZ && ANGZ) {
          return (
            item.added_to_properties === false &&
            (filteringData.bedrooms.length > 0
              ? item.bedrooms ===
                filteringData.bedrooms.find((el) => el === item.bedrooms)
              : item) &&
            (filteringData.bathrooms.length > 0
              ? item.bathrooms ===
                filteringData.bathrooms.find((el) => el === item.bathrooms)
              : item) &&
            dateAdded >= dateMinValue &&
            dateAdded <= dateMaxValue &&
            item.manual_review_listings[0].price >= filteringData.price[0] &&
            item.manual_review_listings[0].price <= filteringData.price[1] &&
            item.property_type ===
              filteringData.propertyType.find((el) => el === item.property_type)
          );
        }
        if (CNGZ && PNGZ && AGZ) {
          const statusResultsResponse = statusResults(item, filteringData);
          return (
            item.added_to_properties === false &&
            (filteringData.bedrooms.length > 0
              ? item.bedrooms ===
                filteringData.bedrooms.find((el) => el === item.bedrooms)
              : item) &&
            (filteringData.bathrooms.length > 0
              ? item.bathrooms ===
                filteringData.bathrooms.find((el) => el === item.bathrooms)
              : item) &&
            dateAdded >= dateMinValue &&
            dateAdded <= dateMaxValue &&
            item.manual_review_listings[0].price >= filteringData.price[0] &&
            item.manual_review_listings[0].price <= filteringData.price[1] &&
            statusResultsResponse
          );
        }

        if (CNGZ && PNGZ && ANGZ) {
          return (
            item.added_to_properties === false &&
            (filteringData.bedrooms.length > 0
              ? item.bedrooms ===
                filteringData.bedrooms.find((el) => el === item.bedrooms)
              : item) &&
            (filteringData.bathrooms.length > 0
              ? item.bathrooms ===
                filteringData.bathrooms.find((el) => el === item.bathrooms)
              : item) &&
            dateAdded >= dateMinValue &&
            dateAdded <= dateMaxValue &&
            item.manual_review_listings[0].price >= filteringData.price[0] &&
            item.manual_review_listings[0].price <= filteringData.price[1]
          );
        }

        return item;
      });
    }
    resolve(filteredData);
  });
};
