const PropertyDetailsExternalLinks = ({ propertyData }) => {
  const { youtube_id, vimeo_id, virtual_tour_url } = propertyData;
  return (
    <div style={{ marginTop: "30px" }}>
      <p className="property-database-heading7">External links</p>
      <div className="property-database-exlinks">
        <a href={youtube_id} target="_black">
          <p>Youtube video</p>
        </a>
        <a href={vimeo_id} target="_black">
          <p>Vimeo video</p>
        </a>
        <a href={virtual_tour_url} target="_black">
          <p>Virtual tour</p>
        </a>
      </div>
    </div>
  );
};

export default PropertyDetailsExternalLinks;
