import React, { useEffect } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import {
  APP_PREFIX_PATH,
  AUTH_PREFIX_PATH,
  REDIRECT_PREFIX_PATH,
  COLLECTION_REPORT,
} from "configs/AppConfig";
import { ConfigProvider } from "antd";
import useBodyClass from "hooks/useBodyClass";
import { supabase_authenticated } from "redux/actions/Auth";
import { supabase } from "auth/SupabaseClient";
import LoaderLayout from "layouts/loader-layout";
import CollectionReportLayout from "layouts/collection-report-layout";
import PropertyDetails from "./app-views/PropertyDatabase/PropertyDetails";
import ManualReviewDetails from "./app-views/ManualReview/ManualReviewDetails";
import ReportDetails from "./app-views/Reports/ReportDetails";

function RouteInterceptor({ children, isAuthenticated, secondPath, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
function RouteInterceptorRedirect({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          <Redirect
            to={{
              pathname: `${APP_PREFIX_PATH}/home`,
              state: { from: location },
            }}
          />
        ) : (
          children
        )
      }
    />
  );
}

export const Views = (props) => {
  const { locale, session, location, direction, supabase_authenticated } =
    props;
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);

  useEffect(() => {
    const session = supabase.auth.session();
    supabase_authenticated(session);
    supabase.auth.onAuthStateChange((event, session) => {
      supabase_authenticated(session);
    });
    //eslint-disable-next-line
  }, []);

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path={REDIRECT_PREFIX_PATH}>
            <Redirect to={`${REDIRECT_PREFIX_PATH}/app`} />
          </Route>
          <Route exact path={`${COLLECTION_REPORT}/:collectionId`}>
            <CollectionReportLayout />
          </Route>
          <Route exact path={`${APP_PREFIX_PATH}/review/details/:propertyId`}>
            <AppLayout direction={direction} location={location} >
              <ManualReviewDetails />
            </AppLayout > 
          </Route>
          <Route exact path={`${APP_PREFIX_PATH}/home/propertydetails/:propertyId`}>
            <AppLayout direction={direction} location={location}  >
              <PropertyDetails />
            </AppLayout>
          </Route>
          <Route exact path={`${APP_PREFIX_PATH}/reports/:reportId`}>
            <AppLayout direction={direction} location={location} >
              <ReportDetails />
            </AppLayout>
          </Route>
          <Route exact path="/">
            <Redirect to={APP_PREFIX_PATH} />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={session}>
            <AppLayout direction={direction} location={location} />
          </RouteInterceptor>
          <RouteInterceptorRedirect
            path={`${REDIRECT_PREFIX_PATH}/app`}
            isAuthenticated={session}
          >
            <LoaderLayout />
          </RouteInterceptorRedirect>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { session } = auth;
  return { locale, direction, session };
};
const mapDispatchToProps = {
  supabase_authenticated,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Views));
