import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { SIGNIN, SIGNOUT } from "../constants/Auth";
import { showAuthMessage, showAuthSuccessMessage } from "../actions/Auth";
import SupabaseService from "services/SupabaseService";

export function* signInWithSBEmail() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    const { email } = payload;
    try {
      const { error } = yield call(SupabaseService.signInWithMagicLink, email);

      if (error) throw error;
      else {
        yield put(showAuthSuccessMessage("Please check your Inbox"));
      }
    } catch (error) {
      yield put(showAuthMessage(error.message || error.error_description));
    }
  });
}

export function* signOutMain() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      const { error } = yield call(SupabaseService.signOutRequest);
      if (error) throw error;
    } catch (error) {
      yield put(showAuthMessage(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(signInWithSBEmail), fork(signOutMain)]);
}
