import { Modal } from "antd";
import { useHistory } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export const ModalContent = (props) => {
  const { heading1, heading2 } = props;
  return (
    <div className="custom-modal-div">
      <div>
        <ExclamationCircleOutlined className="custom-modal-icon" />
      </div>
      <div style={{ marginLeft: "10px" }}>
        <p className="custom-modal-heading1">{heading1}</p>
        <p className="custom-modal-heading2">{heading2}</p>
      </div>
    </div>
  );
};

export const DeleteErrorModal = (props) => {
  const {
    modalIsValid,
    hideModal,
    deleteDataByID,
    id,
    closeCollection,
    closeCollectionIsValid,
    resetRemoveButtonState,
  } = props;
  const okHandler = () => {
    hideModal();
    deleteDataByID(id);
    if (closeCollectionIsValid) {
      closeCollection();
      resetRemoveButtonState();
    }
  };
  const cancelHandler = () => hideModal();
  return (
    <Modal
      open={modalIsValid}
      onOk={okHandler}
      onCancel={cancelHandler}
      style={{
        top: 20,
      }}
      okText="Confirm"
      okButtonProps={{
        style: { textTransform: "uppercase" },
      }}
      cancelButtonProps={{
        style: { textTransform: "uppercase" },
      }}
    >
      <ModalContent
        heading1="Are you sure you want to delete this?"
        heading2="This action can't be undone"
      />
    </Modal>
  );
};

export const CloseCollectionModal = (props) => {
  const {
    modalIsValid,
    hideModal,
    heading1,
    heading2,
    closeCollection,
    resetRemoveButtonState,
    danger,
    redirectToCollectionPage,
    setCollectionDataByIdNull,
  } = props;
  const history = useHistory();
  const okHandler = () => {
    setCollectionDataByIdNull();
    resetRemoveButtonState();
    closeCollection();
    hideModal(false);
    if (redirectToCollectionPage.isValid) {
      history.push(`/app/collections/${redirectToCollectionPage.collectionId}`);
    }
  };
  const cancelHandler = () => hideModal(false);
  return (
    <Modal
      open={modalIsValid}
      onOk={okHandler}
      onCancel={cancelHandler}
      style={{
        top: 20,
      }}
      okText="Confirm"
      okButtonProps={{
        danger,
        style: { textTransform: "uppercase" },
      }}
      cancelButtonProps={{
        style: { textTransform: "uppercase" },
      }}
    >
      <ModalContent heading1={heading1} heading2={heading2} />
    </Modal>
  );
};
