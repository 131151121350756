export const GET_REPORTS_DATA = "GET_REPORTS_DATA";
export const GET_REPORTS_DATA_SUCCESS = "GET_REPORTS_DATA_SUCCESS";
export const GET_REPORTS_DATA_FAILED = "GET_REPORTS_DATA_FAILED";
export const SAVE_REPORT_CONTENT = "SAVE_REPORT_CONTENT";
export const SAVE_REPORT_CONTENT_SUCCESS = "SAVE_REPORT_CONTENT_SUCCESS";
export const SAVE_REPORT_CONTENT_FAILED = "SAVE_REPORT_CONTENT_FAILED";
export const SAVE_REPORT = "SAVE_REPORT";
export const SAVE_REPORT_SUCCESS = "SAVE_REPORT_SUCCESS";
export const SAVE_REPORT_FAILED = "SAVE_REPORT_FAILED";
export const SET_TRENDS_REPORTS_PAGINATION = "SET_TRENDS_REPORTS_PAGINATION";
export const GET_SALES_DATA = "GET_SALES_DATA";
export const GET_SALES_DATA_SUCCESS = "GET_SALES_DATA_SUCCESS";
export const GET_SALES_DATA_FAILED = "GET_SALES_DATA_FAILED";
export const SET_NEW_REPROT_VALID = "SET_NEW_REPROT_VALID";
export const SET_NEW_REPROT_IN_VALID = "SET_NEW_REPROT_IN_VALID";
export const SET_CHART_COMMENT_VALUE = "SET_CHART_COMMENT_VALUE";
export const SET_ADD_NEW_CHART_VALID = "SET_ADD_NEW_CHART_VALID";
export const SET_ADD_NEW_CHART_IN_VALID = "SET_ADD_NEW_CHART_IN_VALID";
export const SET_REPORT_STEP = "SET_REPORT_STEP";
export const SET_REPORT_INFO = "SET_REPORT_INFO";
export const SET_REPORT_CONTENT = "SET_REPORT_CONTENT";
export const SET_LOCATIONS_OPTIONS = "SET_LOCATIONS_OPTIONS";
export const SET_PRICE_BRACKET_OPTIONS = "SET_PRICE_BRACKET_OPTIONS";
export const SET_CHART_LABELS = "SET_CHART_LABELS";
export const SET_CHART_DATA_LOADING = "SET_CHART_DATA_LOADING";
export const SET_CHART_DATA = "SET_CHART_DATA";
export const SET_REPORT_CONTENT_TITLE_IS_VALID =
  "SET_REPORT_CONTENT_TITLE_IS_VALID";
export const SET_REPORT_INFO_TITLE_IS_VALID = "SET_REPORT_INFO_TITLE_IS_VALID";
export const GET_TREND_REPORT_DETAILS = "GET_TREND_REPORT_DETAILS";
export const GET_TREND_REPORT_DETAILS_SUCCESS =
  "GET_TREND_REPORT_DETAILS_SUCCESS";
export const GET_TREND_REPORT_DETAILS_FAILED =
  "GET_TREND_REPORT_DETAILS_FAILED";
export const SET_TREND_REPORT_DETAILS_NULL = "SET_TREND_REPORT_DETAILS_NULL";
export const SET_MARKET_ANALYSIS_IS_VALID = "SET_MARKET_ANALYSIS_IS_VALID";
export const SET_REPORT_CONTENT_LOCATION_IS_VALID =
  "SET_REPORT_CONTENT_LOCATION_IS_VALID";
export const SET_INITITAL_REPORT_DETAILS = "SET_INITITAL_REPORT_DETAILS";
export const SET_SAVE_REPORT_SUCCESS_INVALID =
  "SET_SAVE_REPORT_SUCCESS_INVALID";
export const SET_SAVE_REPORT_ERROR_NULL = "SET_SAVE_REPORT_ERROR_NULL";
export const SET_SHOW_TABLE_IS_VALID = "SET_SHOW_TABLE_IS_VALID";
export const SET_NEW_CHART = "SET_NEW_CHART";
export const REMOVE_CHART = "REMOVE_CHART";
export const REMOVE_ALL_CHARTS = "REMOVE_ALL_CHARTS";
export const DELETE_MULTIPLE_REPORTS = "DELETE_MULTIPLE_REPORTS";
export const DELETE_MULTIPLE_REPORTS_SUCCESS =
  "DELETE_MULTIPLE_REPORTS_SUCCESS";
export const DELETE_MULTIPLE_REPORTS_FAILED = "DELETE_MULTIPLE_REPORTS_FAILED";
export const HIDE_DELETE_MULTIPLE_REPORTS_ERROR =
  "HIDE_DELETE_MULTIPLE_REPORTS_ERROR";
