import { useState } from "react";
import { Select } from "antd";

const SideNavSelectBox = (props) => {
  const { title, optionsList, data, setData, name, value, mode } = props;
  const [open, setOpen] = useState(false);
  const selectProps = {
    mode,
    style: {
      width: 216,
    },
    value,
    options: optionsList,
    title: " ",
    onChange: (newValue) => {
      setData({ ...data, [name]: newValue });
      setOpen(false);
    },
    placeholder: "All",
    virtual: false,
    // maxTagCount: "responsive",
  };
  const dropDownVisible = (visible) => {
    setOpen(visible);
  };
  return (
    <div className="sidenav-filters--div3">
      <p className="sidenav-heading1">{title}</p>
      <Select
        {...selectProps}
        open={open}
        onDropdownVisibleChange={(visible) => dropDownVisible(visible)}
        getPopupContainer={(trigger) => trigger.parentElement}
      ></Select>
    </div>
  );
};

export default SideNavSelectBox;
