const MainWrapper = (props) => {
  const { marginTop } = props;
  return (
    <div className="property-database-wrapper" style={{ marginTop }}>
      {props.children}
    </div>
  );
};

export const ReportWrapper = (props) => {
  const { marginTop, scrollRef } = props;
  return (
    <div ref={scrollRef} className="reports-wrapper" style={{ marginTop }}>
      {props.children}
    </div>
  );
};

export default MainWrapper;
