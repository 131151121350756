import { Table } from "antd";
import { dateFormatHandler } from "configs/TableConstants";

const columns = [
  {
    title: "Sold",
    render: () => <p className="property-database-heading8">Sold</p>,
  },
  {
    title: "Price",
    dataIndex: "price",
    render: (price) => {
      if (price) {
        return (
          <p className="property-database-heading8">
            ${price.toLocaleString()}
          </p>
        );
      } else {
        return <></>;
      }
    },
  },
  {
    title: "Date",
    dataIndex: "registration_date",
    render: (registration_date) => {
      const dateValue = dateFormatHandler(new Date(registration_date));
      return <p className="property-database-heading8">{dateValue}</p>;
    },
  },
];
const PropertyDetailsTimeline = ({ propertyData }) => {
  return (
    <div style={{ marginTop: "30px" }}>
      <p className="property-database-heading7">Timeline</p>
      <Table
        dataSource={propertyData.sales}
        columns={columns}
        rowKey="sales_id"
        tableLayout="fixed"
        pagination={false}
      />
    </div>
  );
};

export default PropertyDetailsTimeline;
