import { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Typography, Input, Divider, Spin } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import Label from "components/layout-components/Label";
import MainWrapper from "components/property-database-compnents/MainWrapper";
import {
  getTrendReportDetails,
  setTrendReportDetailsNull,
  getSalesData
} from "redux/actions/Report";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import SingleReportDetails from "./SingleReportDetails";

const ReportDetails = ({
  reportDetails,
  reportDetailsErrorMessage,
  getTrendReportDetails,
  setTrendReportDetailsNull,
  salesData,
  getSalesData
}) => {
  const { reportId } = useParams();
  let reportDetailObj;
  if (reportDetails) {
    reportDetailObj = reportDetails[0];
  }

  const backToReports = (
    <div style={{ margin: "15px 0" }}>
      <Link to={`${APP_PREFIX_PATH}/reports`} className="back-to-database">
        <LeftOutlined style={{ fontSize: "12px" }} />{" "}
        <span> Back to Report List</span>
      </Link>
    </div>
  );

  useEffect(() => {
    if (salesData === null) {
      getSalesData();
    }
    getTrendReportDetails(reportId);
    return () => {
      setTrendReportDetailsNull();
    };
    //eslint-disable-next-line
  }, []);
  const inputStyles = {color:'#000000', fontSize:"600"}

  return (
    <>
      {reportDetails && salesData ? (
        <>
          {backToReports}
          <MainWrapper>
            <Typography className="property-database-heading1">
              New trend report
            </Typography>
            <div className="form-row">
              <div className="form-row-left-el">
                <Label title="Title" />
                <Input
                  value={reportDetailObj.title}
                  disabled={true}
                  style={inputStyles}
                  // onChange={(e) => changeHandler(e, "title")}
                  // style={{ borderColor: reportInfoTitleIsValid || "red" }}
                />
              </div>
              <div className="form-row-right-el">
                <Label title="Contact Name" />
                <Input
                  value={reportDetailObj.contact_name}
                  disabled={true}
                  style={inputStyles}
                  // onChange={(e) => changeHandler(e, "contact_name")}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-row-left-el">
                <Label title="Contact Email" />
                <Input
                  value={reportDetailObj.contact_email}
                  disabled={true}
                  style={inputStyles}
                  // onChange={(e) => changeHandler(e, "contact_email")}
                />
              </div>
              <div className="form-row-right-el">
                <Label title="Contact Phone" />
                <Input
                  value={reportDetailObj.contact_phone}
                  disabled={true}
                  style={inputStyles}
                  // onChange={(e) => changeHandler(e, "contact_phone")}
                />
              </div>
            </div>
            <Divider className="new-report-divider-style" />
            {reportDetails[0].report_content.map((item, index) => (
              <SingleReportDetails
                singleReportContent={item}
                key={`CHART-DETAILS-${index}`}
              />
            ))}
          </MainWrapper>
        </>
      ) : (
        <div className="loader-center">
          {reportDetailsErrorMessage ? (
            <Typography>{reportDetailsErrorMessage}</Typography>
          ) : (
            <Spin />
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ report }) => {
  const {
    reportDetails,
    reportDetailsLoading,
    reportDetailsErrorMessage,
    salesData,
  } = report;
  return {
    reportDetails,
    reportDetailsLoading,
    reportDetailsErrorMessage,
    salesData,
  };
};

const mapDispatchToProps = {
  getTrendReportDetails,
  setTrendReportDetailsNull,
  getSalesData
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportDetails);
