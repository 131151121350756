import { Button } from "antd";
import { useHistory } from "react-router-dom";
//user-imports
import { APP_PREFIX_PATH } from "configs/AppConfig";
import {
  latestListingsFinder,
  latestSalesFinder,
} from "configs/TableConstants";
const PropertyDetailsHeading = (props) => {
  const {
    propertyData,
    deleteDataLoading,
    showModal,
    editManualReviewPropertyIsValid,
    session,
  } = props;
  const history = useHistory();
  const {
    name,
    property_type,
    parish,
    arv,
    assessment_number,
    address,
    properties_id,
  } = propertyData;
  let price = null;
  let propertyStatus;
  if (propertyData.property_database_listings.length > 0) {
    const listingRecord = latestListingsFinder(
      propertyData.property_database_listings
    );
    price = listingRecord.price.toLocaleString();
    if (listingRecord.is_rent) propertyStatus = "For Rent";
    else if (listingRecord.is_sale) propertyStatus = "For Sale";
    else propertyStatus = null;
  } else if (propertyData.sales.length > 0) {
    const salesRecord = latestSalesFinder(propertyData.sales);
    price = salesRecord.price.toLocaleString();
  }
  const editButtonHandler = () => {
    if (editManualReviewPropertyIsValid) {
      history.push(`${APP_PREFIX_PATH}/review/edit-details/${properties_id}`);
    } else {
      history.push(`${APP_PREFIX_PATH}/home/edit-details/${assessment_number}`);
    }
  };
  return (
    <div className="property-details-div1">
      <div className="property-details-div1-sub1">
        <p className="property-database-heading4">{name}</p>
        <p className="property-database-heading5 text-format">
          {property_type} | {parish}
        </p>

        <p className="property-database-heading5">{propertyStatus}</p>
        <p className="property-database-heading5">{address}</p>
        <p className="property-database-heading5">{assessment_number}</p>
      </div>
      <div className="property-details-div1-sub2">
        <p className="property-database-heading6">
          {price ? `$${price}` : "N/A"}
        </p>
        <p className="property-database-heading5">
          ARV: {arv ? `$${arv.toLocaleString()}` : "N/A"}
        </p>
        {session && (
          <div style={{ marginTop: "5px" }}>
            <Button
              danger
              type="primary"
              style={{ marginRight: "10px" }}
              className="button-text"
              onClick={() => showModal()}
              loading={deleteDataLoading}
            >
              Delete
            </Button>

            <Button
              type="primary"
              onClick={editButtonHandler}
              className="button-text"
            >
              Edit
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PropertyDetailsHeading;
