export const NUMBER_OF_SALES = "NUMBER_OF_SALES";
export const SALES_VOLUME = "SALES_VOLUME";
export const MEDIAN_SALES_PRICE = "MEDIAN_SALES_PRICE";
export const AVERAGE_SALES_PRICE = "AVERAGE_SALES_PRICE";

export const LOCATION_BY_PARISH = "By parish";
export const LOCATION_BY_REGION = "By region";
export const LOCATION_ALL = "All";
export const LOCATION_VALUE_ALL = "LOCATION_VALUE_ALL";
export const LOCATION_BURMUDA = "LOCATION_BURMUDA";
//bracet-contants
export const PRICE_BRACKET = "Price bracket";
export const ARV_BRACKET = "ARV bracket";

export const BRACKET_ALL = "BRACKET_ALL";

//price-constants
export const PRICE_ALL = "PRICE_ALL";
export const PRICE_STARTER = "0-750000";
export const PRICE_MID_MARKET = "750000-1500000";
export const PRICE_HIGH_MARKET = "1500000-2500000";
export const PRICE_LUXURY = "2500000-5000000";
export const PRICE_TOP_LUXURY = "5000000-Infinity";

//ARV-constants
export const ARV_ALL = "ARV_ALL";
export const ARV_STARTER = "0-33500";
export const ARV_MID_MARKER = "33500-63000";
export const ARV_HIGH_MARKET = "63000-126000";
export const ARV_LUXURY = "126000-Infinity";

export const YEARLY = "Yearly";
export const QUARTERLY = "Quarterly";
export const YEAR_TO_DATE = "Year to date";
export const YEAR_OVER_YEAR = "Year over Year";
export const QUARTER_TO_QUARTER = "Quarter to Quarter";
export const QUARTER_OVER_QUARTER = "Quarter over Quarter";

//Parish-all

export const PARISH_ALL =
  "Sandys,Southampton,Warwick,Paget,Pembroke,Devonshire,Smiths,Hamilton,St. George's";

//by-region-constants
export const WEST = "Sandys,Warwick,Southampton";
export const CENTRAL = "Paget,Pembroke,Devonshire";
export const EAST = "Smiths,Hamilton,St. George's";
export const REGION_ALL = `${WEST},${CENTRAL},${EAST}`;

export const regionAllValues = [[WEST], [CENTRAL], [EAST]];

//category-constants
export const CATEGORY_ALL = "CATEGORY_ALL";
export const HOUSE = "House";
export const CONDO = "Condo";
export const APARTMENT = "Apartment";
export const COMMERCIAL = "Commercial";
export const LAND = "Land";
export const FRACTIONAL = "Fractional";

//Charts

export const CHART_1 = "CHART_1";
export const CHART_2 = "CHART_2";
export const CHART_3 = "CHART_3";
export const CHART_4 = "CHART_4";
export const CHART_5 = "CHART_5";
