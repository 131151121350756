import React, { useState, Fragment, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, Grid } from "antd";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT } from "constants/ThemeConstant";
import utils from "utils";
import { onMobileNavToggle } from "redux/actions/Theme";
import SideNavFilters from "components/SideNavFilter";

const { useBreakpoint } = Grid;

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    onMobileNavToggle,
    filters,
  } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  const location = useLocation();
  const navValue = navigationConfig.find((_item) =>
    location.pathname.startsWith(_item.path)
  );
  let key = null;
  if (navValue !== undefined) key = navValue.key;
  const [selectedKey, setSelectedKey] = useState(key);

  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };
  useEffect(() => {
    if (key !== null) {
      setSelectedKey(
        navigationConfig.find((_item) =>
          location.pathname.startsWith(_item.path)
        ).key
      );
    }
    //eslint-disable-next-line
  }, [location]);
  return (
    <Fragment>
      <p className="menu-heading">Menu</p>
      <Menu
        theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
        mode="inline"
        style={{ height: "auto", borderRight: 0 }}
        defaultSelectedKeys={[routeInfo?.key]}
        defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
        className={hideGroupTitle ? "hide-group-title" : ""}
        selectedKeys={[selectedKey]}
      >
        {navigationConfig.map((menu) => (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span className="sidenav-title-thin">{menu?.title}</span>
            {menu.path ? (
              <Link onClick={() => closeMobileNav()} to={menu.path} />
            ) : null}
          </Menu.Item>
        ))}
      </Menu>
      {filters && <SideNavFilters />}
    </Fragment>
  );
};

const MenuContent = (props) => {
  return <SideNavContent {...props} />;
};

const mapStateToProps = ({ theme, propertyDatabase }) => {
  const { sideNavTheme, topNavColor } = theme;
  const { filters } = propertyDatabase;
  return { sideNavTheme, topNavColor, filters };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
