import { Layout, Grid } from "antd";
import { Suspense } from "react";
import Loading from "components/shared-components/Loading";
import HeaderNav from "components/layout-components/HeaderNav";
import MobileNav from "components/layout-components/MobileNav";
import React from "react";
import utils from "utils";
import CollectionReportViews from "views/collection-report-views";

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const CollectionReportLayout = () => {
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes("lg");

  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Layout>
        <HeaderNav isMobile={isMobile} />
        <Layout>
          <Content>
            <CollectionReportViews />
          </Content>
        </Layout>
        {isMobile && <MobileNav />}
      </Layout>
    </Suspense>
  );
};

export default CollectionReportLayout;
