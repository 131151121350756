import {
  YEARLY,
  CATEGORY_ALL,
  BRACKET_ALL,
  LOCATION_VALUE_ALL,
  NUMBER_OF_SALES,
  AVERAGE_SALES_PRICE,
  MEDIAN_SALES_PRICE,
  SALES_VOLUME,
  QUARTERLY,
  PRICE_BRACKET,
  QUARTER_OVER_QUARTER,
  QUARTER_TO_QUARTER,
  YEAR_OVER_YEAR,
  YEAR_TO_DATE,
} from "constants/ReortContentConstants";

import {
  generateQuartersArray,
  generateYOYArray,
  generateYearsArray,
  getCurrentQuarter,
} from "./utils";

export const chartFilters = (salesData, reportContent) => {
  const categoryIsValid = reportContent.category !== CATEGORY_ALL;
  let locationIsValid;
  let reportContentLocations;
  try {
    reportContentLocations = JSON.parse(reportContent.location);
  } catch (error) {
    reportContentLocations = reportContent.location;
  }
  if (typeof reportContentLocations === typeof []) {
    locationIsValid = reportContentLocations[0] !== LOCATION_VALUE_ALL;
  } else {
    locationIsValid = reportContentLocations !== LOCATION_VALUE_ALL;
  }
  const bracketIsValid = reportContent.bracket !== BRACKET_ALL;
  const priceIsValid = reportContent.bracketType === PRICE_BRACKET;
  let priceRange;
  let arvRange;

  if (priceIsValid) {
    priceRange = reportContent.bracket.split("-");
  } else {
    arvRange = reportContent.bracket.split("-");
  }
  let locations;
  if (typeof reportContentLocations === typeof "") {
    locations = reportContentLocations.split(",");
  } else {
    locations = reportContentLocations;
  }
  if (
    reportContent.timePeriod === YEARLY ||
    reportContent.timePeriod === YEAR_OVER_YEAR
  ) {
    let years = [];
    if (reportContent.timePeriod === YEARLY) {
      years = generateYearsArray(
        Number(reportContent.fromDate),
        Number(reportContent.toDate)
      );
    } else if (reportContent.timePeriod === YEAR_OVER_YEAR) {
      years = generateYearsArray(
        Number(reportContent.fromDate),
        Number(reportContent.toDate)
      );
    }

    //eslint-disable-next-line
    const data = years.map((year) => {
      let count = 0;
      let priceCounter = 0;
      let average = 0;
      let priceArr = [];
      let median = 0;

      for (let sale = 0; sale < salesData.length; sale++) {
        const salesYear = new Date(
          salesData[sale].registration_date
        ).getFullYear();
        if (categoryIsValid && locationIsValid && bracketIsValid) {
          for (let location = 0; location < locations.length; location++) {
            if (
              salesData[sale].property_type.toLowerCase() ===
                reportContent.category.toLowerCase() &&
              salesData[sale].parish.toLowerCase() ===
                locations[location].toLowerCase() &&
              (priceIsValid
                ? salesData[sale].price
                  ? salesData[sale].price >= Number(priceRange[0]) &&
                    salesData[sale].price < Number(priceRange[1])
                  : false
                : salesData[sale].combined_arv
                ? salesData[sale].combined_arv >= Number(arvRange[0]) &&
                  salesData[sale].combined_arv < Number(arvRange[1])
                : false) &&
              salesYear === year
            ) {
              count = count + 1;
              priceCounter = priceCounter + salesData[sale].price;
              priceArr.push(salesData[sale].price);
            }
          }
        } else if (categoryIsValid && locationIsValid) {
          for (let location = 0; location < locations.length; location++) {
            if (
              salesData[sale].property_type.toLowerCase() ===
                reportContent.category.toLowerCase() &&
              salesData[sale].parish.toLowerCase() ===
                locations[location].toLowerCase() &&
              salesYear === year
            ) {
              count = count + 1;
              priceCounter = priceCounter + salesData[sale].price;
              priceArr.push(salesData[sale].price);
            }
          }
        } else if (categoryIsValid && bracketIsValid) {
          if (
            salesData[sale].property_type.toLowerCase() ===
              reportContent.category.toLowerCase() &&
            (priceIsValid
              ? salesData[sale].price
                ? salesData[sale].price >= Number(priceRange[0]) &&
                  salesData[sale].price < Number(priceRange[1])
                : false
              : salesData[sale].combined_arv
              ? salesData[sale].combined_arv >= Number(arvRange[0]) &&
                salesData[sale].combined_arv < Number(arvRange[1])
              : false) &&
            salesYear === year
          ) {
            count = count + 1;
            priceCounter = priceCounter + salesData[sale].price;
            priceArr.push(salesData[sale].price);
          }
        } else if (locationIsValid && bracketIsValid) {
          for (let location = 0; location < locations.length; location++) {
            if (
              salesData[sale].parish.toLowerCase() ===
                locations[location].toLowerCase() &&
              (priceIsValid
                ? salesData[sale].price
                  ? salesData[sale].price >= Number(priceRange[0]) &&
                    salesData[sale].price < Number(priceRange[1])
                  : false
                : salesData[sale].combined_arv
                ? salesData[sale].combined_arv >= Number(arvRange[0]) &&
                  salesData[sale].combined_arv < Number(arvRange[1])
                : false) &&
              salesYear === year
            ) {
              count = count + 1;
              priceCounter = priceCounter + salesData[sale].price;
              priceArr.push(salesData[sale].price);
            }
          }
        } else if (locationIsValid) {
          for (let location = 0; location < locations.length; location++) {
            if (
              salesData[sale].parish.toLowerCase() ===
                locations[location].toLowerCase() &&
              salesYear === year
            ) {
              count = count + 1;
              priceCounter = priceCounter + salesData[sale].price;
              priceArr.push(salesData[sale].price);
            }
          }
        } else if (categoryIsValid) {
          if (
            salesData[sale].property_type.toLowerCase() ===
              reportContent.category.toLowerCase() &&
            salesYear === year
          ) {
            count = count + 1;
            priceCounter = priceCounter + salesData[sale].price;
            priceArr.push(salesData[sale].price);
          }
        } else if (bracketIsValid) {
          if (
            (priceIsValid
              ? salesData[sale].price
                ? salesData[sale].price >= Number(priceRange[0]) &&
                  salesData[sale].price < Number(priceRange[1])
                : false
              : salesData[sale].combined_arv
              ? salesData[sale].combined_arv >= Number(arvRange[0]) &&
                salesData[sale].combined_arv < Number(arvRange[1])
              : false) &&
            salesYear === year
          ) {
            count = count + 1;
            priceCounter = priceCounter + salesData[sale].price;
            priceArr.push(salesData[sale].price);
          }
        } else {
          if (salesYear === year) {
            count = count + 1;
            priceCounter = priceCounter + salesData[sale].price;
            priceArr.push(salesData[sale].price);
          }
        }
      }
      if (reportContent.indicator === NUMBER_OF_SALES) {
        return count;
      } else if (reportContent.indicator === AVERAGE_SALES_PRICE) {
        average = Math.round(priceCounter / count);
        return average;
      } else if (reportContent.indicator === MEDIAN_SALES_PRICE) {
        const mid = Math.floor(priceArr.length / 2);
        const priceArrSorted = priceArr.sort((a, b) => a - b);
        if (priceArr.length % 2 === 0) {
          median = (priceArrSorted[mid - 1] + priceArrSorted[mid]) / 2;
          return median;
        } else {
          median = priceArrSorted[mid];
          return median;
        }
      } else if (reportContent.indicator === SALES_VOLUME) {
        let priceVolume = 0;
        for (let i = 0; i < priceArr.length; i++) {
          priceVolume = priceVolume + priceArr[i];
        }
        return Math.floor(priceVolume);
      }
    });
    if (reportContent.timePeriod === YEARLY) {
      return { data: [data], years };
    } else if (reportContent.timePeriod === YEAR_OVER_YEAR) {
      let yearOveryear = [];
      for (let yIndex = 1; yIndex < data.length; yIndex++) {
        yearOveryear.push((data[yIndex] - data[yIndex - 1]) / data[yIndex - 1]);
      }
      return {
        data: [yearOveryear],
        years: generateYOYArray(
          Number(reportContent.fromDate),
          Number(reportContent.toDate)
        ),
      };
    }
  } else if (reportContent.timePeriod === YEAR_TO_DATE) {
    let years = generateYearsArray(
      Number(reportContent.fromDate),
      Number(reportContent.toDate)
    );

    //eslint-disable-next-line
    const data = years.map((year) => {
      let count = 0;
      let priceCounter = 0;
      let average = 0;
      let priceArr = [];
      let median = 0;
      let firstDateOfyear = new Date(year, 0, 1);
      let dateYearToDate = new Date();

      for (let sale = 0; sale < salesData.length; sale++) {
        const salesDate = new Date(salesData[sale].registration_date);
        if (categoryIsValid && locationIsValid && bracketIsValid) {
          for (let location = 0; location < locations.length; location++) {
            if (
              salesData[sale].property_type.toLowerCase() ===
                reportContent.category.toLowerCase() &&
              salesData[sale].parish.toLowerCase() ===
                locations[location].toLowerCase() &&
              (priceIsValid
                ? salesData[sale].price
                  ? salesData[sale].price >= Number(priceRange[0]) &&
                    salesData[sale].price < Number(priceRange[1])
                  : false
                : salesData[sale].combined_arv
                ? salesData[sale].combined_arv >= Number(arvRange[0]) &&
                  salesData[sale].combined_arv < Number(arvRange[1])
                : false) &&
              salesDate >= firstDateOfyear &&
              salesDate <= dateYearToDate
            ) {
              count = count + 1;
              priceCounter = priceCounter + salesData[sale].price;
              priceArr.push(salesData[sale].price);
            }
          }
        } else if (categoryIsValid && locationIsValid) {
          for (let location = 0; location < locations.length; location++) {
            if (
              salesData[sale].property_type.toLowerCase() ===
                reportContent.category.toLowerCase() &&
              salesData[sale].parish.toLowerCase() ===
                locations[location].toLowerCase() &&
              salesDate >= firstDateOfyear &&
              salesDate <= dateYearToDate
            ) {
              count = count + 1;
              priceCounter = priceCounter + salesData[sale].price;
              priceArr.push(salesData[sale].price);
            }
          }
        } else if (categoryIsValid && bracketIsValid) {
          if (
            salesData[sale].property_type.toLowerCase() ===
              reportContent.category.toLowerCase() &&
            (priceIsValid
              ? salesData[sale].price
                ? salesData[sale].price >= Number(priceRange[0]) &&
                  salesData[sale].price < Number(priceRange[1])
                : false
              : salesData[sale].combined_arv
              ? salesData[sale].combined_arv >= Number(arvRange[0]) &&
                salesData[sale].combined_arv < Number(arvRange[1])
              : false) &&
            salesDate >= firstDateOfyear &&
            salesDate <= dateYearToDate
          ) {
            count = count + 1;
            priceCounter = priceCounter + salesData[sale].price;
            priceArr.push(salesData[sale].price);
          }
        } else if (locationIsValid && bracketIsValid) {
          for (let location = 0; location < locations.length; location++) {
            if (
              salesData[sale].parish.toLowerCase() ===
                locations[location].toLowerCase() &&
              (priceIsValid
                ? salesData[sale].price
                  ? salesData[sale].price >= Number(priceRange[0]) &&
                    salesData[sale].price < Number(priceRange[1])
                  : false
                : salesData[sale].combined_arv
                ? salesData[sale].combined_arv >= Number(arvRange[0]) &&
                  salesData[sale].combined_arv < Number(arvRange[1])
                : false) &&
              salesDate >= firstDateOfyear &&
              salesDate <= dateYearToDate
            ) {
              count = count + 1;
              priceCounter = priceCounter + salesData[sale].price;
              priceArr.push(salesData[sale].price);
            }
          }
        } else if (locationIsValid) {
          for (let location = 0; location < locations.length; location++) {
            if (
              salesData[sale].parish.toLowerCase() ===
                locations[location].toLowerCase() &&
              salesDate >= firstDateOfyear &&
              salesDate <= dateYearToDate
            ) {
              count = count + 1;
              priceCounter = priceCounter + salesData[sale].price;
              priceArr.push(salesData[sale].price);
            }
          }
        } else if (categoryIsValid) {
          if (
            salesData[sale].property_type.toLowerCase() ===
              reportContent.category.toLowerCase() &&
            salesDate >= firstDateOfyear &&
            salesDate <= dateYearToDate
          ) {
            count = count + 1;
            priceCounter = priceCounter + salesData[sale].price;
            priceArr.push(salesData[sale].price);
          }
        } else if (bracketIsValid) {
          if (
            (priceIsValid
              ? salesData[sale].price
                ? salesData[sale].price >= Number(priceRange[0]) &&
                  salesData[sale].price < Number(priceRange[1])
                : false
              : salesData[sale].combined_arv
              ? salesData[sale].combined_arv >= Number(arvRange[0]) &&
                salesData[sale].combined_arv < Number(arvRange[1])
              : false) &&
            salesDate >= firstDateOfyear &&
            salesDate <= dateYearToDate
          ) {
            count = count + 1;
            priceCounter = priceCounter + salesData[sale].price;
            priceArr.push(salesData[sale].price);
          }
        } else {
          if (salesDate >= firstDateOfyear && salesDate <= dateYearToDate) {
            count = count + 1;
            priceCounter = priceCounter + salesData[sale].price;
            priceArr.push(salesData[sale].price);
          }
        }
      }
      if (reportContent.indicator === NUMBER_OF_SALES) {
        return count;
      } else if (reportContent.indicator === AVERAGE_SALES_PRICE) {
        average = Math.round(priceCounter / count);
        return average;
      } else if (reportContent.indicator === MEDIAN_SALES_PRICE) {
        const mid = Math.floor(priceArr.length / 2);
        const priceArrSorted = priceArr.sort((a, b) => a - b);
        if (priceArr.length % 2 === 0) {
          median = (priceArrSorted[mid - 1] + priceArrSorted[mid]) / 2;
          return median;
        } else {
          median = priceArrSorted[mid];
          return median;
        }
      } else if (reportContent.indicator === SALES_VOLUME) {
        let priceVolume = 0;
        for (let i = 0; i < priceArr.length; i++) {
          priceVolume = priceVolume + priceArr[i];
        }
        return Math.floor(priceVolume);
      }
    });
    if (reportContent.timePeriod === YEAR_TO_DATE) {
      return { data: [data], years };
    }
  } else if (
    reportContent.timePeriod === QUARTERLY ||
    reportContent.timePeriod === QUARTER_OVER_QUARTER ||
    reportContent.timePeriod === QUARTER_TO_QUARTER
  ) {
    let years = [];
    if (reportContent.timePeriod === QUARTER_OVER_QUARTER) {
      years = generateYearsArray(
        Number(reportContent.fromDate),
        Number(reportContent.toDate)
      );
    } else {
      years = generateYearsArray(
        Number(reportContent.fromDate),
        Number(reportContent.toDate)
      );
    }

    let quarters;
    //eslint-disable-next-line
    const data = years.map((year) => {
      quarters = generateQuartersArray(year);

      let countArr = [];
      let averageArr = [];
      let medianArr = [];
      let volumeArr = [];
      for (let quarter = 0; quarter < quarters.length; quarter++) {
        let count = 0;
        let priceCounter = 0;
        let average = 0;
        let priceArr = [];
        let median = 0;

        for (let sale = 0; sale < salesData.length; sale++) {
          const salesYear = new Date(
            salesData[sale].registration_date
          ).getFullYear();
          const currentQuarter = getCurrentQuarter(
            new Date(salesData[sale].registration_date)
          );
          if (categoryIsValid && locationIsValid && bracketIsValid) {
            for (let location = 0; location < locations.length; location++) {
              if (
                salesData[sale].property_type.toLowerCase() ===
                  reportContent.category.toLowerCase() &&
                salesData[sale].parish.toLowerCase() ===
                  locations[location].toLowerCase() &&
                (priceIsValid
                  ? salesData[sale].price
                    ? salesData[sale].price >= Number(priceRange[0]) &&
                      salesData[sale].price < Number(priceRange[1])
                    : false
                  : salesData[sale].combined_arv
                  ? salesData[sale].combined_arv >= Number(arvRange[0]) &&
                    salesData[sale].combined_arv < Number(arvRange[1])
                  : false) &&
                currentQuarter === quarter + 1 &&
                salesYear === year
              ) {
                count = count + 1;
                priceCounter = priceCounter + salesData[sale].price;
                priceArr.push(salesData[sale].price);
              }
            }
          } else if (categoryIsValid && locationIsValid) {
            for (let location = 0; location < locations.length; location++) {
              if (
                salesData[sale].property_type.toLowerCase() ===
                  reportContent.category.toLowerCase() &&
                salesData[sale].parish.toLowerCase() ===
                  locations[location].toLowerCase() &&
                currentQuarter === quarter + 1 &&
                salesYear === year
              ) {
                count = count + 1;
                priceCounter = priceCounter + salesData[sale].price;
                priceArr.push(salesData[sale].price);
              }
            }
          } else if (categoryIsValid && bracketIsValid) {
            if (
              salesData[sale].property_type.toLowerCase() ===
                reportContent.category.toLowerCase() &&
              (priceIsValid
                ? salesData[sale].price
                  ? salesData[sale].price >= Number(priceRange[0]) &&
                    salesData[sale].price < Number(priceRange[1])
                  : false
                : salesData[sale].combined_arv
                ? salesData[sale].combined_arv >= Number(arvRange[0]) &&
                  salesData[sale].combined_arv < Number(arvRange[1])
                : false) &&
              currentQuarter === quarter + 1 &&
              salesYear === year
            ) {
              count = count + 1;
              priceCounter = priceCounter + salesData[sale].price;
              priceArr.push(salesData[sale].price);
            }
          } else if (locationIsValid && bracketIsValid) {
            for (let location = 0; location < locations.length; location++) {
              if (
                salesData[sale].parish.toLowerCase() ===
                  locations[location].toLowerCase() &&
                (priceIsValid
                  ? salesData[sale].price
                    ? salesData[sale].price >= Number(priceRange[0]) &&
                      salesData[sale].price < Number(priceRange[1])
                    : false
                  : salesData[sale].combined_arv
                  ? salesData[sale].combined_arv >= Number(arvRange[0]) &&
                    salesData[sale].combined_arv < Number(arvRange[1])
                  : false) &&
                currentQuarter === quarter + 1 &&
                salesYear === year
              ) {
                count = count + 1;
                priceCounter = priceCounter + salesData[sale].price;
                priceArr.push(salesData[sale].price);
              }
            }
          } else if (locationIsValid) {
            for (let location = 0; location < locations.length; location++) {
              if (
                salesData[sale].parish.toLowerCase() ===
                  locations[location].toLowerCase() &&
                currentQuarter === quarter + 1 &&
                salesYear === year
              ) {
                count = count + 1;
                priceCounter = priceCounter + salesData[sale].price;
                priceArr.push(salesData[sale].price);
              }
            }
          } else if (categoryIsValid) {
            if (
              salesData[sale].property_type.toLowerCase() ===
                reportContent.category.toLowerCase() &&
              currentQuarter === quarter + 1 &&
              salesYear === year
            ) {
              count = count + 1;
              priceCounter = priceCounter + salesData[sale].price;
              priceArr.push(salesData[sale].price);
            }
          } else if (bracketIsValid) {
            if (
              (priceIsValid
                ? salesData[sale].price
                  ? salesData[sale].price >= Number(priceRange[0]) &&
                    salesData[sale].price < Number(priceRange[1])
                  : false
                : salesData[sale].combined_arv
                ? salesData[sale].combined_arv >= Number(arvRange[0]) &&
                  salesData[sale].combined_arv < Number(arvRange[1])
                : false) &&
              currentQuarter === quarter + 1 &&
              salesYear === year
            ) {
              count = count + 1;
              priceCounter = priceCounter + salesData[sale].price;
              priceArr.push(salesData[sale].price);
            }
          } else {
            if (salesYear === year && currentQuarter === quarter + 1) {
              count = count + 1;
              priceCounter = priceCounter + salesData[sale].price;
              priceArr.push(salesData[sale].price);
            }
          }
        }
        if (reportContent.indicator === NUMBER_OF_SALES) {
          countArr.push(count);
        } else if (reportContent.indicator === AVERAGE_SALES_PRICE) {
          average = Math.round(priceCounter / count);
          averageArr.push(average);
        } else if (reportContent.indicator === MEDIAN_SALES_PRICE) {
          const mid = Math.floor(priceArr.length / 2);
          const priceArrSorted = priceArr.sort((a, b) => a - b);
          if (priceArr.length % 2 === 0) {
            median = (priceArrSorted[mid - 1] + priceArrSorted[mid]) / 2;
            medianArr.push(median);
          } else {
            median = priceArrSorted[mid];
            medianArr.push(median);
          }
        } else if (reportContent.indicator === SALES_VOLUME) {
          let priceVolume = 0;
          for (let i = 0; i < priceArr.length; i++) {
            priceVolume = priceVolume + priceArr[i];
          }
          volumeArr.push(Math.floor(priceVolume));
        }
      }
      if (reportContent.indicator === NUMBER_OF_SALES) {
        return countArr;
      } else if (reportContent.indicator === AVERAGE_SALES_PRICE) {
        return averageArr;
      } else if (reportContent.indicator === MEDIAN_SALES_PRICE) {
        return medianArr;
      } else if (reportContent.indicator === SALES_VOLUME) {
        return volumeArr;
      }
    });
    if (reportContent.timePeriod === QUARTERLY) {
      return { data, years: quarters };
    } else if (reportContent.timePeriod === QUARTER_OVER_QUARTER) {
      let quarterOverQuarter = [];
      //need to run 1 less time because of comparison
      for (let qQver = 1; qQver < data.length; qQver++) {
        let quarterOverQuarterForYear = [];
        //need to run for 4 times
        for (let qIndex = 0; qIndex <= 3; qIndex++) {
          quarterOverQuarterForYear.push(
            data[qQver][qIndex] / data[qQver - 1][qIndex]
          );
        }
        quarterOverQuarter.push(quarterOverQuarterForYear);
      }
      return {
        data: quarterOverQuarter,
        years: ["Q1 over Q1", "Q2 over Q2", "Q3 over Q3", "Q4 over Q4"],
      };
    } else if (reportContent.timePeriod === QUARTER_TO_QUARTER) {
      let quarterToQuarter = [];
      for (let qTo = 0; qTo < data.length; qTo++) {
        let quarterToQuarterForYear = [];
        for (let qToValue = 1; qToValue <= 3; qToValue++) {
          quarterToQuarterForYear.push(
            (data[qTo][qToValue] - data[qTo][qToValue - 1]) /
              data[qTo][qToValue - 1]
          );
        }
        quarterToQuarter.push(quarterToQuarterForYear);
      }
      return {
        data: quarterToQuarter,
        years: ["Q2 to Q1", "Q3 to Q2", "Q4 to Q3"],
      };
    }
  }
};
