import { useEffect } from "react";
import { LeftOutlined } from "@ant-design/icons";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
//user-imports
import { getCollectionsDataById } from "redux/actions/CollectionReport";
import { getAllCollections } from "redux/actions/Collection";
import MainWrapper from "components/property-database-compnents/MainWrapper";
import { Spin } from "antd";
import classes from "./styles.module.css";

import FunctionalComponentWithHook from "./PrintReport";

const CollectionReport = (props) => {
  const { collectionsDataById, getCollectionsDataById, getAllCollections } =
    props;

  const history = useHistory();
  const { collectionId } = useParams();
  const goBackHandler = () => {
    history.goBack();
  };
  const goBack = (
    <div className={classes.goBackWrapper} onClick={goBackHandler}>
      <LeftOutlined style={{ fontSize: "12px" }} />{" "}
      <span> Back to collection's details</span>
    </div>
  );
  useEffect(() => {
    getAllCollections();
    if (collectionId) {
      getCollectionsDataById(collectionId);
    }
    //eslint-disable-next-line
  }, [collectionId]);

  return (
    <>
      {collectionsDataById ? (
        <div className={classes.root}>
          {goBack}
          <div className={classes.mainContiner}>
            <MainWrapper>
              <FunctionalComponentWithHook
                collectionsDataById={collectionsDataById}
              />
            </MainWrapper>
          </div>
        </div>
      ) : (
        <div className="loader-center">
          <Spin />
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ collectionReport }) => {
  const { collectionsDataById, loading, errorMessage } = collectionReport;
  return { collectionsDataById, loading, errorMessage };
};

const mapDispatchToProps = {
  getCollectionsDataById,
  getAllCollections,
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionReport);
