import { useEffect, useState } from "react";
import { Table, Button } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import {
  GROUP_SALES_TAG,
  SHOW_GROUP_BUTTON,
  groupSalesValidator,
} from "configs/TableConstants";
import { salesColumns } from "configs/TableConfig";
import GroupSaleTag from "components/GroupSaleTag";

const getPropertiesWithSalesRecord = (record, data) => {
  const propertiesWithSalesHistory = data.filter((property) => {
    if (property.sales.length > 0) {
      return (
        record.sales[0].application_number ===
          property.sales[0].application_number &&
        record.assessment_number !== property.assessment_number
      );
    } else {
      return false;
    }
  });
  return propertiesWithSalesHistory;
};

const EditableCell = (props) => {
  const {
    record,
    editable,
    setSalesPropertiesLoader,
    setSalesData,
    data,
    propertyData,
    plusIconIsValid,
    setPlusIconIsValid,
    groupSalesIsValid,
    children,
    sales_applicationNumbers,
    ...restProps
  } = props;

  const SalesGroupHandler = () => {
    setPlusIconIsValid((prevState) => !prevState);
  };

  let childNode = children;
  if (editable === GROUP_SALES_TAG) {
    let groupSalesIsValid = groupSalesValidator(
      record,
      sales_applicationNumbers
    );
    childNode = <>{groupSalesIsValid && <GroupSaleTag />}</>;
  }

  if (editable === SHOW_GROUP_BUTTON) {
    childNode = (
      <>
        {record.assessment_number === propertyData[0].assessment_number &&
          groupSalesIsValid && (
            <Button
              type="ghost"
              shape="circle"
              icon={plusIconIsValid ? <PlusOutlined /> : <MinusOutlined />}
              size="small"
              onClick={SalesGroupHandler}
              style={{
                width: "23px",
                height: "30px",
                paddingTop: "4px",
              }}
            />
          )}
      </>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const SalesGroup = ({
  propertyData,
  data,
  groupSalesIsValid,
  sales_applicationNumbers,
}) => {
  const [salesPropertiesLoader, setSalesPropertiesLoader] = useState(false);
  const [salesData, setSalesData] = useState(propertyData);

  const [plusIconIsValid, setPlusIconIsValid] = useState(true);

  useEffect(() => {
    if (plusIconIsValid) {
      setSalesData(propertyData);
    } else {
      const properties = getPropertiesWithSalesRecord(propertyData[0], data);
      setSalesData((prevData) => [...prevData, ...properties]);
    }
    //eslint-disable-next-line
  }, [plusIconIsValid]);
  const columns = salesColumns.map((col) => {
    if (col.editable === GROUP_SALES_TAG) {
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          setSalesPropertiesLoader,
          setSalesData,
          data,
          propertyData,
          plusIconIsValid,
          setPlusIconIsValid,
          groupSalesIsValid,
          sales_applicationNumbers,
        }),
      };
    }
    if (col.editable === SHOW_GROUP_BUTTON) {
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          setSalesPropertiesLoader,
          setSalesData,
          data,
          propertyData,
          plusIconIsValid,
          setPlusIconIsValid,
          groupSalesIsValid,
        }),
      };
    }

    return col;
  });

  const components = {
    body: {
      cell: EditableCell,
    },
  };

  return (
    <div style={{ marginTop: "30px" }}>
      <p className="property-database-heading7">Sales Group</p>
      <Table
        components={components}
        loading={salesPropertiesLoader}
        dataSource={salesData}
        columns={columns}
        rowKey="properties_id"
        tableLayout="fixed"
        pagination={false}
      />
    </div>
  );
};

export default SalesGroup;
