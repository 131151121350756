import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  saveReportContentSuccess,
  saveReportContentFailed,
  saveReportSuccess,
  saveReportFailed,
  getReportsDataSuccess,
  getReportsDataFailed,
  getSalesDataSuccess,
  getSalesDataFailed,
  getTrendReportDetailsSuccess,
  getTrendReportDetailsFailed,
  deleteMultipleReportsSuccess,
  deleteMultipleReportsFailed,
} from "redux/actions/Report";
import {
  GET_REPORTS_DATA,
  GET_SALES_DATA,
  GET_TREND_REPORT_DETAILS,
  SAVE_REPORT,
  SAVE_REPORT_CONTENT,
  DELETE_MULTIPLE_REPORTS,
} from "redux/constants/Report";
import SupabaseService from "services/SupabaseService";

export function* getAllRepotsData() {
  yield takeEvery(GET_REPORTS_DATA, function* () {
    try {
      const { data, error } = yield call(SupabaseService.getAllReportsData);
      if (error) throw error;
      else {
        yield put(getReportsDataSuccess(data));
      }
    } catch (error) {
      yield put(getReportsDataFailed("Request Failed, Please try again"));
    }
  });
}

export function* getAllSalesData() {
  yield takeEvery(GET_SALES_DATA, function* () {
    try {
      const { data, error } = yield call(SupabaseService.getAllSalesData);
      if (error) throw error;
      else {
        yield put(getSalesDataSuccess(data));
      }
    } catch (error) {
      console.log(error);
      yield put(getSalesDataFailed("Request Failed, Please try again"));
    }
  });
}

export function* saveReportContentData() {
  yield takeEvery(SAVE_REPORT_CONTENT, function* (payload) {
    try {
      const { data: reportContent } = payload;
      const { error } = yield call(
        SupabaseService.saveReportContent,
        reportContent
      );
      if (error) throw error;
      else {
        yield put(saveReportContentSuccess());
      }
    } catch (error) {
      yield put(saveReportContentFailed("Request Failed, Please try again"));
    }
  });
}

export function* saveReportData() {
  yield takeEvery(SAVE_REPORT, function* (payload) {
    try {
      const { data: reportData } = payload;
      const { reportInfo, reportContent } = reportData;
      const { data, error } = yield call(
        SupabaseService.saveReport,
        reportInfo
      );
      if (error) throw error;
      else {
        const reportContentNew = reportContent.map((item) => ({
          ...item,
          trend_report_id: data[0]?.trend_report_id,
        }));
        const { error: rcError } = yield call(
          SupabaseService.saveReportContent,
          reportContentNew
        );
        if (rcError) throw rcError;
        else {
          yield put(saveReportSuccess());
        }
      }
    } catch (error) {
      yield put(saveReportFailed("Request Failed, Please try again"));
    }
  });
}

export function* getTrendReportDetails() {
  yield takeEvery(GET_TREND_REPORT_DETAILS, function* (payload) {
    const { id } = payload;
    try {
      const { data, error } = yield call(
        SupabaseService.getTrendReportDetails,
        id
      );
      if (error) throw error;
      else {
        yield put(getTrendReportDetailsSuccess(data));
      }
    } catch (error) {
      console.log(error);
      yield put(
        getTrendReportDetailsFailed("Request Failed, Please try again")
      );
    }
  });
}

export function* deleteMultipleReports() {
  yield takeEvery(DELETE_MULTIPLE_REPORTS, function* (payload) {
    try {
      const { reportIds } = payload;
      for (var i = 0; i < reportIds.length; i++) {
        const { error } = yield call(
          SupabaseService.deleteReportsByID,
          reportIds[i]
        );
        if (error) throw error;
      }
      const { data, error } = yield call(SupabaseService.getAllReportsData);
      if (error) throw error;
      else {
        yield put(deleteMultipleReportsSuccess(data));
      }
    } catch (error) {
      yield put(deleteMultipleReportsFailed());
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(saveReportContentData),
    fork(saveReportData),
    fork(getAllRepotsData),
    fork(getAllSalesData),
    fork(getTrendReportDetails),
    fork(deleteMultipleReports),
  ]);
}
