import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { Spin } from "antd";
//user-imports
import MainWrapper from "components/property-database-compnents/MainWrapper";
import {
  hideFilters,
  deleteDataByID,
  getPropertyDataById,
  showLoading,
  hideDataMessage,
  showModal,
  hideModal,
  preventApplyingFilters,
} from "redux/actions/PropertyDatabase";
import { getAllCollections } from "redux/actions/Collection";
import PropertyDetailsHeading from "./PropertyDetailsHeading";
import PropertyDetailsCarousel from "./PropertyDetailsCarousel";
import PropertyDetailsSummary from "./PropertyDetailsSummary";
import PropertyDetailsDescription from "./PropertyDetailsDescription";
// import PropertyDetailsExternalLinks from "./PropertyDetailsExternalLinks";
import PropertyDetailsAgents from "./PropertyDetailsAgents";
import PropertyDetailsTimeline from "./PropertyDetailsTimeline";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import AlertMessage from "components/layout-components/AlertMessage";
import { DeleteErrorModal } from "components/layout-components/CustomModal";
import PropertyDetailsListings from "./PropertyDetailsListings";
import SalesGroup from "./SalesGroup";
import { groupSalesValidator } from "configs/TableConstants";
import PropertyDetailsListed from "./PropertyDetailsListed";

const PropertyDetails = (props) => {
  const {
    data,
    dataById,
    hideFilters,
    dataMessage,
    dataShowErrorMessage,
    hideDataMessage,
    allowRedirect,
    getPropertyDataById,
    dataByIdError,
    dataByIdMessage,
    modalIsValid,
    hideModal,
    deleteDataByID,
    preventApplyingFilters,
    backTodatabaseIsVaild,
    getAllCollections,
    collectionId,
    session,
    sales_applicationNumbers,
  } = props;
  const history = useHistory();
  const { propertyId } = useParams();

  useEffect(() => {
    if (allowRedirect) history.push(`${APP_PREFIX_PATH}/home`);
    //eslint-disable-next-line
  }, [allowRedirect]);

  useEffect(() => {
    hideFilters();
    getPropertyDataById(+propertyId);
    preventApplyingFilters();
    if (!backTodatabaseIsVaild) {
      getAllCollections();
    }

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (dataShowErrorMessage) {
        hideDataMessage();
      }
    }, 3000);
    //eslint-disable-next-line
  }, [dataShowErrorMessage]);

  const backHandler = () => {
    if (backTodatabaseIsVaild) {
      history.push("/app/home");
    } else {
      history.push(`${APP_PREFIX_PATH}/collections/${collectionId}`);
    }
  };

  const backToDatabase = (
    <div className="back-to-database" onClick={backHandler}>
      <LeftOutlined style={{ fontSize: "12px" }} />{" "}
      <span>
        {backTodatabaseIsVaild
          ? "Back to property database"
          : "Back to collection list"}
      </span>
    </div>
  );
  let groupSalesIsValid = false;
  if (dataById) {
    groupSalesIsValid = groupSalesValidator(
      dataById[0],
      sales_applicationNumbers
    );
  }
  if (!dataById) {
    return (
      <div style={{ textAlign: "center" }}>
        {dataByIdError ? <p>{dataByIdMessage}</p> : <Spin />}
      </div>
    );
  } else {
    return (
      <>
        <AlertMessage
          alertType="error"
          showMessage={dataShowErrorMessage}
          message={dataMessage}
          width="195px"
        />
        <DeleteErrorModal
          modalIsValid={modalIsValid}
          hideModal={hideModal}
          deleteDataByID={deleteDataByID}
          id={dataById[0].assessment_number}
        />
        {session && <>{backToDatabase}</>}

        <MainWrapper marginTop="10px">
          <div>
            <PropertyDetailsHeading
              propertyData={dataById[0]}
              {...props}
              editManualReviewPropertyIsValid={false}
            />
            <PropertyDetailsCarousel images={dataById[0].images} />
            <PropertyDetailsSummary propertyData={dataById[0]} />
            <PropertyDetailsDescription propertyData={dataById[0]} />
            {/* <PropertyDetailsExternalLinks propertyData={dataById[0]} /> */}
            <PropertyDetailsAgents propertyData={dataById[0]} />
            <PropertyDetailsListings propertyData={dataById[0]} />
            <PropertyDetailsListed propertyData={dataById[0]} />

            {dataById[0].sales.length > 0 && (
              <PropertyDetailsTimeline propertyData={dataById[0]} />
            )}
            {groupSalesIsValid && data && (
              <SalesGroup
                propertyData={dataById}
                data={data}
                groupSalesIsValid={groupSalesIsValid}
                sales_applicationNumbers={sales_applicationNumbers}
              />
            )}
          </div>
        </MainWrapper>
        {session && <>{backToDatabase}</>}
      </>
    );
  }
};

const mapStateToProps = ({ propertyDatabase, collection, auth }) => {
  const {
    data,
    dataById,
    loading,
    dataByIdLoading,
    dataMessage,
    dataShowErrorMessage,
    dataShowSuccessMessage,
    allowRedirect,
    modalIsValid,
    dataByIdError,
    dataByIdMessage,
    backTodatabaseIsVaild,
    deleteDataLoading,
    sales_applicationNumbers,
  } = propertyDatabase;
  const { collectionId } = collection;
  const { session } = auth;
  return {
    data,
    dataById,
    loading,
    dataByIdLoading,
    dataMessage,
    dataShowErrorMessage,
    dataShowSuccessMessage,
    allowRedirect,
    modalIsValid,
    dataByIdError,
    dataByIdMessage,
    backTodatabaseIsVaild,
    collectionId,
    deleteDataLoading,
    session,
    sales_applicationNumbers,
  };
};
const mapDispatchToProps = {
  hideFilters,
  deleteDataByID,
  getPropertyDataById,
  showLoading,
  hideDataMessage,
  showModal,
  hideModal,
  preventApplyingFilters,
  getAllCollections,
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyDetails);
