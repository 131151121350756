import ReactHtmlParser from "react-html-parser";
const PropertyDetailsSummary = (props) => {
  const { propertyData } = props;
  let price = null;
  let buyer_type = "International";
  if (
    propertyData.property_database_listings.length > 0 &&
    propertyData.property_database_listings[0].price
  ) {
    price = propertyData.property_database_listings[0].price;
    buyer_type = propertyData.property_database_listings[0].buyer_type;
  }
  const {
    bedrooms,
    bathrooms,
    half_bathrooms,
    short_description,
    property_type,
  } = propertyData;
  return (
    <div className="property-details-div3">
      <p className="property-database-heading7">Summary</p>
      <div className="parse-heading-class">
        {ReactHtmlParser(short_description)}
      </div>
      <div className="property-details-div4">
        <div className="property-details-div4-sub">
          <p>
            {bedrooms
              ? bedrooms === 1
                ? `${bedrooms} Bedroom`
                : `${bedrooms} Bedrooms`
              : "N/A Bedrooms"}{" "}
          </p>
          <p>
            {bathrooms
              ? bathrooms === 1
                ? `${bathrooms} Bathroom`
                : `${bathrooms} Bathrooms`
              : "N/A Bathrooms"}{" "}
          </p>
          <p>{half_bathrooms ? half_bathrooms : "N/A"} Half Bathroom</p>
        </div>
        <div className="property-details-div4-sub">
          <p>Usage: {property_type} </p>
          <p>Buyer: {buyer_type}</p>
          <p>Price/sqft: {price ? `$${price.toLocaleString()}` : "N/A"}</p>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetailsSummary;
