import {
  GETDATA,
  SETDATA,
  DELETEDATA_ID,
  DELETEDATA_ID_SUCCESS,
  SHOW_FILTERS,
  HIDE_FILTERS,
  SHOW_LOADING,
  SHOW_ERROR_MESSAGE,
  SHOW_SUCCESS_MESSAGE,
  HIDE_MESSAGE,
  SHOW_MODAL,
  HIDE_MODAL,
  DISALLOW_REDIRECT,
  SHOW_LESS_FILTERS,
  SHOW_ALL_FILTERS,
  GET_PROPERTY_DATA_BY_ID,
  GET_PROPERTY_DATA_BY_ID_SUCCESS,
  GET_PROPERTY_DATA_BY_ID_FAILED,
  SAVE_EDITING_SUCCESS,
  HIDE_DATA_BY_ID_ERROR,
  SET_DATA_BY_ID_NULL,
  SET_PROPERTYDATABASE_PAGINATION,
  FILTERED_DATA,
  APPLY_PROPERTY_FILTERS,
  GET_FILTER_MAX_VALUES,
  SET_FILTER_MAX_VALUES,
  RESET_FILTERS,
  SET_REMOVE_BUTTON_STATE,
  RESERT_REMOVE_BUTTON_STATE,
  CHANGE_REMOVE_BUTTON_STATE,
  GET_FILTER_MAX_VALUES_FAILED,
  CHANGE_PROPERTY_FILTERS_DATA,
  SET_PROPERTY_FILTER_LOADING,
  PREVENT_APPLYING_FILTERS,
  SET_PROPERTY_DATABASE_FILTERS_VALID,
  SET_PROPERTY_DATABASE_FILTERS_INVALID,
  SET_BACK_TO_DATABASE_VALID,
  SET_BACK_TO_DATABASE_INVALID,
  GET_UNDEFINED_DATA_UNITS,
  GET_UNDEFINED_DATA_UNITS_SUCCESS,
  GET_UNDEFINED_DATA_UNITS_FAILED,
  MAKE_UNDEFINED_DATA_UNITS_INVALID,
  MAKE_UNDEFINED_DATA_UNITS_VALID,
  GET_SOLD_IN_THE_PAST,
  GET_SOLD_IN_THE_PAST_SUCCESS,
  GET_SOLD_IN_THE_PAST_FAILED,
  MAKE_SOLD_IN_THE_PAST_VALID,
  MAKE_SOLD_IN_THE_PAST_INVALID,
  GET_SALES_APPLICATION_NUMBERS,
  GET_SALES_APPLICATION_NUMBERS_ERROR,
  GET_SALES_APPLICATION_NUMBERS_SUCCESS,
} from "../constants/PropertyDatabase";

export const getData = () => {
  return {
    type: GETDATA,
  };
};

export const showDataErrorMessage = (message) => {
  return {
    type: SHOW_ERROR_MESSAGE,
    message,
  };
};
export const showDataSuccessMessage = (message) => {
  return {
    type: SHOW_SUCCESS_MESSAGE,
    message,
  };
};

export const hideDataMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export const setData = (data) => {
  return {
    type: SETDATA,
    data,
  };
};

export const deleteDataByID = (id) => {
  return {
    type: DELETEDATA_ID,
    id,
  };
};

export const deleteDataByIDSuccess = () => {
  return {
    type: DELETEDATA_ID_SUCCESS,
  };
};

export const getPropertyDataById = (id) => {
  return {
    type: GET_PROPERTY_DATA_BY_ID,
    id,
  };
};

export const getPropertyDataByIdSuccess = (data) => {
  return {
    type: GET_PROPERTY_DATA_BY_ID_SUCCESS,
    data,
  };
};

export const getPropertyDataByIdfailed = (message) => {
  return {
    type: GET_PROPERTY_DATA_BY_ID_FAILED,
    message,
  };
};

export const makeAllowRedirectFalse = () => {
  return {
    type: DISALLOW_REDIRECT,
  };
};

export const showFilters = () => {
  return {
    type: SHOW_FILTERS,
  };
};

export const hideFilters = () => {
  return {
    type: HIDE_FILTERS,
  };
};

export const showLessFilters = () => {
  return {
    type: SHOW_LESS_FILTERS,
  };
};

export const showAllFilters = () => {
  return {
    type: SHOW_ALL_FILTERS,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

export const showModal = () => {
  return {
    type: SHOW_MODAL,
  };
};

export const hideModal = () => {
  return {
    type: HIDE_MODAL,
  };
};

export const setDataNull = () => {
  return {
    type: SAVE_EDITING_SUCCESS,
  };
};

export const hideDataByIdError = () => {
  return {
    type: HIDE_DATA_BY_ID_ERROR,
  };
};

export const setDataByIdNull = () => {
  return {
    type: SET_DATA_BY_ID_NULL,
  };
};

export const setPropertyDatabasePagination = (data) => {
  return {
    type: SET_PROPERTYDATABASE_PAGINATION,
    data,
  };
};

export const applyPropertyFilters = () => {
  return {
    type: APPLY_PROPERTY_FILTERS,
  };
};

export const setFilteredData = (data) => {
  return {
    type: FILTERED_DATA,
    data,
  };
};

export const getFilterMaxValue = () => {
  return {
    type: GET_FILTER_MAX_VALUES,
  };
};

export const setFiterMaxValues = (data) => {
  return {
    type: SET_FILTER_MAX_VALUES,
    data,
  };
};

export const getFilterMaxValueFailed = () => {
  return {
    type: GET_FILTER_MAX_VALUES_FAILED,
  };
};

export const resetFilters = (data) => {
  return {
    type: RESET_FILTERS,
    data,
  };
};

export const changePropertyFiltersData = (data) => {
  return {
    type: CHANGE_PROPERTY_FILTERS_DATA,
    data,
  };
};

export const setRemoveButtonState = (propertiesId) => {
  return {
    type: SET_REMOVE_BUTTON_STATE,
    propertiesId,
  };
};

export const resetRemoveButtonState = () => {
  return {
    type: RESERT_REMOVE_BUTTON_STATE,
  };
};

export const changeRemoveButtonState = (propertiesId) => {
  return {
    type: CHANGE_REMOVE_BUTTON_STATE,
    propertiesId,
  };
};

export const setPropertyFilterLoading = () => {
  return {
    type: SET_PROPERTY_FILTER_LOADING,
  };
};

export const preventApplyingFilters = () => {
  return {
    type: PREVENT_APPLYING_FILTERS,
  };
};

export const setPropertyDatabaseFiltersValid = () => {
  return {
    type: SET_PROPERTY_DATABASE_FILTERS_VALID,
  };
};

export const setPropertyDatabaseFiltersInValid = () => {
  return {
    type: SET_PROPERTY_DATABASE_FILTERS_INVALID,
  };
};

export const setbackToDatabaseValid = () => {
  return {
    type: SET_BACK_TO_DATABASE_VALID,
  };
};

export const setbackToDatabaseInValid = () => {
  return {
    type: SET_BACK_TO_DATABASE_INVALID,
  };
};

export const getUndefinedDataUnits = () => {
  return {
    type: GET_UNDEFINED_DATA_UNITS,
  };
};

export const getUndefinedDataUnitsSuccess = (data) => {
  return {
    type: GET_UNDEFINED_DATA_UNITS_SUCCESS,
    data,
  };
};

export const getUndefinedDataUnitsFailed = (message) => {
  return {
    type: GET_UNDEFINED_DATA_UNITS_FAILED,
    message,
  };
};

export const makeUndefinedDataUnitsInValid = () => {
  return {
    type: MAKE_UNDEFINED_DATA_UNITS_INVALID,
  };
};

export const makeUndefinedDataUnitsValid = () => {
  return {
    type: MAKE_UNDEFINED_DATA_UNITS_VALID,
  };
};

export const getSoldInThePast = () => {
  return {
    type: GET_SOLD_IN_THE_PAST,
  };
};

export const getSoldInThePastSuccess = (data) => {
  return {
    type: GET_SOLD_IN_THE_PAST_SUCCESS,
    data,
  };
};

export const getSoldInThePastFailed = (message) => {
  return {
    type: GET_SOLD_IN_THE_PAST_FAILED,
    message,
  };
};

export const makesoldInThePastInValid = () => {
  return {
    type: MAKE_SOLD_IN_THE_PAST_INVALID,
  };
};

export const makesoldInThePastValid = () => {
  return {
    type: MAKE_SOLD_IN_THE_PAST_VALID,
  };
};

export const getSalesApplicationNumbers = () => {
  return {
    type: GET_SALES_APPLICATION_NUMBERS,
  };
};

export const getSalesApplicationNumbersError = () => {
  return {
    type: GET_SALES_APPLICATION_NUMBERS_ERROR,
  };
};

export const getSalesApplicationNumbersSuccess = (data) => {
  return {
    type: GET_SALES_APPLICATION_NUMBERS_SUCCESS,
    data,
  };
};
