import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import "./carousel.css";

const PropertyDetailsCarousel = (props) => {
  const { images } = props;
  let carouselImages = images === null ? ["/img/nologo.png"] : images;
  const leftArrowStyles = {
    position: "absolute",
    top: "44%",
    bottom: "auto",
    padding: ".4em",
    zIndex: 2,
    width: "80px",
    height: "80px",
  };
  const rightArrowStyles = {
    ...leftArrowStyles,
    right: "0px",
  };

  const iconStyles = {
    fontSize: "48px",
    fontWeight: "700",
    color: "#FFFFFF",
  };

  return (
    <Carousel
      showArrows={true}
      showIndicators={false}
      thumbHeight={100}
      showThumbs={carouselImages.length === 1 ? false : true}
      autoPlay={false}
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <Button
            type="text"
            shape="circle"
            icon={<LeftOutlined style={iconStyles} />}
            onClick={onClickHandler}
            title={label}
            style={leftArrowStyles}
          />
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <Button
            type="text"
            shape="circle"
            icon={<RightOutlined style={iconStyles} />}
            onClick={onClickHandler}
            title={label}
            style={rightArrowStyles}
          />
        )
      }
    >
      {carouselImages.map((item) => (
        <div
          style={{ marginTop: "20px", maxHeight: "800px" }}
          key={Math.random()}
        >
          <img src={item} alt="" className="property-details-image" />
        </div>
      ))}
    </Carousel>
  );
};

export default PropertyDetailsCarousel;
