import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import propertyDatabase from "./PropertyDatabase";
import collection from "./Collection";
import collectionReport from "./CollectionReport";
import manualReview from "./ManualReview";
import report from "./Reports";

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  propertyDatabase,
  collection,
  collectionReport,
  manualReview,
  report,
});

export default reducers;
