import {
  SUPABASE_AUTHENTICATED,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SHOW_LOADING,
  SHOW_AUTH_SUCCESS_MESSAGE,
  SIGNIN,
  GO_BACK,
} from "../constants/Auth";

const initState = {
  loading: false,
  message: "",
  loginSuccess: false,
  showMessage: false,
  emailValue: null,
  showSuccessMessage: false,
  redirect: "",
  session: null,
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case SHOW_AUTH_SUCCESS_MESSAGE:
      return {
        ...state,
        message: action.message,
        loginSuccess: true,
        showSuccessMessage: true,
        loading: false,
      };
    case SUPABASE_AUTHENTICATED:
      return {
        ...state,
        loading: false,
        session: action.session,
        redirect: "/",
      };

    case SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false,
      };
    case HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: "",
        showMessage: false,
        showSuccessMessage: false,
      };
    case SHOW_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SIGNIN:
      return {
        ...state,
        loading: true,
        emailValue: action.payload.email,
      };
    case GO_BACK:
      return {
        ...state,
        loading: false,
        emailValue: null,
        loginSuccess: false,
      };
    default:
      return state;
  }
};

export default auth;
