const GroupSaleTag = () => {
  return (
    <div
      style={{
        color: "red",
        padding: "0px",
        width: "90px",
        height: "25px",
        borderRadius: "5px",
        backgroundColor: "#ffebff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p style={{ color: "#ff14ff", fontWeight: 500 }}>Group Sales</p>
    </div>
  );
};

export default GroupSaleTag;
