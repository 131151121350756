import { Switch } from "antd";

const SwitchFilter = (props) => {
  const { title, data, setData, name, value, marginTopIsValid } = props;

  const switchChangeHandler = (checked) => {
    if (!marginTopIsValid) {
      //for manual review
      setData({ ...data, [name]: checked });
    } else {
      if (name === "soldInPast") {
        setData({
          ...data,
          [name]: checked,
          undefinedDataUnits: data.undefinedDataUnits
            ? false
            : data.undefinedDataUnits,
        });
      } else {
        setData({
          ...data,
          [name]: checked,
          soldInPast: data.soldInPast ? false : data.soldInPast,
        });
      }
    }
  };
  return (
    <div
      className={
        marginTopIsValid ? "sidenav-filters--div6" : "sidenav-filters--div10"
      }
    >
      <Switch
        style={{ marginRight: "5px" }}
        onChange={switchChangeHandler}
        checked={value}
      />
      <p className="sidenav-heading1">{title}</p>
    </div>
  );
};
export default SwitchFilter;
