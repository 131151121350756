import {
  NUMBER_OF_SALES,
  PRICE_BRACKET,
  QUARTERLY,
  SALES_VOLUME,
  YEARLY,
} from "constants/ReortContentConstants";
import { charThreeARVData, charThreePriceData } from "./utils";

export const chartThreeFilters = (salesData, reportContent) => {
  const priceIsValid = reportContent.bracketType === PRICE_BRACKET;
  let priceData;
  let arvData;
  if (priceIsValid) {
    priceData = charThreePriceData;
    if (reportContent.timePeriod === YEARLY) {
      //eslint-disable-next-line
      const data = priceData.map((price) => {
        let countArr = [];
        let volumeArr = [];
        let count = 0;
        let priceArr = [];
        for (let sale = 0; sale < salesData.length; sale++) {
          const salesYear = new Date(
            salesData[sale].registration_date
          ).getFullYear();
          if (
            (salesData[sale].price
              ? salesData[sale].price >= Number(price.range[0]) &&
                salesData[sale].price < Number(price.range[1])
              : false) &&
            salesYear > Number(reportContent.fromDate) &&
            salesYear < Number(reportContent.toDate)
          ) {
            count = count + 1;
            priceArr.push(salesData[sale].price);
          }
        }
        if (reportContent.indicator === NUMBER_OF_SALES) {
          countArr.push(count);
          return countArr;
        }
        if (reportContent.indicator === SALES_VOLUME) {
          let priceVolume = 0;
          for (let i = 0; i < priceArr.length; i++) {
            priceVolume = priceVolume + priceArr[i];
          }
          volumeArr.push(Math.floor(priceVolume));
          return volumeArr;
        }
      });
      return {
        data,
        years: [`From ${reportContent.fromDate} to ${reportContent.toDate}`],
      };
    } else if (reportContent.timePeriod === QUARTERLY) {
      //eslint-disable-next-line
      const data = priceData.map((price) => {
        let countArr = [];
        let volumeArr = [];
        let count = 0;
        let priceArr = [];
        for (let sale = 0; sale < salesData.length; sale++) {
          const salesYear = new Date(
            salesData[sale].registration_date
          ).getFullYear();
          if (
            (salesData[sale].price
              ? salesData[sale].price >= Number(price.range[0]) &&
                salesData[sale].price < Number(price.range[1])
              : false) &&
            salesYear > Number(reportContent.fromDate) &&
            salesYear < Number(reportContent.toDate)
          ) {
            count = count + 1;
            priceArr.push(salesData[sale].price);
          }
        }
        if (reportContent.indicator === NUMBER_OF_SALES) {
          countArr.push(count);
          return countArr;
        }
        if (reportContent.indicator === SALES_VOLUME) {
          let priceVolume = 0;
          for (let i = 0; i < priceArr.length; i++) {
            priceVolume = priceVolume + priceArr[i];
          }
          volumeArr.push(Math.floor(priceVolume));
          return volumeArr;
        }
      });
      return {
        data,
        years: [
          `From Q1 ${reportContent.fromDate} to Q4 ${reportContent.toDate}`,
        ],
      };
    }
  } else {
    arvData = charThreeARVData;
    if (reportContent.timePeriod === YEARLY) {
      //eslint-disable-next-line
      const data = arvData.map((arv) => {
        let countArr = [];
        let volumeArr = [];
        let count = 0;
        let priceArr = [];

        for (let sale = 0; sale < salesData.length; sale++) {
          const salesYear = new Date(
            salesData[sale].registration_date
          ).getFullYear();
          if (
            (salesData[sale].combined_arv
              ? salesData[sale].combined_arv >= Number(arv.range[0]) &&
                salesData[sale].combined_arv < Number(arv.range[1])
              : false) &&
            salesYear > Number(reportContent.fromDate) &&
            salesYear < Number(reportContent.toDate)
          ) {
            count = count + 1;
            priceArr.push(salesData[sale].price);
          }
        }
        if (reportContent.indicator === NUMBER_OF_SALES) {
          countArr.push(count);
          return countArr;
        }
        if (reportContent.indicator === SALES_VOLUME) {
          let priceVolume = 0;
          for (let i = 0; i < priceArr.length; i++) {
            priceVolume = priceVolume + priceArr[i];
          }
          volumeArr.push(Math.floor(priceVolume));
          return volumeArr;
        }
      });
      return {
        data,
        years: [`From ${reportContent.fromDate} to ${reportContent.toDate}`],
      };
    } else if (reportContent.timePeriod === QUARTERLY) {
      //eslint-disable-next-line
      const data = arvData.map((arv) => {
        let countArr = [];
        let volumeArr = [];
        let count = 0;
        let priceArr = [];

        for (let sale = 0; sale < salesData.length; sale++) {
          const salesYear = new Date(
            salesData[sale].registration_date
          ).getFullYear();
          if (
            (salesData[sale].combined_arv
              ? salesData[sale].combined_arv >= Number(arv.range[0]) &&
                salesData[sale].combined_arv < Number(arv.range[1])
              : false) &&
            salesYear > Number(reportContent.fromDate) &&
            salesYear < Number(reportContent.toDate)
          ) {
            count = count + 1;
            priceArr.push(salesData[sale].price);
          }
        }
        if (reportContent.indicator === NUMBER_OF_SALES) {
          countArr.push(count);
          return countArr;
        }
        if (reportContent.indicator === SALES_VOLUME) {
          let priceVolume = 0;
          for (let i = 0; i < priceArr.length; i++) {
            priceVolume = priceVolume + priceArr[i];
          }
          volumeArr.push(Math.floor(priceVolume));
          return volumeArr;
        }
      });
      return {
        data,
        years: [
          `From Q1 ${reportContent.fromDate} to Q4 ${reportContent.toDate}`,
        ],
      };
    }
  }
};
