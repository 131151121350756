import {
  GET_COLLECTIONS_DATA_BY_ID,
  GET_COLLECTIONS_DATA_BY_ID_FAILED,
  GET_COLLECTIONS_DATA_BY_ID_SUCCESS,
  MAKE_COLLECTIONS_DATA_BY_ID_NULL,
} from "redux/constants/CollectionReport";

export const getCollectionsDataById = (id) => {
  return {
    type: GET_COLLECTIONS_DATA_BY_ID,
    id,
  };
};

export const getCollectionsDataByIdSuccess = (data) => {
  return {
    type: GET_COLLECTIONS_DATA_BY_ID_SUCCESS,
    data,
  };
};

export const getCollectionsDataByIdFailed = (message) => {
  return {
    type: GET_COLLECTIONS_DATA_BY_ID_FAILED,
    message,
  };
};

export const mekeCollectionsDataByIdNull = () => {
  return {
    type: MAKE_COLLECTIONS_DATA_BY_ID_NULL,
  };
};
