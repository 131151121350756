import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import { latestListingsFinder, latestSalesFinder } from "configs/TableConstants";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
    paddingTop: "3vh",
    paddingBottom: "11vh",
    paddingHorizontal: 10,
  },
  titleWrapper: {
    textAlign: "center",
    height: "8vh",
  },
  titleName: {
    fontSize: 16,
    fontWeight: 800,
    marginTop: 2,
    color: "#1A3353",
  },
  titleDate: {
    fontSize: 14,
    fontWeight: 500,
    marginTop: 3,
    color: "#455560",
  },
  titleInfo: {
    fontSize: 12,
    fontWeight: 500,
    marginTop: 3,
    color: "#455560",
  },
  headingName: {
    fontSize: 12,
    fontWeight: 700,
    marginTop: 3,
    color: "#1A3353",
    textTransform: "capitalize",
  },
  headingInfo: {
    fontSize: 10,
    fontWeight: 500,
    marginTop: 3,
    color: "#1A3353",
  },
  headingPrice: {
    fontSize: 12,
    fontWeight: 400,
    marginTop: 3,
    color: "#455560",
  },

  heading: {
    fontSize: 10,
    fontWeight: 400,
    marginTop: 3,
  },
  comment: {
    fontSize: 10,
    fontWeight: 300,
    marginTop: 3,
    color: "#455560",
    textAlign: "justify",
  },
  commentNotAvailable: {
    fontSize: 10,
    fontWeight: 400,
    marginTop: 3,
    color: "#FFFFFF",
  },
  itemsWrapper: {
    marginLeft: 10,
  },
  itemsWrapperA: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "100%",
    // marginTop: 20,
  },
  row: {
    width: "40%",
    marginHorizontal: 5,
  },

  priceWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    textTransform: "capitalize",
    width: "100%",
  },
  image: {
    // width: "100%",
    height: 170,
    objectFit: "cover",
    marginTop: 10,
    marginBottom: 10,
  },
  titlePlaceholder: {
    height: "4vh",
  },
});

// Create Document Component
const MyDocument = (props) => {
  const { data, dateValue } = props;
  const {
    contact_name: name,
    contact_phone: phone,
    contact_email: email,
    // collection_properties,
  } = data;

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.titleWrapper}>
          <Text style={styles.titleName}>{data.title}</Text>
          <Text style={styles.titleDate}>{dateValue}</Text>
          <Text style={styles.titleInfo}>
            Contact: {name} {email ? `| ${email}` : ""}{" "}
            {phone ? `| ${phone}` : ""}
          </Text>
        </View>

        <View style={styles.itemsWrapperA}>
          {data.collection_properties.map((item, index) => {
            const { comment } = item;
            const {
              name,
              arv,
              parish,
              images,
              property_type,
              bedrooms,
              bathrooms,
            } = item.property_database;
            let mutableName = <Text style={styles.headingName}>{name}</Text>;

            if (
              index % 2 === 0 &&
              index !== data.collection_properties.length - 1
            ) {
              const propertyName = name;
              const propertyNameLength = propertyName.length;
              const nextPropertyName =
                data.collection_properties[index + 1].property_database.name;
              const nextPropertyNameLength = nextPropertyName.length;

              if (propertyNameLength < nextPropertyNameLength) {
                let nameToAdd = nextPropertyName.slice(propertyNameLength);

                mutableName = (
                  <Text style={styles.headingName}>
                    {propertyName}{" "}
                    <Text style={{ color: "white" }}> {nameToAdd} aaa</Text>
                  </Text>
                );
              }
            } else if (index % 2 !== 0) {
              const propertyName = name;
              const propertyNameLength = propertyName.length;
              const prevPropertyName =
                data.collection_properties[index - 1].property_database.name;

              const prevPropertyNameLength = prevPropertyName.length;

              if (propertyNameLength < prevPropertyNameLength) {
                let nameToAdd = prevPropertyName.slice(propertyNameLength);

                mutableName = (
                  <Text style={styles.headingName}>
                    {propertyName}{" "}
                    <Text style={{ color: "white" }}> {nameToAdd}</Text>
                  </Text>
                );
              }
            }
            let price = null;
            let bathRooms;
            let bedRooms = bedrooms ? `| ${bedrooms} Bedrooms` : null;
            if (bedrooms) {
              bathRooms = bathrooms ? ` ${bathrooms} Bathrooms` : null;
            } else {
              bathRooms = bathrooms ? `| ${bathrooms} Bathrooms ` : null;
            }

            if (
              item.property_database.property_database_listings.length > 0
            ) {
              const listingsRecord = latestListingsFinder(item.property_database.property_database_listings);
              price = `$${listingsRecord.price.toLocaleString()}`;
            } else if (
              item.property_database.sales.length > 0
            ) {
              const salesRecord = latestSalesFinder(item.property_database.sales);
              price = `$${salesRecord.price.toLocaleString()}`;
            }
            let imgSrc = "/img/nologo.png";
            if (images !== null && images.length > 0) {
              imgSrc = {
                uri: images[0],
                method: "GET",
                headers: {
                  "Access-Control-Allow-Origin": "*",
                },
                body: "",
              };
            }

            return (
              <View
                key={item.id}
                style={{ ...styles.row, height: index > 3 ? "43vh" : "39vh" }}
              >
                {mutableName}
                <Text style={styles.headingInfo}>
                  ARV: {`$${arv.toLocaleString()}`} {bedRooms}
                  {bathRooms}
                </Text>
                <Image style={styles.image} src={imgSrc} />
                <View style={styles.priceWrapper}>
                  <Text style={styles.headingPrice}>
                    {property_type} | {parish}
                  </Text>
                  <Text style={styles.headingPrice}>{price}</Text>
                </View>

                <View>
                  <Text style={styles.comment}>{comment}</Text>
                </View>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
