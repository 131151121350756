import * as React from "react";
import { Col, Row } from "antd";
//user-imports
import classes from "./styles.module.css";
import { dateFormatHandler, latestListingsFinder, latestSalesFinder } from "configs/TableConstants";

export class ComponentToPrint extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { checked: false };
  }

  handleCheckboxOnChange = () =>
    this.setState({ checked: !this.state.checked });

  render() {
    const { collectionsDataById } = this.props;
    let reportData, dateValue, name, phone, email, image;
    if (collectionsDataById) {
      reportData = collectionsDataById[0];
      dateValue = dateFormatHandler(new Date(reportData.created_on));
      name = reportData.contact_name;
      phone = reportData.contact_phone;
      email = reportData.contact_email;
    }

    return (
      <div>
        <style type="text/css" media="print">
          {
            "\
   @page { size: portrait; }\
"
          }
        </style>
        <>
          {collectionsDataById && (
            <>
              <div className={classes.reportTitle}>
                <p className={classes.heading5}>{reportData.title}</p>
                <p className={classes.heading6}>{dateValue}</p>
                <p className={classes.heading7}>
                  Contact: {name} {email ? `| ${email}` : ""}{" "}
                  {phone ? `| ${phone}` : ""}
                </p>
              </div>
              <div>
                <Row>
                  {reportData.collection_properties.map((item, index) => {
                    const { comment } = item;

                    const {
                      name,
                      arv,
                      parish,
                      images,
                      property_type,
                      bedrooms,
                      bathrooms,
                    } = item.property_database;
                    let price = "N/A";

                    image = images !== null ? images[0] : "/img/nologo.png";
                    let mutableName = (
                      <p className={classes.report_heading1}>{name}</p>
                    );

                    if (
                      index % 2 === 0 &&
                      index !== reportData.collection_properties.length - 1
                    ) {
                      const propertyName = name;
                      const propertyNameLength = propertyName.length;
                      const nextPropertyName =
                        reportData.collection_properties[index + 1]
                          .property_database.name;

                      const nextPropertyNameLength = nextPropertyName.length;

                      if (propertyNameLength < nextPropertyNameLength) {
                        let nameToAdd =
                          nextPropertyName.slice(propertyNameLength);

                        mutableName = (
                          <p className={classes.report_heading1}>
                            {propertyName}
                            <span style={{ color: "white" }}> {nameToAdd}</span>
                          </p>
                        );
                      }
                    } else if (index % 2 !== 0) {
                      const propertyName = name;
                      const propertyNameLength = propertyName.length;
                      const prevPropertyName =
                        reportData.collection_properties[index - 1]
                          .property_database.name;

                      const prevPropertyNameLength = prevPropertyName.length;

                      if (propertyNameLength < prevPropertyNameLength) {
                        let nameToAdd =
                          prevPropertyName.slice(propertyNameLength);

                        mutableName = (
                          <p className={classes.report_heading1}>
                            {propertyName}
                            <span style={{ color: "white" }}> {nameToAdd}</span>
                          </p>
                        );
                      }
                    }

                    if (

                      item.property_database.property_database_listings.length >
                      0
                    ) {
                      const listingsRecord = latestListingsFinder(item.property_database.property_database_listings);
                      price = `$${listingsRecord.price.toLocaleString()}`;
                    } else if (
                      item.property_database.sales.length > 0
                    ) {
                      const salesRecord = latestSalesFinder(item.property_database.sales);
                      price = `$${salesRecord.price.toLocaleString()}`;

                    }
                    let bathRooms;
                    let bedRooms = bedrooms ? `| ${bedrooms} Bedrooms` : null;
                    if (bedrooms) {
                      bathRooms = bathrooms ? ` ${bathrooms} Bathrooms` : null;
                    } else {
                      bathRooms = bathrooms
                        ? ` | ${bathrooms} Bathrooms`
                        : null;
                    }

                    return (
                      <Col span={12} key={item.id}>
                        <div
                          style={{
                            margin: "20px auto",
                            width: "fit-content",
                          }}
                        >
                          {mutableName}

                          <p className={classes.report_heading2}>
                            ARV: {`$${arv.toLocaleString()}`} {bedRooms}{" "}
                            {bathRooms}
                          </p>
                          <img
                            src={image}
                            alt="logo"
                            className={classes.report_cover_image}
                          />
                          <div className={classes.report_heading3}>
                            <p className={classes.capitalize_text}>
                              {property_type} | {parish}
                            </p>
                            <p>{price}</p>
                          </div>
                          <p className={classes.report_heading4}>{comment}</p>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </>
          )}
        </>
      </div>
    );
  }
}

export const FunctionalComponentToPrint = React.forwardRef((props, ref) => {
  // eslint-disable-line max-len
  return <ComponentToPrint ref={ref} text={props.text} />;
});
