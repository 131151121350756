import {useCallback, useState} from "react";
import { Input } from "antd";
import debounce from "lodash/debounce";

const InputValueBox = (props) => {
  const { defaultValue, onValueChange, propertyFiltersData, name, title } =
    props;
  const [value, setValue] = useState(defaultValue)

  const debouncedOnChange = useCallback((value) => {
    debounce(() => {
      onValueChange({
        ...propertyFiltersData,
        [name]: value,
      });
    }, 300)();
  }, [propertyFiltersData, name, onValueChange]);

  const valueHandler = (e) => {
    const value = e.target.value;
    setValue(value)
    debouncedOnChange(value);
  }

  return (
    <>
      <div className="sidenav-filters--div3">
        <p className="sidenav-heading1">{title}</p>
        <div>
          <Input
            placeholder="Search by name"
            value={value}
            onChange={valueHandler}
          />
        </div>
      </div>
    </>
  );
};

export default InputValueBox;
