import {
  DELETE_MULTIPLE_REPORTS,
  DELETE_MULTIPLE_REPORTS_FAILED,
  DELETE_MULTIPLE_REPORTS_SUCCESS,
  GET_REPORTS_DATA,
  GET_REPORTS_DATA_FAILED,
  GET_REPORTS_DATA_SUCCESS,
  GET_SALES_DATA,
  GET_SALES_DATA_FAILED,
  GET_SALES_DATA_SUCCESS,
  GET_TREND_REPORT_DETAILS,
  GET_TREND_REPORT_DETAILS_FAILED,
  GET_TREND_REPORT_DETAILS_SUCCESS,
  HIDE_DELETE_MULTIPLE_REPORTS_ERROR,
  REMOVE_ALL_CHARTS,
  REMOVE_CHART,
  SAVE_REPORT,
  SAVE_REPORT_CONTENT,
  SAVE_REPORT_CONTENT_FAILED,
  SAVE_REPORT_CONTENT_SUCCESS,
  SAVE_REPORT_FAILED,
  SAVE_REPORT_SUCCESS,
  SET_ADD_NEW_CHART_IN_VALID,
  SET_ADD_NEW_CHART_VALID,
  SET_CHART_COMMENT_VALUE,
  SET_CHART_DATA,
  SET_CHART_DATA_LOADING,
  SET_CHART_LABELS,
  SET_INITITAL_REPORT_DETAILS,
  SET_LOCATIONS_OPTIONS,
  SET_MARKET_ANALYSIS_IS_VALID,
  SET_NEW_CHART,
  SET_NEW_REPROT_IN_VALID,
  SET_NEW_REPROT_VALID,
  SET_PRICE_BRACKET_OPTIONS,
  SET_REPORT_CONTENT,
  SET_REPORT_CONTENT_LOCATION_IS_VALID,
  SET_REPORT_CONTENT_TITLE_IS_VALID,
  SET_REPORT_INFO,
  SET_REPORT_INFO_TITLE_IS_VALID,
  SET_REPORT_STEP,
  SET_SAVE_REPORT_ERROR_NULL,
  SET_SAVE_REPORT_SUCCESS_INVALID,
  SET_SHOW_TABLE_IS_VALID,
  SET_TRENDS_REPORTS_PAGINATION,
  SET_TREND_REPORT_DETAILS_NULL,
} from "redux/constants/Report";

export const getReportsData = () => {
  return {
    type: GET_REPORTS_DATA,
  };
};

export const getReportsDataSuccess = (data) => {
  return {
    type: GET_REPORTS_DATA_SUCCESS,
    data,
  };
};

export const getReportsDataFailed = (message) => {
  return {
    type: GET_REPORTS_DATA_FAILED,
    message,
  };
};

export const saveReportContent = (data) => {
  return {
    type: SAVE_REPORT_CONTENT,
    data,
  };
};

export const saveReportContentSuccess = () => {
  return {
    type: SAVE_REPORT_CONTENT_SUCCESS,
  };
};

export const saveReportContentFailed = (message) => {
  return {
    type: SAVE_REPORT_CONTENT_FAILED,
    message,
  };
};

export const saveReport = (reportInfo, reportContent) => {
  return {
    type: SAVE_REPORT,
    data: { reportInfo, reportContent },
  };
};

export const saveReportSuccess = () => {
  return {
    type: SAVE_REPORT_SUCCESS,
  };
};

export const saveReportFailed = (message) => {
  return {
    type: SAVE_REPORT_FAILED,
    message,
  };
};

export const setTrendsReportPagination = (data) => {
  return {
    type: SET_TRENDS_REPORTS_PAGINATION,
    data,
  };
};

export const getSalesData = () => {
  return {
    type: GET_SALES_DATA,
  };
};

export const getSalesDataSuccess = (data) => {
  return {
    type: GET_SALES_DATA_SUCCESS,
    data,
  };
};

export const getSalesDataFailed = (message) => {
  return {
    type: GET_SALES_DATA_FAILED,
    message,
  };
};

export const setNewReportValid = () => {
  return {
    type: SET_NEW_REPROT_VALID,
  };
};

export const setNewReportInValid = () => {
  return {
    type: SET_NEW_REPROT_IN_VALID,
  };
};

export const setChartCommentValue = (value) => {
  return {
    type: SET_CHART_COMMENT_VALUE,
    value,
  };
};

export const setAddNewChartValid = () => {
  return {
    type: SET_ADD_NEW_CHART_VALID,
  };
};

export const setAddNewChartInValid = () => {
  return {
    type: SET_ADD_NEW_CHART_IN_VALID,
  };
};

export const setStep = (stepValue, chartId) => {
  return {
    type: SET_REPORT_STEP,
    stepValue,
    chartId,
  };
};

export const setReportInfo = (data) => {
  return {
    type: SET_REPORT_INFO,
    data,
  };
};

export const setReportContent = (data, chartId) => {
  return {
    type: SET_REPORT_CONTENT,
    data,
    chartId,
  };
};

export const setLocationOptionSelection = (data, chartId) => {
  return {
    type: SET_LOCATIONS_OPTIONS,
    data,
    chartId,
  };
};

export const setBracketTypeOptionSelection = (data, chartId) => {
  return {
    type: SET_PRICE_BRACKET_OPTIONS,
    data,
    chartId,
  };
};

export const setChartLabels = (data, chartId) => {
  return {
    type: SET_CHART_LABELS,
    data,
    chartId,
  };
};

export const setChartDataLoading = () => {
  return {
    type: SET_CHART_DATA_LOADING,
  };
};

export const setChartData = (data, chartId) => {
  return {
    type: SET_CHART_DATA,
    data,
    chartId,
  };
};

export const setReportContentTitleIsValid = (value, chartId) => {
  return {
    type: SET_REPORT_CONTENT_TITLE_IS_VALID,
    value,
    chartId,
  };
};

export const setReportInfoTitleIsValid = (value) => {
  return {
    type: SET_REPORT_INFO_TITLE_IS_VALID,
    value,
  };
};

export const getTrendReportDetails = (id) => {
  return {
    type: GET_TREND_REPORT_DETAILS,
    id,
  };
};

export const getTrendReportDetailsSuccess = (data) => {
  return {
    type: GET_TREND_REPORT_DETAILS_SUCCESS,
    data,
  };
};

export const getTrendReportDetailsFailed = (message) => {
  return {
    type: GET_TREND_REPORT_DETAILS_FAILED,
    message,
  };
};

export const setTrendReportDetailsNull = () => {
  return {
    type: SET_TREND_REPORT_DETAILS_NULL,
  };
};

export const setMarketAnalysisIsValid = (value, chartId) => {
  return {
    type: SET_MARKET_ANALYSIS_IS_VALID,
    value,
    chartId,
  };
};

export const setReportContentLocationIsValid = (value) => {
  return {
    type: SET_REPORT_CONTENT_LOCATION_IS_VALID,
    value,
  };
};

export const setInitialReportDetails = () => {
  return {
    type: SET_INITITAL_REPORT_DETAILS,
  };
};

export const setSaveReportSuccessInValid = () => {
  return {
    type: SET_SAVE_REPORT_SUCCESS_INVALID,
  };
};

export const setSaveReportErrorNull = () => {
  return {
    type: SET_SAVE_REPORT_ERROR_NULL,
  };
};

export const setShowTableIsValid = (value, chartId) => {
  return {
    type: SET_SHOW_TABLE_IS_VALID,
    value,
    chartId,
  };
};

export const setNewChart = (chartNumber) => {
  return {
    type: SET_NEW_CHART,
    chartNumber,
  };
};

export const removeChart = (NumberOfCharts, chart, singleChartContent) => {
  const remainingCharts = NumberOfCharts.filter(
    (oldChart) => oldChart !== chart
  );
  return {
    type: REMOVE_CHART,
    remainingCharts,
    singleChartContent,
  };
};

export const removeAllChart = () => {
  return {
    type: REMOVE_ALL_CHARTS,
  };
};

export const deleteMultipleReports = (reportIds) => {
  return {
    type: DELETE_MULTIPLE_REPORTS,
    reportIds,
  };
};

export const deleteMultipleReportsSuccess = (data) => {
  return {
    type: DELETE_MULTIPLE_REPORTS_SUCCESS,
    data,
  };
};
export const deleteMultipleReportsFailed = () => {
  return {
    type: DELETE_MULTIPLE_REPORTS_FAILED,
  };
};

export const hideDeleteMultipleReportsError = () => {
  return {
    type: HIDE_DELETE_MULTIPLE_REPORTS_ERROR,
  };
};
