import {
  SIGNIN,
  AUTHENTICATED,
  SUPABASE_AUTHENTICATED,
  SIGNOUT,
  SHOW_AUTH_MESSAGE,
  SHOW_AUTH_SUCCESS_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNUP,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  GO_BACK,
} from "../constants/Auth";

export const signIn = (user) => {
  return {
    type: SIGNIN,
    payload: user,
  };
};

export const goBack = () => {
  return {
    type: GO_BACK,
  };
};

export const authenticated = (token) => {
  return {
    type: AUTHENTICATED,
    token,
  };
};

export const supabase_authenticated = (session) => {
  return {
    type: SUPABASE_AUTHENTICATED,
    session,
  };
};

export const signOut = () => {
  return {
    type: SIGNOUT,
  };
};

export const signUp = (user) => {
  return {
    type: SIGNUP,
    payload: user,
  };
};

export const signUpSuccess = (token) => {
  return {
    type: SIGNUP_SUCCESS,
    token,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_AUTH_MESSAGE,
    message,
  };
};
export const showAuthSuccessMessage = (message) => {
  return {
    type: SHOW_AUTH_SUCCESS_MESSAGE,
    message,
  };
};

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};
