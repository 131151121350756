import ReactHtmlParser from "react-html-parser";
const PropertyDetailsDescription = (props) => {
  const { propertyData } = props;
  const { long_description } = propertyData;

  return (
    <div className="property-details-div5">
      <p className="property-database-heading7">Long description</p>
      <div className="parse-heading-class">
        {ReactHtmlParser(long_description)}
      </div>
    </div>
  );
};

export default PropertyDetailsDescription;
