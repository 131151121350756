export const SHOW_COLLECTION_FORM = "SHOW_COLLECTION_FORM";
export const HIDE_COLLECTION_FORM = "HIDE_COLLECTION_FORM";
export const CREATE_COLLECTION = "CREATE_COLLECTION";
export const CREATE_COLLECTION_FAILED = "CREATE_COLLECTION_FAILED";
export const SET_COLLECTION_DATA = "SET_COLLECTION_DATA";
export const GET_ALL_COLLECTIONS = "GET_ALL_COLLECTIONS";
export const GET_ALL_COLLECTIONS_FAILED = "GET_ALL_COLLECTIONS_FAILED";
export const SET_COLLECTIONS_DATA = "SET_COLLECTIONS_DATA";
export const GET_COLLECTION_BY_ID = "GET_COLLECTION_BY_ID";
export const GET_COLLECTION_BY_ID_SUCCESS = "GET_COLLECTION_BY_ID_SUCCESS";
export const GET_COLLECTION_BY_ID_FAILED = "GET_COLLECTION_BY_ID_FAILED";
export const SET_COLLECTION_DATA_BY_ID_NULL = "SET_COLLECTION_DATA_BY_ID_NULL";
export const HIDE_ERROR_MESSAGE = "HIDE_ERROR_MESSAGE";
export const SHOW_COLLECTION_MODAL = "SHOW_COLLECTION_MODAL";
export const HIDE_COLLECTION_MODAL = "HIDE_COLLECTION_MODAL";
export const DELETE_COLLECTION_BY_ID = "DELETE_COLLECTION_BY_ID";
export const DELETE_COLLECTION_BY_ID_SUCCESS =
  "DELETE_COLLECTION_BY_ID_SUCCESS";
export const DELETE_COLLECTION_BY_ID_FAILED = "DELETE_COLLECTION_BY_ID_FAILED";
export const HIDE_COLLECTION_MESSAGE = "HIDE_COLLECTION_MESSAGE";
export const DISALLOW_COLLECTION_REDIRECT = "DISALLOW_COLLECTION_REDIRECT";
export const ADD_PROPERTY_TO_COLLECTION = "ADD_PROPERTY_TO_COLLECTION";
export const REMOVE_PROPERTY_FROM_COLLECTION =
  "REMOVE_PROPERTY_FROM_COLLECTION";
export const ADD_NEW_PROPERTIES_TO_COLLECTION =
  "ADD_NEW_PROPERTIES_TO_COLLECTION";
export const SET_COLLECTION_DETAILS_PAGINATION =
  "SET_COLLECTION_DETAILS_PAGINATION";
export const SET_COLLECTION_PAGINATION = "SET_COLLECTION_PAGINATION";
export const APPLY_COLLECTION_FILTERS = "APPLY_COLLECTION_FILTERS";
export const RESET_COLLECTION_FILTERS = "RESET_COLLECTION_FILTERS";
export const SET_COLLECTION_FILTERED_DATA = "SET_COLLECTION_FILTERED_DATA";
export const MAKE_COLLECTION_FILTERED_DATA_NULL =
  "MAKE_COLLECTION_FILTERED_DATA_NULL";
export const MAKE_COLLECTIONS_DATA_NULL = "MAKE_COLLECTION_DATA_NULL";
export const CLOSE_COLLECTION = "CLOSE_COLLECTION";
export const CHANGE_COLLECTION_FILTERS_DATA = "CHANGE_COLLECTION_FILTERS_DATA";
export const DELETE_MULTIPLE_COLLECTION = "DELETE_MULTIPLE_COLLECTION";
export const DELETE_MULTIPLE_COLLECTION_SUCCESS =
  "DELETE_MULTIPLE_COLLECTION_SUCCESS";
export const DELETE_MULTIPLE_COLLECTION_FAILED =
  "DELETE_MULTIPLE_COLLECTION_FAILED";
export const SET_DELETE_MULTIPLE_COLLECTION_ERROR_FALSE =
  "SET_DELETE_MULTIPLE_COLLECTION_ERROR_FALSE";
export const SET_REDIRECT_TO_COLLECTIONPAGE_TURE =
  "SET_REDIRECT_TO_COLLECTIONPAGE_TURE";
export const SET_REDIRECT_TO_COLLECTIONPAGE_FALSE =
  "SET_REDIRECT_TO_COLLECTIONPAGE_FALSE";

export const SET_COLLECTION_ID = "SET_COLLECTION_ID";
