import {
  GETDATA,
  SETDATA,
  DELETEDATA_ID,
  DELETEDATA_ID_SUCCESS,
  SHOW_SUCCESS_MESSAGE,
  SHOW_ERROR_MESSAGE,
  HIDE_MESSAGE,
  SHOW_FILTERS,
  HIDE_FILTERS,
  SHOW_LOADING,
  SHOW_MODAL,
  HIDE_MODAL,
  DISALLOW_REDIRECT,
  SHOW_LESS_FILTERS,
  SHOW_ALL_FILTERS,
  GET_PROPERTY_DATA_BY_ID,
  GET_PROPERTY_DATA_BY_ID_SUCCESS,
  GET_PROPERTY_DATA_BY_ID_FAILED,
  SAVE_EDITING_SUCCESS,
  HIDE_DATA_BY_ID_ERROR,
  SET_DATA_BY_ID_NULL,
  SET_PROPERTYDATABASE_PAGINATION,
  FILTERED_DATA,
  APPLY_PROPERTY_FILTERS,
  GET_FILTER_MAX_VALUES,
  SET_FILTER_MAX_VALUES,
  RESET_FILTERS,
  SET_REMOVE_BUTTON_STATE,
  RESERT_REMOVE_BUTTON_STATE,
  CHANGE_REMOVE_BUTTON_STATE,
  GET_FILTER_MAX_VALUES_FAILED,
  CHANGE_PROPERTY_FILTERS_DATA,
  SET_PROPERTY_FILTER_LOADING,
  PREVENT_APPLYING_FILTERS,
  SET_PROPERTY_DATABASE_FILTERS_INVALID,
  SET_PROPERTY_DATABASE_FILTERS_VALID,
  SET_BACK_TO_DATABASE_VALID,
  SET_BACK_TO_DATABASE_INVALID,
  GET_UNDEFINED_DATA_UNITS,
  GET_UNDEFINED_DATA_UNITS_SUCCESS,
  GET_UNDEFINED_DATA_UNITS_FAILED,
  MAKE_UNDEFINED_DATA_UNITS_INVALID,
  MAKE_UNDEFINED_DATA_UNITS_VALID,
  GET_SOLD_IN_THE_PAST,
  GET_SOLD_IN_THE_PAST_SUCCESS,
  GET_SOLD_IN_THE_PAST_FAILED,
  MAKE_SOLD_IN_THE_PAST_VALID,
  MAKE_SOLD_IN_THE_PAST_INVALID,
  GET_SALES_APPLICATION_NUMBERS,
  GET_SALES_APPLICATION_NUMBERS_ERROR,
  GET_SALES_APPLICATION_NUMBERS_SUCCESS,
} from "../constants/PropertyDatabase";

const initState = {
  data: null,
  dataById: null,
  loading: false,
  dataByIdLoading: false,
  filters: false,
  lessFilters: false,
  dataMessage: "",
  dataByIdMessage: "",
  dataByIdError: false,
  dataShowErrorMessage: false,
  dataShowSuccessMessage: false,
  deleteDataLoading: false,
  allowRedirect: false,
  modalIsValid: false,
  propertyDatabasePagniation: { current: 1, pageSize: 10 },
  propertyFilteredData: null,
  propertyFiltersMaxValues: [],
  filterResetMessage: false,
  propertyFiltersLoading: false,
  removeButtonState: {},
  applyFiltersIsValid: false,
  propertyDatabaseFiltersIsValid: true,
  backTodatabaseIsVaild: false,
  propertyFiltersData: {
    parish: [],
    price: [0, null],
    bedrooms: [],
    bathrooms: [],
    minDate: new Date(),
    maxDate: new Date(),
    propertyType: [],
    availability: "",
    status: [],
    arv: [0, null],
    soldInPast: false,
    undefinedDataUnits: false,
  },
  undefinedDataUnits: null,
  undefinedDataUnitsLoading: false,
  undefinedDataUnitsErrorMessage: null,
  undefinedDataUnitsIsValid: false,
  soldInThePastData: null,
  soldInThePastDataLoading: false,
  soldInThePastDataErrorMessage: null,
  soldInThePastDataIsValid: false,
  propertiesModalIsValid: true,
  sales_applicationNumbers: [],
  sales_applicationNumbersLoading: false,
};
const getPropertyFilterValues = (data) => {
  return {
    parish: [],
    propertyType: [],
    availability: "",
    status: [],
    price: [0, Number(data[0])],
    maxDate: new Date(data[1]),
    minDate: new Date(data[2]),
    bedrooms: [],
    bathrooms: [],
    arv: [0, Number(data[5])],
    soldInPast: false,
    undefinedDataUnits: false,
  };
};
const propertyDatabase = (state = initState, action) => {
  switch (action.type) {
    case GETDATA:
      return {
        ...state,
        loading: true,
      };
    case SETDATA:
      return {
        ...state,
        data: action.data,
        loading: false,
        applyFiltersIsValid: true,
      };
    case GET_SALES_APPLICATION_NUMBERS:
      return {
        ...state,
        sales_applicationNumbersLoading: true,
      };
    case GET_SALES_APPLICATION_NUMBERS_ERROR:
      return {
        ...state,
        sales_applicationNumbersLoading: false,
      };
    case GET_SALES_APPLICATION_NUMBERS_SUCCESS:
      return {
        ...state,
        sales_applicationNumbers: action?.data,
        sales_applicationNumbersLoading: false,
      };
    case SHOW_FILTERS:
      return {
        ...state,
        filters: true,
      };

    case SHOW_LESS_FILTERS:
      return {
        ...state,
        lessFilters: true,
        filters: true,
      };
    case SHOW_ALL_FILTERS:
      return {
        ...state,
        lessFilters: false,
      };
    case DELETEDATA_ID:
      return {
        ...state,
        deleteDataLoading: true,
      };
    case DELETEDATA_ID_SUCCESS:
      return {
        ...state,
        allowRedirect: true,
        data: null,
        deleteDataLoading: false,
        propertiesModalIsValid: false,
      };
    case GET_PROPERTY_DATA_BY_ID:
      return {
        ...state,
        dataByIdLoading: true,
        dataByIdError: false,
        dataByIdMessage: null,
      };
    case GET_PROPERTY_DATA_BY_ID_SUCCESS:
      return {
        ...state,
        dataById: action.data,
        dataByIdLoading: false,
      };

    case GET_PROPERTY_DATA_BY_ID_FAILED:
      return {
        ...state,
        dataByIdError: true,
        dataByIdMessage: action.message,
        dataByIdLoading: false,
      };
    case HIDE_DATA_BY_ID_ERROR:
      return {
        ...state,
        dataByIdError: false,
        dataByIdMessage: "",
      };

    case DISALLOW_REDIRECT:
      return {
        ...state,
        allowRedirect: false,
      };
    case HIDE_FILTERS:
      return {
        ...state,
        filters: false,
      };
    case SHOW_LOADING:
      return {
        ...state,
        loading: true,
      };
    case "HIDE_LOADING":
      return {
        ...state,
        loading: false,
      };
    case SHOW_SUCCESS_MESSAGE:
      return {
        ...state,
        loading: false,
        deleteDataLoading: false,
        dataMessage: action.message,
        dataShowSuccessMessage: true,
      };
    case SHOW_ERROR_MESSAGE:
      return {
        ...state,
        loading: false,
        deleteDataLoading: false,
        dataMessage: action.message,
        dataShowErrorMessage: true,
      };
    case HIDE_MESSAGE:
      return {
        ...state,
        dataMessage: "",
        dataShowErrorMessage: false,
        dataShowSuccessMessage: false,
      };
    case SHOW_MODAL:
      return {
        ...state,
        modalIsValid: true,
      };
    case HIDE_MODAL:
      return {
        ...state,
        modalIsValid: false,
      };
    case SAVE_EDITING_SUCCESS:
      return {
        ...state,
        dataById: null,
        data: null,
        propertyFilteredData: null,
        propertiesModalIsValid: false,
      };
    case SET_DATA_BY_ID_NULL:
      return {
        ...state,
        dataById: null,
      };
    case SET_PROPERTYDATABASE_PAGINATION:
      return {
        ...state,
        propertyDatabasePagniation: action.data,
      };
    case APPLY_PROPERTY_FILTERS:
      return {
        ...state,
        loading: true,
      };
    case FILTERED_DATA:
      return {
        ...state,
        loading: false,
        propertyFilteredData: action.data,
      };
    case RESET_FILTERS:
      return {
        ...state,
        propertyFiltersLoading: false,
        propertyFiltersData: getPropertyFilterValues(action.data),
      };

    case CHANGE_PROPERTY_FILTERS_DATA:
      return {
        ...state,
        propertyFiltersData: action.data,
        applyFiltersIsValid: true,
      };

    case GET_FILTER_MAX_VALUES:
      return {
        ...state,
        propertyFiltersLoading: true,
      };
    case SET_PROPERTY_FILTER_LOADING:
      return {
        ...state,
        propertyFiltersLoading: true,
        applyFiltersIsValid: true,
      };

    case SET_FILTER_MAX_VALUES:
      return {
        ...state,
        propertyFiltersLoading: false,
        propertyFiltersMaxValues: action.data,
        propertyFiltersData: getPropertyFilterValues(action.data),
      };

    case GET_FILTER_MAX_VALUES_FAILED:
      return {
        ...state,
        propertyFiltersLoading: false,
        propertyFiltersMaxValues: [],
        filterResetMessage: true,
      };
    case SET_REMOVE_BUTTON_STATE: {
      return {
        ...state,
        removeButtonState: {
          ...state.removeButtonState,
          [action.propertiesId]: true,
        },
      };
    }
    case RESERT_REMOVE_BUTTON_STATE: {
      return {
        ...state,
        removeButtonState: {},
      };
    }
    case CHANGE_REMOVE_BUTTON_STATE: {
      return {
        ...state,
        removeButtonState: {
          ...state.removeButtonState,
          [action.propertiesId]: false,
        },
      };
    }
    case PREVENT_APPLYING_FILTERS:
      return {
        ...state,
        applyFiltersIsValid: false,
      };
    case SET_PROPERTY_DATABASE_FILTERS_VALID:
      return {
        ...state,
        propertyDatabaseFiltersIsValid: true,
      };
    case SET_PROPERTY_DATABASE_FILTERS_INVALID:
      return {
        ...state,
        propertyDatabaseFiltersIsValid: false,
      };
    case SET_BACK_TO_DATABASE_VALID:
      return {
        ...state,
        backTodatabaseIsVaild: true,
      };
    case SET_BACK_TO_DATABASE_INVALID:
      return {
        ...state,
        backTodatabaseIsVaild: false,
      };
    case GET_UNDEFINED_DATA_UNITS:
      return {
        ...state,
        undefinedDataUnitsLoading: true,
        undefinedDataUnitsIsValid: true,
        soldInThePastDataIsValid: false,
        propertyFilteredData: null,
      };
    case GET_UNDEFINED_DATA_UNITS_SUCCESS:
      return {
        ...state,
        undefinedDataUnits: action.data,
        undefinedDataUnitsLoading: false,
      };
    case GET_UNDEFINED_DATA_UNITS_FAILED:
      return {
        ...state,
        undefinedDataUnitsLoading: false,
        undefinedDataUnitsErrorMessage: action.message,
      };
    case MAKE_UNDEFINED_DATA_UNITS_INVALID:
      return {
        ...state,
        undefinedDataUnitsIsValid: false,
      };
    case MAKE_UNDEFINED_DATA_UNITS_VALID:
      return {
        ...state,
        undefinedDataUnitsIsValid: true,
        soldInThePastDataIsValid: false,
        propertyFilteredData: null,
      };
    case GET_SOLD_IN_THE_PAST:
      return {
        ...state,
        soldInThePastDataLoading: true,
        soldInThePastDataIsValid: true,
        undefinedDataUnitsIsValid: false,
        propertyFilteredData: null,
      };
    case GET_SOLD_IN_THE_PAST_SUCCESS:
      return {
        ...state,
        soldInThePastData: action.data,
        soldInThePastDataLoading: false,
      };
    case GET_SOLD_IN_THE_PAST_FAILED:
      return {
        ...state,
        soldInThePastDataLoading: false,
        soldInThePastDataErrorMessage: action.message,
      };
    case MAKE_SOLD_IN_THE_PAST_VALID:
      return {
        ...state,
        soldInThePastDataIsValid: true,
        undefinedDataUnitsIsValid: false,
        // propertyFilteredData: null,
      };
    case MAKE_SOLD_IN_THE_PAST_INVALID:
      return {
        ...state,
        soldInThePastDataIsValid: false,
      };
    default:
      return state;
  }
};

export default propertyDatabase;
