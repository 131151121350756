import React, { Component } from "react";
// import { SettingOutlined } from "@ant-design/icons"; setting Logo
import { Drawer } from "antd";
import ThemeConfigurator from "./ThemeConfigurator";
import { connect } from "react-redux";
import { DIR_RTL } from "constants/ThemeConstant";
import { signOut } from "redux/actions/Auth";

export class NavPanel extends Component {
  state = { visible: false };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { signOut, direction, session } = this.props;
    return (
      <>
        {/* 
        To be used latter if nessessary
        
        <Menu mode="horizontal">
         <Menu.Item key="panel" onClick={() => signOut()}>
            Setting logo 
            <span><SettingOutlined className="nav-icon mr-0" /></span> 
            <p style={{ marginBottom: "20px" }}>Logout</p>
          </Menu.Item> 
        </Menu> */}
        {session && (
          <div className="logout-wrapper " onClick={() => signOut()}>
            <p className="logout-heading">Logout</p>
          </div>
        )}

        <Drawer
          title="Theme Config"
          placement={direction === DIR_RTL ? "left" : "right"}
          width={350}
          onClose={this.onClose}
          open={this.state.visible}
        >
          <ThemeConfigurator />
        </Drawer>
      </>
    );
  }
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale } = theme;
  const { session } = auth;
  return { locale, session };
};

const mapDispatchToProps = {
  signOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavPanel);
