import {
  SHOW_COLLECTION_FORM,
  HIDE_COLLECTION_FORM,
  SET_COLLECTION_DATA,
  GET_ALL_COLLECTIONS,
  SET_COLLECTIONS_DATA,
  GET_ALL_COLLECTIONS_FAILED,
  GET_COLLECTION_BY_ID,
  GET_COLLECTION_BY_ID_SUCCESS,
  GET_COLLECTION_BY_ID_FAILED,
  SET_COLLECTION_DATA_BY_ID_NULL,
  CREATE_COLLECTION,
  CREATE_COLLECTION_FAILED,
  HIDE_ERROR_MESSAGE,
  SHOW_COLLECTION_MODAL,
  HIDE_COLLECTION_MODAL,
  DELETE_COLLECTION_BY_ID,
  DELETE_COLLECTION_BY_ID_SUCCESS,
  DELETE_COLLECTION_BY_ID_FAILED,
  HIDE_COLLECTION_MESSAGE,
  DISALLOW_COLLECTION_REDIRECT,
  ADD_PROPERTY_TO_COLLECTION,
  ADD_NEW_PROPERTIES_TO_COLLECTION,
  SET_COLLECTION_DETAILS_PAGINATION,
  SET_COLLECTION_PAGINATION,
  REMOVE_PROPERTY_FROM_COLLECTION,
  APPLY_COLLECTION_FILTERS,
  SET_COLLECTION_FILTERED_DATA,
  RESET_COLLECTION_FILTERS,
  MAKE_COLLECTION_FILTERED_DATA_NULL,
  MAKE_COLLECTIONS_DATA_NULL,
  CLOSE_COLLECTION,
  CHANGE_COLLECTION_FILTERS_DATA,
  DELETE_MULTIPLE_COLLECTION,
  DELETE_MULTIPLE_COLLECTION_SUCCESS,
  DELETE_MULTIPLE_COLLECTION_FAILED,
  SET_DELETE_MULTIPLE_COLLECTION_ERROR_FALSE,
  SET_REDIRECT_TO_COLLECTIONPAGE_TURE,
  SET_REDIRECT_TO_COLLECTIONPAGE_FALSE,
  SET_COLLECTION_ID,
} from "redux/constants/Collection";

const initState = {
  collectionForm: false,
  collectionData: null,
  collectionsData: null,
  collectionDataById: null,
  collectionByIdLoading: false,
  getCollectionByIdErrorMessage: null,
  collectionFilteredData: null,
  loading: false,
  createCollectionLoading: false,
  collectionMessage: "",
  createCollectionErrorMessage: false,
  collectionShowErrorMessage: false,
  collectionShowSuccessMessage: false,
  modalIsValid: false,
  deleteCollectionLoading: false,
  allowCollectionRedirect: false,
  saveCollectionLoading: false,
  addedToCollectionLength: 0,
  collectionDetailsPagination: { current: 1, pageSize: 10 },
  collectionPagination: { current: 1, pageSize: 10 },
  collectionFiltersData: { date: new Date("2022-01-01") },
  deleteMultipleCollectionError: false,
  redirectToCollectionPage: { isValid: false, collectionId: null },
  collectionId: null,
};

const collection = (state = initState, action) => {
  switch (action.type) {
    case SHOW_COLLECTION_FORM:
      return {
        ...state,
        collectionForm: true,
      };
    case HIDE_COLLECTION_FORM:
      return {
        ...state,
        collectionForm: false,
      };

    case CREATE_COLLECTION:
      return {
        ...state,
        createCollectionLoading: true,
      };
    case SET_COLLECTION_DATA:
      return {
        ...state,
        collectionData: action.data,
        // collectionsData: null,
        collectionForm: false,
        createCollectionLoading: false,
        addedToCollectionLength: 0,
      };

    case CLOSE_COLLECTION:
      return {
        ...state,
        collectionData: null,
        addedToCollectionLength: 0,
      };
    case ADD_NEW_PROPERTIES_TO_COLLECTION:
      return {
        ...state,
        collectionData: action.data.collectionData,
        collectionForm: false,
        addedToCollectionLength: action.data.length,
      };
    case CREATE_COLLECTION_FAILED:
      return {
        ...state,
        createCollectionErrorMessage: true,
        collectionMessage: action.message,
        createCollectionLoading: false,
      };
    case ADD_PROPERTY_TO_COLLECTION:
      return {
        ...state,
        addedToCollectionLength: state.addedToCollectionLength + 1,
      };
    case REMOVE_PROPERTY_FROM_COLLECTION:
      return {
        ...state,
        addedToCollectionLength: state.addedToCollectionLength - 1,
      };

    case HIDE_ERROR_MESSAGE:
      return {
        ...state,
        createCollectionErrorMessage: false,
        collectionMessage: "",
      };
    case GET_ALL_COLLECTIONS:
      return {
        ...state,
        loading: true,
      };
    case SET_COLLECTIONS_DATA:
      return {
        ...state,
        loading: false,
        collectionsData: action.data,
      };
    case GET_ALL_COLLECTIONS_FAILED:
      return {
        ...state,
        collectionMessage: action.message,
        collectionShowErrorMessage: true,
      };
    case SHOW_COLLECTION_MODAL:
      return {
        ...state,
        modalIsValid: true,
      };
    case HIDE_COLLECTION_MODAL:
      return {
        ...state,
        modalIsValid: false,
      };
    case DELETE_COLLECTION_BY_ID:
      return {
        ...state,
        deleteCollectionLoading: true,
      };
    case DELETE_COLLECTION_BY_ID_SUCCESS:
      return {
        ...state,
        deleteCollectionLoading: false,
        allowCollectionRedirect: true,
        collectionMessage: action.message,
        collectionShowSuccessMessage: true,
      };
    case DELETE_COLLECTION_BY_ID_FAILED:
      return {
        ...state,
        deleteCollectionLoading: false,
        collectionMessage: action.message,
        collectionShowErrorMessage: true,
      };
    case GET_COLLECTION_BY_ID:
      return {
        ...state,
        collectionByIdLoading: true,
        collectionDataById: null,
      };
    case GET_COLLECTION_BY_ID_SUCCESS:
      return {
        ...state,
        collectionByIdLoading: false,
        collectionDataById: action.data,
      };
    case GET_COLLECTION_BY_ID_FAILED:
      return {
        ...state,
        collectionByIdLoading: false,
        getCollectionByIdErrorMessage: action.message,
      };
    case SET_COLLECTION_DATA_BY_ID_NULL:
      return {
        ...state,
        collectionDataById: null,
      };
    case HIDE_COLLECTION_MESSAGE:
      return {
        ...state,
        collectionMessage: "",
        collectionShowErrorMessage: false,
        collectionShowSuccessMessage: false,
      };
    case DISALLOW_COLLECTION_REDIRECT:
      return {
        ...state,
        allowCollectionRedirect: false,
      };
    case SET_COLLECTION_DETAILS_PAGINATION:
      return {
        ...state,
        collectionDetailsPagination: action.data,
      };
    case SET_COLLECTION_PAGINATION:
      return {
        ...state,
        collectionPagination: action.data,
      };
    case APPLY_COLLECTION_FILTERS:
      return {
        ...state,
        loading: true,
      };
    case SET_COLLECTION_FILTERED_DATA:
      return {
        ...state,
        loading: false,
        collectionFilteredData: action.data,
      };
    case MAKE_COLLECTION_FILTERED_DATA_NULL:
      return {
        ...state,
        collectionFilteredData: null,
      };
    case RESET_COLLECTION_FILTERS:
      return {
        ...state,
        collectionFilteredData: state.collectionsData,
        collectionFiltersData: { date: new Date("2022-01-01") },
      };
    case CHANGE_COLLECTION_FILTERS_DATA:
      return {
        ...state,
        collectionFiltersData: action.data,
      };
    case MAKE_COLLECTIONS_DATA_NULL:
      return {
        ...state,
        collectionsData: null,
      };
    case DELETE_MULTIPLE_COLLECTION:
      return {
        ...state,
        loading: true,
      };
    case DELETE_MULTIPLE_COLLECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        collectionsData: action.data,
      };
    case DELETE_MULTIPLE_COLLECTION_FAILED:
      return {
        ...state,
        loading: false,
        collectionsData: state.collectionsData,
        deleteMultipleCollectionError: true,
      };
    case SET_DELETE_MULTIPLE_COLLECTION_ERROR_FALSE:
      return {
        ...state,
        deleteMultipleCollectionError: false,
      };
    case SET_REDIRECT_TO_COLLECTIONPAGE_TURE:
      return {
        ...state,
        redirectToCollectionPage: { isValid: true, collectionId: action.id },
      };
    case SET_REDIRECT_TO_COLLECTIONPAGE_FALSE:
      return {
        ...state,
        redirectToCollectionPage: { isValid: false, collectionId: null },
      };
    case SET_COLLECTION_ID:
      return {
        ...state,
        collectionId: action.collectionId,
      };
    default:
      return state;
  }
};

export default collection;
