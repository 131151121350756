import { Table } from "antd";
import { dateFormatHandler } from "configs/TableConstants";


const columns = [
    {
        key: "id",
        title: "Listing ID",
        render: (record) => (
            <p className="property-database-heading8">{record.listing_id}</p>
        ),
    },
    {
        key: "dateListed",
        title: "Date Listed",
        render: (record) => {
            const date = record.listed_date ? dateFormatHandler(new Date(record.listed_date)) : "N/A";
            return <p className="property-database-heading8">{date}</p>;
        },

    },
    {
        key: "dateUnlisted",
        title: "Date Unlisted",
        render: (record) => {
            const date = record.unlisted_date ? dateFormatHandler(new Date(record.unlisted_date)) : "N/A";
            return <p className="property-database-heading8">{date}</p>;
        },
    },

];
const PropertyDetailsListed = ({ propertyData }) => {
    const { property_database_listings } = propertyData;
    return (
        <div style={{ marginTop: "15px" }}>
            {property_database_listings.length > 0 && (
                <>
                    <p className="property-database-heading7">Listings</p>
                    <Table
                        dataSource={propertyData.property_database_listings}
                        columns={columns}
                        rowKey="listing_id"
                    />
                </>
            )}
        </div>
    );
};

export default PropertyDetailsListed;
