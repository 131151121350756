import {
  SHOW_COLLECTION_FORM,
  HIDE_COLLECTION_FORM,
  CREATE_COLLECTION,
  SET_COLLECTION_DATA,
  GET_ALL_COLLECTIONS,
  SET_COLLECTIONS_DATA,
  GET_ALL_COLLECTIONS_FAILED,
  GET_COLLECTION_BY_ID,
  CREATE_COLLECTION_FAILED,
  HIDE_ERROR_MESSAGE,
  SHOW_COLLECTION_MODAL,
  HIDE_COLLECTION_MODAL,
  DELETE_COLLECTION_BY_ID,
  DELETE_COLLECTION_BY_ID_SUCCESS,
  HIDE_COLLECTION_MESSAGE,
  DELETE_COLLECTION_BY_ID_FAILED,
  DISALLOW_COLLECTION_REDIRECT,
  ADD_PROPERTY_TO_COLLECTION,
  ADD_NEW_PROPERTIES_TO_COLLECTION,
  SET_COLLECTION_DETAILS_PAGINATION,
  SET_COLLECTION_PAGINATION,
  REMOVE_PROPERTY_FROM_COLLECTION,
  GET_COLLECTION_BY_ID_SUCCESS,
  APPLY_COLLECTION_FILTERS,
  SET_COLLECTION_FILTERED_DATA,
  RESET_COLLECTION_FILTERS,
  MAKE_COLLECTION_FILTERED_DATA_NULL,
  MAKE_COLLECTIONS_DATA_NULL,
  CLOSE_COLLECTION,
  CHANGE_COLLECTION_FILTERS_DATA,
  DELETE_MULTIPLE_COLLECTION,
  DELETE_MULTIPLE_COLLECTION_SUCCESS,
  DELETE_MULTIPLE_COLLECTION_FAILED,
  SET_DELETE_MULTIPLE_COLLECTION_ERROR_FALSE,
  SET_REDIRECT_TO_COLLECTIONPAGE_TURE,
  SET_REDIRECT_TO_COLLECTIONPAGE_FALSE,
  GET_COLLECTION_BY_ID_FAILED,
  SET_COLLECTION_DATA_BY_ID_NULL,
  SET_COLLECTION_ID,
} from "redux/constants/Collection";

export const showCollectionForm = () => {
  return {
    type: SHOW_COLLECTION_FORM,
  };
};
export const hideCollectionForm = () => {
  return {
    type: HIDE_COLLECTION_FORM,
  };
};

export const createCollection = (collectionData) => {
  return {
    type: CREATE_COLLECTION,
    collectionData,
  };
};

export const createCollectionSuccess = (data) => {
  return {
    type: SET_COLLECTION_DATA,
    data,
  };
};

export const addNewPropertiesToCollection = (data) => {
  return {
    type: ADD_NEW_PROPERTIES_TO_COLLECTION,
    data,
  };
};

export const createCollectionFailed = (message) => {
  return {
    type: CREATE_COLLECTION_FAILED,
    message,
  };
};

export const getAllCollections = () => {
  return {
    type: GET_ALL_COLLECTIONS,
  };
};

export const getAllCollectionsFailed = (message) => {
  return {
    type: GET_ALL_COLLECTIONS_FAILED,
    message,
  };
};

export const getAllCollectionsSuccess = (data) => {
  return {
    type: SET_COLLECTIONS_DATA,
    data,
  };
};

export const getCollectionById = (id) => {
  return {
    type: GET_COLLECTION_BY_ID,
    id,
  };
};

export const getCollectionByIdSuccess = (data) => {
  return {
    type: GET_COLLECTION_BY_ID_SUCCESS,
    data,
  };
};
export const getCollectionByIdFailed = (message) => {
  return {
    type: GET_COLLECTION_BY_ID_FAILED,
    message,
  };
};

export const hideErrorMessage = () => {
  return {
    type: HIDE_ERROR_MESSAGE,
  };
};

export const showCollectionModal = () => {
  return {
    type: SHOW_COLLECTION_MODAL,
  };
};

export const hideCollectionModal = () => {
  return {
    type: HIDE_COLLECTION_MODAL,
  };
};

export const deleteCollectionById = (id) => {
  return {
    type: DELETE_COLLECTION_BY_ID,
    id,
  };
};

export const deleteCollectionByIdSuccess = (message) => {
  return {
    type: DELETE_COLLECTION_BY_ID_SUCCESS,
    message,
  };
};

export const deleteCollectionByIdFailed = (message) => {
  return {
    type: DELETE_COLLECTION_BY_ID_FAILED,
    message,
  };
};

export const hideCollectionMessage = () => {
  return {
    type: HIDE_COLLECTION_MESSAGE,
  };
};

export const disallowCollectionRedirect = () => {
  return {
    type: DISALLOW_COLLECTION_REDIRECT,
  };
};

export const addPropertyToCollection = () => {
  return {
    type: ADD_PROPERTY_TO_COLLECTION,
  };
};

export const removePropertyFromCollection = () => {
  return {
    type: REMOVE_PROPERTY_FROM_COLLECTION,
  };
};

export const setCollectionDetailsPagination = (data) => {
  return {
    type: SET_COLLECTION_DETAILS_PAGINATION,
    data,
  };
};
export const setCollectionPagination = (data) => {
  return {
    type: SET_COLLECTION_PAGINATION,
    data,
  };
};

export const applyCollectionFilters = () => {
  return {
    type: APPLY_COLLECTION_FILTERS,
  };
};

export const setCollectionFilteredData = (data) => {
  return {
    type: SET_COLLECTION_FILTERED_DATA,
    data,
  };
};

export const changeCollectionFiltersData = (data) => {
  return {
    type: CHANGE_COLLECTION_FILTERS_DATA,
    data,
  };
};

export const resetCollectionFilters = () => {
  return {
    type: RESET_COLLECTION_FILTERS,
  };
};

export const makeCollectionFilteredDataNull = () => {
  return {
    type: MAKE_COLLECTION_FILTERED_DATA_NULL,
  };
};

export const makeCollectionsDataNull = () => {
  return {
    type: MAKE_COLLECTIONS_DATA_NULL,
  };
};

export const closeCollection = () => {
  return {
    type: CLOSE_COLLECTION,
  };
};
export const deleteMultipleCollection = (collectionIds) => {
  return {
    type: DELETE_MULTIPLE_COLLECTION,
    collectionIds,
  };
};

export const deleteMultipleCollectionSuccess = (data) => {
  return {
    type: DELETE_MULTIPLE_COLLECTION_SUCCESS,
    data,
  };
};
export const deleteMultipleCollectionFailed = () => {
  return {
    type: DELETE_MULTIPLE_COLLECTION_FAILED,
  };
};

export const setDeleteMultipleCollectionErrorFalse = () => {
  return {
    type: SET_DELETE_MULTIPLE_COLLECTION_ERROR_FALSE,
  };
};

export const setRedirectToCollectionPageTrue = (id) => {
  return {
    type: SET_REDIRECT_TO_COLLECTIONPAGE_TURE,
    id,
  };
};

export const setRedirectToCollectionPageFalse = () => {
  return {
    type: SET_REDIRECT_TO_COLLECTIONPAGE_FALSE,
  };
};

export const setCollectionDataByIdNull = () => {
  return {
    type: SET_COLLECTION_DATA_BY_ID_NULL,
  };
};

export const setCollectionId = (collectionId) => {
  return {
    type: SET_COLLECTION_ID,
    collectionId,
  };
};
