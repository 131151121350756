import { useEffect } from "react";
import { connect } from "react-redux";
import { LeftOutlined } from "@ant-design/icons";
import { useParams, useHistory } from "react-router-dom";
import { Spin } from "antd";
import {
  getManualReviewDataById,
  preventManualReviewApplyingFilters,
  deleteManualReviewDataById,
  hideDeleteModal,
  showDeleteModal,
  hideDeleteErrorMessage,
} from "redux/actions/ManualReview";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import AlertMessage from "components/layout-components/AlertMessage";
import { DeleteErrorModal } from "components/layout-components/CustomModal";
import { hideFilters } from "redux/actions/PropertyDatabase";
import MainWrapper from "components/property-database-compnents/MainWrapper";
import PropertyDetailsHeading from "views/app-views/PropertyDatabase/PropertyDetails/PropertyDetailsHeading";
import PropertyDetailsCarousel from "views/app-views/PropertyDatabase/PropertyDetails/PropertyDetailsCarousel";
import PropertyDetailsSummary from "views/app-views/PropertyDatabase/PropertyDetails/PropertyDetailsSummary";
import PropertyDetailsDescription from "views/app-views/PropertyDatabase/PropertyDetails/PropertyDetailsDescription";
import PropertyDetailsExternalLinks from "views/app-views/PropertyDatabase/PropertyDetails/PropertyDetailsExternalLinks";
import PropertyDetailsAgents from "views/app-views/PropertyDatabase/PropertyDetails/PropertyDetailsAgents";
import PropertyDetailsListings from "views/app-views/PropertyDatabase/PropertyDetails/PropertyDetailsListings";
const ManualReviewDetails = (props) => {
  const {
    manualReviewDataById,
    manualReviewDataByIdErrorMessage,
    getManualReviewDataById,
    hideFilters,
    preventManualReviewApplyingFilters,
    deleteManualReviewDataById,
    hideDeleteModal,
    showDeleteModal,
    manualReviewDeleteModalIsValid,
    manualReviewdeleteDataByIdLoading,
    manualReviewdeleteError,
    manualReviewdeleteErrorMessage,
    hideDeleteErrorMessage,
    manualReviewdeleteSuccess,
    session,
  } = props;
  const { propertyId } = useParams();
  const history = useHistory();

  useEffect(() => {
    getManualReviewDataById(+propertyId);
    hideFilters();
    preventManualReviewApplyingFilters();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (manualReviewdeleteError) {
        hideDeleteErrorMessage();
      }
    }, 3000);
    //eslint-disable-next-line
  }, [manualReviewdeleteError]);

  useEffect(() => {
    if (manualReviewdeleteSuccess) history.replace(`${APP_PREFIX_PATH}/review`);
    //eslint-disable-next-line
  }, [manualReviewdeleteSuccess]);

  const backToDatabase = (
    <div
      className="back-to-database"
      onClick={() => history.push(`${APP_PREFIX_PATH}/review`)}
    >
      <LeftOutlined style={{ fontSize: "12px" }} />{" "}
      <span> Back to Manual Review</span>
    </div>
  );

  if (!manualReviewDataById) {
    return (
      <div style={{ textAlign: "center" }}>
        {manualReviewDataByIdErrorMessage ? (
          <p>{manualReviewDataByIdErrorMessage}</p>
        ) : (
          <Spin />
        )}
      </div>
    );
  } else {
    return (
      <>
        <AlertMessage
          alertType="error"
          showMessage={manualReviewdeleteError}
          message={manualReviewdeleteErrorMessage}
          width="195px"
        />
        <DeleteErrorModal
          modalIsValid={manualReviewDeleteModalIsValid}
          hideModal={hideDeleteModal}
          deleteDataByID={deleteManualReviewDataById}
          id={manualReviewDataById[0].properties_id}
        />
        {session && <>{backToDatabase}</>}
        <MainWrapper marginTop="10px">
          <div>
            <PropertyDetailsHeading
              propertyData={manualReviewDataById[0]}
              loading={manualReviewdeleteDataByIdLoading}
              showModal={showDeleteModal}
              editManualReviewPropertyIsValid={true}
              session={session}
            />
            <PropertyDetailsCarousel images={manualReviewDataById[0].images} />
            <PropertyDetailsSummary propertyData={manualReviewDataById[0]} />
            <PropertyDetailsDescription
              propertyData={manualReviewDataById[0]}
            />
            <PropertyDetailsExternalLinks
              propertyData={manualReviewDataById[0]}
            />
            <PropertyDetailsAgents propertyData={manualReviewDataById[0]} />
            <PropertyDetailsListings propertyData={manualReviewDataById[0]} />
          </div>
        </MainWrapper>
        {session && <>{backToDatabase}</>}
      </>
    );
  }
};

const mapStateToProps = ({ manualReview, auth }) => {
  const {
    manualReviewDataById,
    manualReviewDataByIdErrorMessage,
    manualReviewDeleteModalIsValid,
    manualReviewdeleteDataByIdLoading,
    manualReviewdeleteError,
    manualReviewdeleteErrorMessage,
    manualReviewdeleteSuccess,
  } = manualReview;
  const { session } = auth;
  return {
    manualReviewDataById,
    manualReviewDataByIdErrorMessage,
    manualReviewDeleteModalIsValid,
    manualReviewdeleteDataByIdLoading,
    manualReviewdeleteError,
    manualReviewdeleteErrorMessage,
    manualReviewdeleteSuccess,
    session,
  };
};

const mapDispatchToProps = {
  getManualReviewDataById,
  hideFilters,
  preventManualReviewApplyingFilters,
  deleteManualReviewDataById,
  hideDeleteModal,
  showDeleteModal,
  hideDeleteErrorMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManualReviewDetails);
