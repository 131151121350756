import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import { UserbackProvider } from "@userback/react";

const userbackToken = process.env.REACT_APP_USERBACK_TOKEN;

ReactDOM.render(
  <UserbackProvider token={userbackToken}>
    <App />
  </UserbackProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
