import {
  NUMBER_OF_SALES,
  SALES_VOLUME,
  MEDIAN_SALES_PRICE,
  AVERAGE_SALES_PRICE,
  LOCATION_BY_PARISH,
  LOCATION_BY_REGION,
  PRICE_BRACKET,
  ARV_BRACKET,
  YEARLY,
  QUARTERLY,
  YEAR_TO_DATE,
  YEAR_OVER_YEAR,
  QUARTER_TO_QUARTER,
  QUARTER_OVER_QUARTER,
  HOUSE,
  CONDO,
  APARTMENT,
  COMMERCIAL,
  LAND,
  FRACTIONAL,
  CATEGORY_ALL,
  WEST,
  CENTRAL,
  EAST,
  ARV_LUXURY,
  ARV_HIGH_MARKET,
  ARV_MID_MARKER,
  ARV_STARTER,
  PRICE_STARTER,
  PRICE_MID_MARKET,
  PRICE_HIGH_MARKET,
  PRICE_LUXURY,
  PRICE_TOP_LUXURY,
  LOCATION_VALUE_ALL,
  BRACKET_ALL,
  PARISH_ALL,
  REGION_ALL,
  LOCATION_BURMUDA,
} from "constants/ReortContentConstants";
import { citiesOption } from "./FiltersConfig";

export const indicatorOptions = [
  {
    title: " ",
    value: NUMBER_OF_SALES,
    label: "Number of sales",
  },
  {
    title: " ",
    value: SALES_VOLUME,
    label: "Sales volume",
  },
  {
    title: " ",
    value: MEDIAN_SALES_PRICE,
    label: "Median sales price",
  },
  {
    title: " ",
    value: AVERAGE_SALES_PRICE,
    label: "Average sales price",
  },
];

export const locationOptions = [
  {
    title: " ",
    value: LOCATION_BURMUDA,
    label: "Bermuda",
  },
  {
    title: " ",
    value: LOCATION_BY_PARISH,
    label: "By parish",
  },
  {
    title: " ",
    value: LOCATION_BY_REGION,
    label: "By region",
  },
];

export const bracketOptions = [
  {
    title: " ",
    value: PRICE_BRACKET,
    label: "Price bracket",
  },
  {
    title: " ",
    value: ARV_BRACKET,
    label: "ARV bracket",
  },
];

export const timePeriodOptions = [
  {
    title: " ",
    value: YEARLY,
    label: "Yearly",
  },
  {
    title: " ",
    value: QUARTERLY,
    label: "Quarterly",
  },
  {
    title: " ",
    value: YEAR_TO_DATE,
    label: "Year To Date",
  },
  {
    title: " ",
    value: YEAR_OVER_YEAR,
    label: "Year Over Year (YOY)",
  },
  {
    title: " ",
    value: QUARTER_TO_QUARTER,
    label: "Quarter To Quarter (QTQ)",
  },
  {
    title: " ",
    value: QUARTER_OVER_QUARTER,
    label: "Quarter Over Quarter (QOQ)",
  },
];

export const byRegionOptions = [
  {
    value: REGION_ALL,
    label: "All",
  },
  {
    value: WEST,
    label: "West",
  },
  {
    value: CENTRAL,
    label: "Central",
  },
  {
    value: EAST,
    label: "East",
  },
];

export const allLocationsOptions = [
  {
    value: LOCATION_VALUE_ALL,
    label: "All",
  },
  ...byRegionOptions,
  ...citiesOption,
];

export const priceBracketOptions = [
  {
    value: BRACKET_ALL,
    label: "All",
  },
  {
    value: PRICE_STARTER,
    label: "Starter (0 - 0.75M)",
  },
  {
    value: PRICE_MID_MARKET,
    label: "Mid market (0.75M - 1.5M)",
  },
  {
    value: PRICE_HIGH_MARKET,
    label: "High market (1.5M - 2.5M)",
  },
  {
    value: PRICE_LUXURY,
    label: "Luxury (2.5M - 5M)",
  },
  {
    value: PRICE_TOP_LUXURY,
    label: "Top-luxury (>5M)",
  },
];

export const arvBracketOptions = [
  {
    value: BRACKET_ALL,
    label: "All",
  },
  {
    value: ARV_STARTER,
    label: "Starter (0 - 0.0335M)",
  },
  {
    value: ARV_MID_MARKER,
    label: "Mid market (0.0335M- 0.063M)",
  },
  {
    value: ARV_HIGH_MARKET,
    label: "High market (0.063M - 0.126M)",
  },
  {
    value: ARV_LUXURY,
    label: "Luxury (>0.126M)",
  },
];

export const categoryOptions = [
  {
    value: CATEGORY_ALL,
    label: "All",
  },

  {
    value: HOUSE,
    label: "House",
  },
  {
    value: CONDO,
    label: "Condo",
  },
  {
    value: APARTMENT,
    label: "Apartment",
  },
  {
    value: COMMERCIAL,
    label: "Commercial",
  },
  {
    value: LAND,
    label: "Land",
  },
  {
    value: FRACTIONAL,
    label: "Fractional",
  },
];

export const reportCitiesOptions = [
  {
    value: PARISH_ALL,
    label: "All",
  },
  ...citiesOption,
];
