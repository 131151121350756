export const GETDATA = "GETDATA";
export const SETDATA = "SETDATA";
export const DELETEDATA = "DELETEDATA";
export const DELETEDATA_ID = "DELETEDATA_ID";
export const DELETEDATA_ID_SUCCESS = "DELETEDATA_ID_SUCCESS";
export const SHOW_SUCCESS_MESSAGE = "SHOW_SUCCESS_MESSAGE";
export const GET_PROPERTY_DATA_BY_ID = "GET_PROPERTY_DATA_BY_ID";
export const GET_PROPERTY_DATA_BY_ID_SUCCESS =
  "GET_PROPERTY_DATA_BY_ID_SUCCESS";
export const GET_PROPERTY_DATA_BY_ID_FAILED = "GET_PROPERTY_DATA_BY_ID_FAILED";
export const SHOW_ERROR_MESSAGE = "SHOW_ERROR_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const GETDATA_REQUEST_FAILED = "GETDATA_REQUEST_FAILED";
export const SHOW_FILTERS = "SHOW_FILTERS";
export const HIDE_FILTERS = "HIDE_FILTERS";
export const SHOW_LOADING = "SHOW_LOADING";
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const DISALLOW_REDIRECT = "DISALLOW_REDIRECT";
export const SHOW_LESS_FILTERS = "SHOW_LESS_FILTERS";
export const SHOW_ALL_FILTERS = "SHOW_ALL_FILTERS";
export const SAVE_EDITING_SUCCESS = "SAVE_EDITING_SUCCESS";
export const SET_DATA_BY_ID_NULL = "SET_DATA_BY_ID_NULL";
export const HIDE_DATA_BY_ID_ERROR = "HIDE_DATA_BY_ID_ERROR";
export const SET_PROPERTYDATABASE_PAGINATION =
  "SET_PROPERTYDATABASE_PAGINATION";
export const FILTERED_DATA = "FILTERED_DATA";
export const APPLY_PROPERTY_FILTERS = "APPLY_PROPERTY_FILTERS";
export const GET_FILTER_MAX_VALUES = "GET_FILTER_MAX_VALUES";
export const GET_FILTER_MAX_VALUES_FAILED = "GET_FILTER_MAX_VALUES_FAILED";
export const SET_FILTER_MAX_VALUES = "SET_FILTER_MAX_VALUES";
export const RESET_FILTERS = "RESET_FILTERS";
export const CHANGE_PROPERTY_FILTERS_DATA = "CHANGE_PROPERTY_FILTERS_DATA";
export const SET_REMOVE_BUTTON_STATE = "SET_REMOVE_BUTTON_STATE";
export const RESERT_REMOVE_BUTTON_STATE = "RESERT_REMOVE_BUTTON_STATE";
export const CHANGE_REMOVE_BUTTON_STATE = "CHANGE_REMOVE_BUTTON_STATE";
export const SET_PROPERTY_FILTER_LOADING = "SET_PROPERTY_FILTER_LOADING";
export const PREVENT_APPLYING_FILTERS = "PREVENT_APPLYING_FILTERS";
export const SET_PROPERTY_DATABASE_FILTERS_VALID =
  "SET_PROPERTY_DATABASE_FILTERS_VALID";
export const SET_PROPERTY_DATABASE_FILTERS_INVALID =
  "SET_PROPERTY_DATABASE_FILTERS_INVALID";
export const SET_BACK_TO_DATABASE_VALID = "BACK_TO_DATABASE_VALID";
export const SET_BACK_TO_DATABASE_INVALID = "SET_BACK_TO_DATABASE_INVALID";
export const GET_UNDEFINED_DATA_UNITS = "GET_UNDEFINED_DATA_UNITS";
export const GET_UNDEFINED_DATA_UNITS_SUCCESS =
  "GET_UNDEFINED_DATA_UNITS_SUCCESS";
export const GET_UNDEFINED_DATA_UNITS_FAILED =
  "GET_UNDEFINED_DATA_UNITS_FAILED";
export const MAKE_UNDEFINED_DATA_UNITS_INVALID =
  "MAKE_UNDEFINED_DATA_UNITS_INVALID";
export const MAKE_UNDEFINED_DATA_UNITS_VALID =
  "MAKE_UNDEFINED_DATA_UNITS_VALID";
export const GET_SOLD_IN_THE_PAST = "GET_SOLD_IN_THE_PAST";
export const GET_SOLD_IN_THE_PAST_SUCCESS = "GET_SOLD_IN_THE_PAST_SUCCESS";
export const GET_SOLD_IN_THE_PAST_FAILED = "GET_SOLD_IN_THE_PAST_FAILED";
export const MAKE_SOLD_IN_THE_PAST_INVALID = "MAKE_SOLD_IN_THE_PAST_INVALID";
export const MAKE_SOLD_IN_THE_PAST_VALID = "MAKE_SOLD_IN_THE_PAST_VALID";
export const GET_SALES_APPLICATION_NUMBERS = "GET_SALES_APPLICATION_NUMBERS";
export const GET_SALES_APPLICATION_NUMBERS_SUCCESS =
  "GET_SALES_APPLICATION_NUMBERS_SUCCESS";
export const GET_SALES_APPLICATION_NUMBERS_ERROR =
  "GET_SALES_APPLICATION_NUMBERS_ERROR";
