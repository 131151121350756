export const GET_MANUAL_REVIEW_DATA = "GET_MANUAL_REVIEW_DATA";
export const GET_MANUAL_REVIEW_DATA_SUCCESS = "GET_MANUAL_REVIEW_DATA_SUCCESS";
export const GET_MANUAL_REVIEW_DATA_FAILED = "GET_MANUAL_REVIEW_DATA_FAILED";
export const MANUAL_REVIEW_FILTERED_DATA = "MANUAL_REVIEW_FILTERED_DATA";
export const APPLY_MANUAL_REIVEW_FILTERS = "APPLY_MANUAL_REIVEW_FILTERS";
export const SET_MANUAL_REVIEW_FILTERS = "SET_MANUAL_REVIEW_FILTERS";
export const RESET_MANUAL_REVIEW_FILTERS = "RESET_MANUAL_REVIEW_FILTERS";
export const SET_MANUAL_REIVEW_FILTER_LOADING =
  "SET_MANUAL_REIVEW_FILTER_LOADING";
export const CHANGE_MANUAL_REVIEW_FILTERS_DATA =
  "CHANGE_MANUAL_REVIEW_FILTERS_DATA";
export const GET_MANUAL_REVIEW_FILTER_MAX_VALUES =
  "GET_MANUAL_REVIEW_FILTER_MAX_VALUES";
export const SET_MANUAL_REVIEW_FILTER_MAX_VALUES =
  "SET_MANUAL_REVIEW_FILTER_MAX_VALUES";
export const GET_MANUAL_REVIEW_FILTER_MAX_VALUES_FAILED =
  "GET_MANUAL_REVIEW_FILTER_MAX_VALUES_FAILED";
export const PREVENT_MANUAL_REVIEW_APPLYING_FILTERS =
  "PREVENT_MANUAL_REVIEW_APPLYING_FILTERS";
export const GET_MANUAL_REVIEW_DATA_BY_ID = "GET_MANUAL_REVIEW_DATA_BY_ID";
export const GET_MANUAL_REVIEW_DATA_BY_ID_SUCCESS =
  "GET_MANUAL_REVIEW_DATA_BY_ID_SUCCESS";
export const GET_MANUAL_REVIEW_DATA_BY_ID_FAILED =
  "GET_MANUAL_REVIEW_DATA_BY_ID_FAILED";
export const SET_MANUAL_REVIEW_DATA_BY_ID_NULL =
  "SET_MANUAL_REVIEW_DATA_BY_ID_NULL";
export const DELETE_MANUAL_REVIEW_DATA_BY_ID =
  "DELETE_MANUAL_REVIEW_DATA_BY_ID";
export const DELETE_MANUAL_REVIEW_DATA_BY_ID_SUCCESS =
  "DELETE_MANUAL_REVIEW_DATA_BY_ID_SUCCESS";
export const DELETE_MANUAL_REVIEW_DATA_BY_ID_FAILED =
  "DELETE_MANUAL_REVIEW_DATA_BY_ID_FAILED";
export const CLEAR_MANUAL_REVIEW_DATA_BY_ID_SUCCESS =
  "CLEAR_MANUAL_REVIEW_DATA_BY_ID_SUCCESS";
export const SET_MANUAL_REIVEW_PAGINATION = "SET_MANUAL_REIVEW_PAGINATION";
export const SHOW_DELETE_MODAL = "SHOW_DELETE_MODAL";
export const HIDE_DELETE_MODAL = "HIDE_DELETE_MODAL";
export const HIDE_DELETE_ERROR_MESSAGE = "HIDE_DELETE_ERROR_MESSAGE";
export const MAKE_MANUAL_REVIEW_DATA_NULL = "MAKE_MANUAL_REVIEW_DATA_NULL";
