const SliderTitle = (props) => {
  const { title, minValue, maxValue } = props;
  return (
    <div className="sidenav-title-wrapper">
      <p className="sidenav-heading1">{title}</p>
      <p className="sidenav-slider-values">
        {minValue} {maxValue ? "-" : ""} {maxValue}
      </p>
    </div>
  );
};

export default SliderTitle;
