const PropertyDetailsAgents = ({ propertyData }) => {
  let agentData = [];
  if (propertyData.property_database_listings.length > 0) {
    if (
      propertyData.property_database_listings[0].property_database_agents
        .length > 0
    ) {
      agentData =
        propertyData.property_database_listings[0].property_database_agents;
    }
  }
  return (
    <div style={{ marginTop: "30px" }}>
      {agentData.length > 0 && (
        <>
          <p className="property-database-heading7">Agents</p>
          {agentData.map((item) => (
            <div key={item.agent_id} className="property-details-div-agent">
              <p>{item.agent_name}</p>
              <p>{item.company}</p>
              <p>{item.email}</p>
              <p>{item.phone}</p>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default PropertyDetailsAgents;
