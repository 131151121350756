// To-be-used-later
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import millify from "millify";
import {
  CHART_1,
  CHART_2,
  CHART_3,
  QUARTERLY,
  QUARTER_OVER_QUARTER,
  QUARTER_TO_QUARTER,
  REGION_ALL,
  YEARLY,
  YEAR_OVER_YEAR,
  YEAR_TO_DATE,
} from "constants/ReortContentConstants";

import { generateYOYArray, generateYearsArray } from "../ChartFilters/utils";
import {
  chartDecider,
  chartThreeTableHeadingFinder,
  getChartDetails,
} from "../utils";

//tables-styles
const td_1 = { textTransform: "uppercase", paddingLeft: "10px" };
const tableStyles = {
  width: "100%",
  marginTop: "30px",
  marginBottom: "30px",
};

const getTRStyles = (index) => {
  return {
    height: "50px",
    backgroundColor: index % 2 === 0 ? "#E6EBF1" : "white",
    paddingLeft: "50px",
    border: "1px solid #E6EBF1",
  };
};

const ChartTalbe = ({ singleChartContent }) => {
  const years = generateYearsArray(
    Number(singleChartContent.reportContent.fromDate),
    Number(singleChartContent.reportContent.toDate)
  );

  const chartType = chartDecider(singleChartContent.reportContent);
  let cities = singleChartContent.reportContent.location.split(",");
  const chartThreeTableHeading = chartThreeTableHeadingFinder(
    singleChartContent.reportContent,
    chartType
  );
  const regions = ["West", "Central", "East"];

  const csvDataFinder = () => {
    let firstRow = [];
    let csvData;
    const years = generateYearsArray(
      Number(singleChartContent.reportContent.fromDate),
      Number(singleChartContent.reportContent.toDate)
    );
    try {
      if (
        singleChartContent.reportContent.timePeriod === YEARLY ||
        singleChartContent.reportContent.timePeriod === YEAR_OVER_YEAR ||
        singleChartContent.reportContent.timePeriod === YEAR_TO_DATE
      ) {
        firstRow.push("");
      } else {
        firstRow.push("Quarters");
      }
      if (
        chartType === CHART_2 &&
        singleChartContent.reportContent.timePeriod === QUARTERLY
      ) {
        firstRow.push(...cities);
        const data = singleChartContent.chartLabels?.map((labels, index) => ({
          [firstRow[0]]: labels,
          [firstRow[1]]: singleChartContent.chartData[0][index],
          [firstRow[2]]: singleChartContent.chartData[1][index],
          [firstRow[3]]: singleChartContent.chartData[2][index],
          [firstRow[4]]: singleChartContent.chartData[3][index],
          [firstRow[5]]: singleChartContent.chartData[4][index],
          [firstRow[6]]: singleChartContent.chartData[5][index],
          [firstRow[7]]: singleChartContent.chartData[6][index],
          [firstRow[8]]: singleChartContent.chartData[7][index],
          [firstRow[9]]: singleChartContent.chartData[8][index],
        }));
        csvData = data;
        return csvData;
      } else if (
        chartType === CHART_1 &&
        (singleChartContent.reportContent.timePeriod === YEARLY ||
          singleChartContent.reportContent.timePeriod === YEAR_TO_DATE ||
          singleChartContent.reportContent.timePeriod === QUARTERLY ||
          singleChartContent.reportContent.timePeriod === QUARTER_TO_QUARTER)
      ) {
        // firstRow = [...firstRow, ...years];
        firstRow.push(...years);
        if (
          singleChartContent.reportContent.timePeriod === YEAR_TO_DATE ||
          singleChartContent.reportContent.timePeriod === YEARLY
        ) {
          const data = singleChartContent.chartData?.map((data, index) => [
            "Data",
            ...data,
          ]);
          csvData = [firstRow, ...data];
          return csvData;
        } else {
          const data = singleChartContent.chartLabels?.map((labels, index) => {
            let values = { [firstRow[0]]: labels };
            for (let i = 1; i <= firstRow?.length - 1; i++) {
              values[firstRow[i]] = singleChartContent.chartData[i - 1][index];
            }
            return values;
          });
          csvData = data;
          return csvData;
        }
      } else if (
        chartType === CHART_1 &&
        (singleChartContent.reportContent.timePeriod === QUARTER_OVER_QUARTER ||
          singleChartContent.reportContent.timePeriod === YEAR_OVER_YEAR)
      ) {
        const yearToDateHeadings = years
          ?.slice(0, -1)
          ?.map((year) => `${year} to ${year + 1}`);
        firstRow?.push(...yearToDateHeadings);
        if (
          singleChartContent.reportContent.timePeriod === QUARTER_OVER_QUARTER
        ) {
          const data = singleChartContent.chartLabels?.map((labels, index) => {
            let values = { [firstRow[0]]: labels };
            for (let i = 1; i <= firstRow?.length - 1; i++) {
              values[firstRow[i]] = singleChartContent.chartData[i - 1][index];
            }
            return values;
          });
          csvData = data;
          return csvData;
        } else {
          const data = singleChartContent.chartData?.map((data, index) => [
            "Data",
            ...data,
          ]);
          csvData = [firstRow, ...data];
          return csvData;
        }
      } else if (
        chartType === CHART_2 &&
        (singleChartContent.reportContent.timePeriod === YEARLY ||
          singleChartContent.reportContent.timePeriod === YEAR_TO_DATE)
      ) {
        if (singleChartContent.reportContent.location === REGION_ALL) {
          cities = regions;
        }
        firstRow.push(...years);
        if (singleChartContent.reportContent.timePeriod === YEARLY) {
          const parishesData = singleChartContent.chartData?.map(
            (data, index) => [cities[index], ...data]
          );
          csvData = [firstRow, ...parishesData];
        }
        //TODO: YEAR_TO_DATE is not implemented for CHART_2
        //  else if (singleChartContent.reportContent.timePeriod === YEAR_TO_DATE) {
        //   const data = singleChartContent.chartData?.map((data, index) => [
        //     "Data",
        //     ...data,
        //   ]);
        //   csvData = [firstRow, ...data];
        // }
        return csvData;
      } else if (
        chartType === CHART_2 &&
        singleChartContent.reportContent.timePeriod === YEAR_OVER_YEAR
      ) {
        const yearToDateHeadings = years
          ?.slice(0, -1)
          ?.map((year) => `${year} to ${year + 1}`);
        firstRow?.push(...yearToDateHeadings);
        const data = singleChartContent.chartData?.map((data, index) => [
          cities[index],
          ...data,
        ]);
        csvData = [firstRow, ...data];
        return csvData;
      } else if (chartType === CHART_3) {
        firstRow.push(...chartThreeTableHeading);
        let values = { [firstRow?.[0]]: singleChartContent.chartLabels?.[0] };
        for (let k = 1; k <= firstRow?.length - 1; k++) {
          values[firstRow?.[k]] = singleChartContent.chartData?.[k - 1]?.[0];
        }
        csvData = [values];

        return csvData;
      }
      return [{ ERROR: "Failed to meet chart condition" }];
    } catch (error) {
      console.log(error);
      return [{ ERROR: "Failed to do the processing for the CSV file" }];
    }
  };
  const chartDetails = getChartDetails(singleChartContent.reportContent);

  return (
    <>
      <table style={tableStyles}>
        <thead>
          <tr style={{ height: "50px" }}>
            <th style={td_1}>
              {singleChartContent.reportContent.timePeriod === YEARLY ||
              singleChartContent.reportContent.timePeriod === YEAR_OVER_YEAR ||
              singleChartContent.reportContent.timePeriod === YEAR_TO_DATE
                ? ""
                : "Quarters"}
            </th>
            {chartType === CHART_2 &&
              singleChartContent.reportContent.timePeriod === QUARTERLY && (
                <>
                  {singleChartContent.reportContent.location === REGION_ALL ? (
                    <>
                      {regions.map((region, index) => (
                        <th key={`REGION-${index}`}>{region}</th>
                      ))}
                    </>
                  ) : (
                    <>
                      {" "}
                      {cities.map((city, index) => (
                        <th key={`CITY-${index}`}>{city}</th>
                      ))}
                    </>
                  )}
                </>
              )}
            {chartType === CHART_1 && (
              <>
                {singleChartContent.reportContent.timePeriod ===
                  YEAR_OVER_YEAR ||
                singleChartContent.reportContent.timePeriod ===
                  QUARTER_OVER_QUARTER ? (
                  <>
                    {generateYOYArray(
                      Number(singleChartContent.reportContent.fromDate),
                      Number(singleChartContent.reportContent.toDate)
                    ).map((year) => (
                      <th key={`YEAR-${year}`}>{year}</th>
                    ))}
                  </>
                ) : (
                  <>
                    {years.map((year) => (
                      <th key={`YEAR-${year}`}>{year}</th>
                    ))}
                  </>
                )}
              </>
            )}
            {chartType === CHART_2 &&
              (singleChartContent.reportContent.timePeriod === YEARLY ||
                singleChartContent.reportContent.timePeriod ===
                  YEAR_TO_DATE) && (
                <>
                  {years.map((year) => (
                    <th key={`YEAR-${year}`}>{year}</th>
                  ))}
                </>
              )}
            {chartType === CHART_2 &&
              singleChartContent.reportContent.timePeriod ===
                YEAR_OVER_YEAR && (
                <>
                  {years?.slice(0, -1)?.map((year) => {
                    return (
                      <th key={`YEAR-${year}`}>
                        {year} to {year + 1}
                      </th>
                    );
                  })}
                </>
              )}
            {chartType === CHART_3 && (
              <>
                {chartThreeTableHeading.map((price, index) => (
                  <th key={`PRICE-${index}`}>{price}</th>
                ))}
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {(singleChartContent.reportContent.timePeriod === YEARLY ||
            singleChartContent.reportContent.timePeriod === YEAR_OVER_YEAR ||
            singleChartContent.reportContent.timePeriod === YEAR_TO_DATE) && (
            <>
              {chartType === CHART_3 ? (
                <>
                  {singleChartContent.chartLabels.map((q, index) => {
                    return (
                      <tr key={`Q_ROW-${index}`} style={getTRStyles(index)}>
                        <td style={{ paddingLeft: "10px" }}>{q}</td>
                        {singleChartContent.chartData.map((item, i) => {
                          return (
                            <td key={`Q_TD-${i}`}>
                              {item[index] === Infinity
                                ? 0
                                : millify(item[index], {
                                    precision: 3,
                                  })}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
              ) : (
                <>
                  {singleChartContent.chartData.map((item, index) => {
                    return (
                      <tr key={`DATA-${index}`} style={getTRStyles(index)}>
                        {chartType === CHART_2 ? (
                          <td style={{ paddingLeft: "10px" }}>
                            {singleChartContent.reportContent.location ===
                            REGION_ALL
                              ? regions[index]
                              : cities[index]}
                          </td>
                        ) : (
                          <td style={{ paddingLeft: "10px" }}>Data</td>
                        )}
                        {item.map((i, index) => (
                          <td key={`TD-${index}`}>
                            {i === Infinity
                              ? 0
                              : millify(i, {
                                  precision: 3,
                                })}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </>
              )}
            </>
          )}
          {(singleChartContent.reportContent.timePeriod === QUARTERLY ||
            singleChartContent.reportContent.timePeriod ===
              QUARTER_OVER_QUARTER ||
            singleChartContent.reportContent.timePeriod ===
              QUARTER_TO_QUARTER) && (
            <>
              {singleChartContent.chartLabels.map((q, index) => {
                return (
                  <tr key={`Q_ROW-${index}`} style={getTRStyles(index)}>
                    <td style={{ paddingLeft: "10px" }}>{q}</td>
                    {singleChartContent.chartData.map((item, i) => {
                      return (
                        <td key={`Q_TD-${i}`}>
                          {item[index] === Infinity
                            ? 0
                            : millify(item[index], {
                                precision: 3,
                              })}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </>
          )}
        </tbody>
      </table>

      <CSVLink
        style={{ color: "#3E79F7", cursor: "pointer" }}
        data={csvDataFinder()}
        filename={`${chartDetails?.titleOne}${chartDetails.titleTwo}.csv`}
      >
        Download CSV file
      </CSVLink>
    </>
  );
};

const mapStateToProps = ({ report }) => {
  return {};
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ChartTalbe);
