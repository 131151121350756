import {
  APARTMENT,
  ARV_BRACKET,
  CENTRAL,
  COMMERCIAL,
  CONDO,
  EAST,
  FRACTIONAL,
  HOUSE,
  LAND,
  LOCATION_BURMUDA,
  LOCATION_BY_REGION,
  LOCATION_VALUE_ALL,
  MEDIAN_SALES_PRICE,
  REGION_ALL,
  WEST,
} from "constants/ReortContentConstants";
import { ARV_STARTER } from "constants/ReortContentConstants";
import { ARV_MID_MARKER } from "constants/ReortContentConstants";
import { ARV_HIGH_MARKET } from "constants/ReortContentConstants";
import { ARV_LUXURY } from "constants/ReortContentConstants";
import { PRICE_TOP_LUXURY } from "constants/ReortContentConstants";
import { PRICE_LUXURY } from "constants/ReortContentConstants";
import { PRICE_HIGH_MARKET } from "constants/ReortContentConstants";
import { PRICE_MID_MARKET } from "constants/ReortContentConstants";
import { PRICE_STARTER } from "constants/ReortContentConstants";
import { AVERAGE_SALES_PRICE } from "constants/ReortContentConstants";
import {
  BRACKET_ALL,
  CATEGORY_ALL,
  CHART_1,
  CHART_2,
  CHART_3,
  LOCATION_BY_PARISH,
  NUMBER_OF_SALES,
  PARISH_ALL,
  PRICE_BRACKET,
  QUARTERLY,
  QUARTER_OVER_QUARTER,
  QUARTER_TO_QUARTER,
  SALES_VOLUME,
  YEARLY,
  YEAR_OVER_YEAR,
  YEAR_TO_DATE,
} from "constants/ReortContentConstants";
const colors = [
  "#3835C8",
  "#4D78EF",
  "#8C43E6",
  "#F44273",
  "#FF9900",
  "#33CCCC",
  "#FF6347",
  "#7FFF00",
  "#FFD700",
  "#9ACD32",
  "#8A2BE2",
  "#00CED1",
  "#FF69B4",
  "#20B2AA",
  "#800000",
  "#228B22",
  "#DDA0DD",
  "#808080",
  "#FF4500",
  "#8B4513",
];

export const getRandomColor = (reportContent, index, chartType) => {
  const h = Math.floor(Math.random() * 16777215).toString(16);
  const randomColor = `#${h}`;
  if (chartType === CHART_1) {
    const color = colors?.[index];
    if (color) {
      return color;
    } else {
      return randomColor;
    }
  } else if (chartType === CHART_2) {
    if (reportContent.location === PARISH_ALL) {
      switch (index) {
        case 0:
          return "#3835C8";
        case 1:
          return "#4D78EF";
        case 2:
          return "#57B9F9";
        case 3:
          return "#ACD846";
        case 4:
          return "#5FCE88";
        case 5:
          return "#4A9264";
        case 6:
          return " #F7C75C";
        case 7:
          return "#ED7476";
        case 8:
          return "#CD5040";
        default:
          return randomColor;
      }
    }
    if (reportContent.location === REGION_ALL) {
      switch (index) {
        case 0:
          return "#3835C8";
        case 1:
          return "#ACD846";
        case 2:
          return "#F7C75C";
        default:
          return randomColor;
      }
    }
  } else if (chartType === CHART_3) {
    switch (index) {
      case 0:
        return "#FB82F7";
      case 1:
        return "#9FC62F";
      case 2:
        return "#C64D2F";
      case 3:
        return "#5E9453";
      case 4:
        return "#4D65B2";
      default:
        return randomColor;
    }
  }
  return randomColor;
};

export const chartDecider = (reportContent) => {
  //NEED-TO-FIX-THIS
  let chartThreeLocation;
  let locations = reportContent.location.split(",");

  if (
    reportContent.locationOption === LOCATION_BY_PARISH &&
    locations.length === 1
  ) {
    chartThreeLocation = true;
  } else {
    chartThreeLocation = false;
  }
  // if (
  //   (reportContent.locationOption === LOCATION_BY_PARISH ||
  //     reportContent.locationOption === LOCATION_BY_REGION) &&
  //   (reportContent.location === PARISH_ALL ||
  //     reportContent.location === REGION_ALL) &&
  //   reportContent.category !== CATEGORY_ALL &&
  //   reportContent.bracket !== BRACKET_ALL &&
  //   (reportContent.timePeriod === YEARLY ||
  //     reportContent.timePeriod === QUARTERLY ||
  //     reportContent.timePeriod === YEAR_OVER_YEAR)
  // ) {
  //   return CHART_2;
  // }
  if (
    (reportContent.locationOption === LOCATION_BY_PARISH ||
      reportContent.locationOption === LOCATION_BY_REGION) &&
    (reportContent.location === PARISH_ALL ||
      reportContent.location === REGION_ALL) &&
    (reportContent.timePeriod === YEARLY ||
      (reportContent.locationOption === LOCATION_BY_PARISH
        ? reportContent.timePeriod === QUARTERLY
        : false) ||
      reportContent.timePeriod === YEAR_OVER_YEAR)
  ) {
    return CHART_2;
  } else if (
    (reportContent.indicator === NUMBER_OF_SALES ||
      reportContent.indicator === SALES_VOLUME) &&
    (reportContent.timePeriod === YEARLY ||
      reportContent.timePeriod === QUARTERLY) &&
    reportContent.category !== CATEGORY_ALL &&
    reportContent.bracket === BRACKET_ALL &&
    chartThreeLocation
  ) {
    return CHART_3;
  } else {
    return CHART_1;
  }
};

export const charThreePriceLabels = [
  "0-0.75M",
  "0.75M-1.5M",
  "1.5M-2.5M",
  "2.5M-5M",
  ">5M",
];
export const arvBracketOptions = [
  "0-0.0335M",
  "0.0335M- 0.063M",
  "0.063M - 0.126M",
  ">0.126M",
];

export const chartAboveLabelDecider = (reportContent, index) => {
  const chartType = chartDecider(reportContent);
  if (chartType === CHART_2) {
    let locations;
    if (reportContent.location === REGION_ALL) {
      locations = ["West", "Central", "East"];
    } else {
      locations = reportContent.location.split(",");
    }

    return locations[index];
  } else if (chartType === CHART_3) {
    if (reportContent.bracketType === PRICE_BRACKET) {
      return charThreePriceLabels[index];
    } else {
      return arvBracketOptions[index];
    }
  } else if (chartType === CHART_1) {
    if (
      reportContent.timePeriod === YEARLY ||
      reportContent.timePeriod === YEAR_TO_DATE
    ) {
      return "Year";
    } else if (reportContent.timePeriod === YEAR_OVER_YEAR) {
      return "Year Over Year";
    } else if (reportContent.timePeriod === QUARTER_OVER_QUARTER) {
      return `${Number(reportContent.fromDate) + index + 1}/${
        Number(reportContent.fromDate) + index
      }`;
    } else if (
      reportContent.timePeriod === QUARTERLY ||
      reportContent.timePeriod === QUARTER_TO_QUARTER
    ) {
      return Number(reportContent.fromDate) + index;
    }
  }
};

export const chartThreeTableHeadingFinder = (reportContent, chartType) => {
  if (chartType === CHART_3) {
    const priceIsValid = reportContent.bracketType === PRICE_BRACKET;
    if (priceIsValid) {
      return charThreePriceLabels;
    } else {
      return arvBracketOptions;
    }
  } else {
    return [];
  }
};

export const getReportIndicator = (indicator) => {
  switch (indicator) {
    case NUMBER_OF_SALES:
      return "Number of Sales";
    case SALES_VOLUME:
      return "Volume of Sales";
    case AVERAGE_SALES_PRICE:
      return "Average Sales Price";
    case MEDIAN_SALES_PRICE:
      return "Median Sales Price";
    default:
      return "";
  }
};

const getTimePeriod = (timePeriod) => {
  switch (timePeriod) {
    case YEARLY:
      return "Yearly";
    case QUARTERLY:
      return "Quarterly";
    case YEAR_OVER_YEAR:
      return "Year over Year";
    case YEAR_TO_DATE:
      return "Year to Date";
    case QUARTER_OVER_QUARTER:
      return "Quarter over Quarter";
    case QUARTER_TO_QUARTER:
      return "Quarter to Quarter";
    default:
      return "";
  }
};
export const getBracketType = (bracketType) => {
  if (bracketType === PRICE_BRACKET) {
    return "price bracket";
  } else if (bracketType === ARV_BRACKET) {
    return "ARV bracket";
  }
};

const getLocationType = (locationOption, location) => {
  if (locationOption === LOCATION_BURMUDA) {
    return {
      option: "Bermuda",
      location: undefined,
    };
  }
  if (
    locationOption === LOCATION_BY_PARISH &&
    (location === PARISH_ALL || location === LOCATION_VALUE_ALL)
  ) {
    return {
      option: "Parish",
      location: "All",
    };
  }
  if (locationOption === LOCATION_BY_PARISH && location !== PARISH_ALL) {
    return {
      option: "Parish",
      location,
    };
  }
  if (locationOption === LOCATION_BY_REGION && location === REGION_ALL) {
    return {
      option: "Region",
      location: "All",
    };
  }
  if (locationOption === LOCATION_BY_REGION && location !== REGION_ALL) {
    let region;
    switch (location) {
      case WEST:
        region = "West";
        break;
      case CENTRAL:
        region = "Central";
        break;
      case EAST:
        region = "East";
        break;
      default:
        region = "";
    }
    return {
      option: "Region",
      location: region,
    };
  }
};

const getMillionIdentifier = (reportContent) => {
  if (
    reportContent.indicator !== NUMBER_OF_SALES &&
    (reportContent.timePeriod === YEARLY ||
      reportContent.timePeriod === YEAR_TO_DATE ||
      reportContent.timePeriod === QUARTERLY)
  ) {
    return "(mil. BMD)";
  } else {
    return ""; // (per property)
  }
};

const getCategory = (category) => {
  if (category === CATEGORY_ALL) {
    return undefined;
  } else {
    return category;
  }
};

const getBracket = (bracket) => {
  if (bracket === BRACKET_ALL) {
    return undefined;
  } else {
    return bracket;
  }
};

const getBrcketLabel = (bracet) => {
  switch (bracet) {
    case PRICE_STARTER:
    case ARV_STARTER:
      return "Starter Market";
    case PRICE_MID_MARKET:
    case ARV_MID_MARKER:
      return "Mid Market";
    case PRICE_HIGH_MARKET:
    case ARV_HIGH_MARKET:
      return "High Market";
    case PRICE_LUXURY:
    case ARV_LUXURY:
      return "Luxury Market";
    case PRICE_TOP_LUXURY:
      return "Top Luxury Market";
    default:
      return "";
  }
};
export const categoryPluralFinder = (category) => {
  switch (category) {
    case HOUSE:
      return "Houses";
    case CONDO:
      return "Condos";
    case APARTMENT:
      return "Apartments";
    case COMMERCIAL:
      return "Commercial prop.";
    case LAND:
      return "Land";
    case FRACTIONAL:
      return "Fractional";
    default:
      return category;
  }
};
export const getChartDetails = (reportContent) => {
  const reportIndicator = getReportIndicator(reportContent.indicator);
  const timePeriod = getTimePeriod(reportContent.timePeriod);
  const locationType = getLocationType(
    reportContent.locationOption,
    reportContent.location
  );
  const category = getCategory(reportContent.category);
  const categoryPlural = categoryPluralFinder(category);
  const bracket = getBracket(reportContent.bracket);
  const bracketLabel = getBrcketLabel(bracket);
  const millionIdentifier = getMillionIdentifier(reportContent);

  return {
    titleOne: `${timePeriod} ${reportIndicator} ${
      bracket ? `for ${bracketLabel}` : ""
    }
    ${
      category ? (bracket ? categoryPlural : `for ${category}`) : ""
    } ${millionIdentifier}`,
    titleTwo: `(${locationType.option}${
      locationType.location ? ` : ${locationType.location}` : ""
    })`,
  };
};
