import { Checkbox } from "antd";
const SideNavCheckBox = (props) => {
  const { options, data, setData, name } = props;
  const onChangeHandler = (checkedValues) => {
    setData({ ...data, [name]: checkedValues });
  };
  return (
    <>
      <Checkbox.Group
        options={options}
        defaultValue={data[name]}
        onChange={onChangeHandler}
        style={{ display: "flex", flexDirection: "column" }}
      />
    </>
  );
};
export default SideNavCheckBox;
