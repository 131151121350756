import {
  YEARLY,
  NUMBER_OF_SALES,
  AVERAGE_SALES_PRICE,
  MEDIAN_SALES_PRICE,
  SALES_VOLUME,
  QUARTERLY,
  PRICE_BRACKET,
  REGION_ALL,
  YEAR_OVER_YEAR,
  CATEGORY_ALL,
  BRACKET_ALL,
} from "constants/ReortContentConstants";

import {
  generateChartTwoQuartersArray,
  generateQuartersArray,
  generateYOYArray,
  generateYearsArray,
  getCurrentQuarter,
  getYearOverYearData,
  sumIndexes,
} from "./utils";

export const chartTwoFilters = (salesData, reportContent) => {
  // console.log("SALES_DATA", salesData);
  // console.log(reportContent);
  const priceIsValid = reportContent.bracketType === PRICE_BRACKET;
  const bracketRangeIsValid = reportContent.bracket !== BRACKET_ALL;
  let priceRange;
  let arvRange;
  if (priceIsValid) {
    priceRange = reportContent.bracket.split("-");
  } else {
    arvRange = reportContent.bracket.split("-");
  }
  let locations;

  locations = reportContent.location.split(",");
  // console.log("locations", locations);

  if (
    reportContent.timePeriod === YEARLY ||
    reportContent.timePeriod === YEAR_OVER_YEAR
  ) {
    const years = generateYearsArray(
      Number(reportContent.fromDate),
      Number(reportContent.toDate)
    );

    //eslint-disable-next-line
    const data = locations.map((l) => {
      // let priceVolume = 1;
      let countArr = [];
      let averageArr = [];
      let medianArr = [];
      let volumeArr = [];

      for (let year = 0; year < years.length; year++) {
        let count = 0;
        let priceCounter = 0;
        let average = 0;
        let priceArr = [];
        let median = 0;

        for (let sale = 0; sale < salesData.length; sale++) {
          const salesYear = new Date(
            salesData[sale].registration_date
          ).getFullYear();

          if (
            salesData[sale]?.parish.toLowerCase() === l.toLowerCase() &&
            (reportContent.category !== CATEGORY_ALL
              ? salesData[sale]?.property_type.toLowerCase() ===
                reportContent.category.toLowerCase()
              : true) &&
            (bracketRangeIsValid
              ? priceIsValid
                ? salesData[sale]?.price
                  ? salesData[sale].price >= Number(priceRange[0]) &&
                    salesData[sale].price < Number(priceRange[1])
                  : false
                : salesData[sale]?.combined_arv
                ? salesData[sale].combined_arv >= Number(arvRange[0]) &&
                  salesData[sale].combined_arv < Number(arvRange[1])
                : false
              : true) &&
            salesYear === Number(years[year])
          ) {
            count = count + 1;
            priceCounter = priceCounter + salesData[sale].price;
            priceArr.push(salesData[sale].price);
          }
        }
        if (reportContent.indicator === NUMBER_OF_SALES) {
          countArr.push(count);
        } else if (reportContent.indicator === AVERAGE_SALES_PRICE) {
          average = Math.round(priceCounter / count);
          averageArr.push(average);
        } else if (reportContent.indicator === MEDIAN_SALES_PRICE) {
          const mid = Math.floor(priceArr.length / 2);
          const priceArrSorted = priceArr.sort((a, b) => a - b);
          if (priceArr.length % 2 === 0) {
            median = (priceArrSorted[mid - 1] + priceArrSorted[mid]) / 2;
            medianArr.push(median);
          } else {
            median = priceArrSorted[mid];
            medianArr.push(median);
          }
        } else if (reportContent.indicator === SALES_VOLUME) {
          let priceVolume = 0;
          for (let i = 0; i < priceArr.length; i++) {
            priceVolume = priceVolume + priceArr[i];
          }
          volumeArr.push(Math.floor(priceVolume));
        }
      }
      if (reportContent.indicator === NUMBER_OF_SALES) {
        return countArr;
      } else if (reportContent.indicator === AVERAGE_SALES_PRICE) {
        return averageArr;
      } else if (reportContent.indicator === MEDIAN_SALES_PRICE) {
        return medianArr;
      } else if (reportContent.indicator === SALES_VOLUME) {
        return volumeArr;
      }
    });
    if (reportContent.location === REGION_ALL) {
      // console.log("Region_data", data);
      let result = [];
      let west = [];
      let central = [];
      let east = [];
      for (let acc = 0; acc <= 2; acc++) {
        west.push(data[acc]);
      }
      for (let acc = 3; acc <= 5; acc++) {
        central.push(data[acc]);
      }
      for (let acc = 6; acc <= 8; acc++) {
        east.push(data[acc]);
      }
      const westResult = sumIndexes(west).flat();
      result.push(westResult);
      const centralResult = sumIndexes(central).flat();
      result.push(centralResult);
      const eastResult = sumIndexes(east).flat();
      result.push(eastResult);

      if (reportContent.timePeriod === YEARLY) {
        // console.log("Region_result", result);
        return { data: result, years };
      } else if (reportContent.timePeriod === YEAR_OVER_YEAR) {
        const yearOverYearData = getYearOverYearData(result);
        // console.log("yearOveryear_REGION", yearOverYearData);
        return {
          data: yearOverYearData,
          years: generateYOYArray(
            Number(reportContent.fromDate),
            Number(reportContent.toDate)
          ),
        };
      }
    } else {
      if (reportContent.timePeriod === YEARLY) {
        // console.log("parish_yearly", data);
        return { data: data, years };
      } else if (reportContent.timePeriod === YEAR_OVER_YEAR) {
        // console.log("parish_yearly", data);
        const yearOverYearData = getYearOverYearData(data);

        // console.log("yearOverYear_PARISH", yearOverYearData);
        return {
          data: yearOverYearData,
          years: generateYOYArray(
            Number(reportContent.fromDate),
            Number(reportContent.toDate)
          ),
        };
      }
    }
  } else if (reportContent.timePeriod === QUARTERLY) {
    const years = generateYearsArray(
      Number(reportContent.fromDate),
      Number(reportContent.toDate)
    );
    let quarters;
    const quartersLabels = generateChartTwoQuartersArray(
      Number(reportContent.fromDate),
      Number(reportContent.toDate)
    );
    //eslint-disable-next-line
    const data = locations.map((l) => {
      let countArr = [];
      let averageArr = [];
      let medianArr = [];
      let volumeArr = [];

      for (let y = 0; y < years.length; y++) {
        quarters = generateQuartersArray(years[y]);
        let yearCountArr = [];
        let yearAverageArr = [];
        let yearMedianArr = [];
        let yearVolumeArr = [];

        for (let quarter = 0; quarter < quarters.length; quarter++) {
          let count = 0;
          let priceCounter = 0;
          let average = 0;
          let priceArr = [];
          let median = 0;
          for (let sale = 0; sale < salesData.length; sale++) {
            const salesYear = new Date(
              salesData[sale].registration_date
            ).getFullYear();
            const currentQuarter = getCurrentQuarter(
              new Date(salesData[sale].registration_date)
            );

            if (
              (reportContent.category !== CATEGORY_ALL
                ? salesData[sale].property_type.toLowerCase() ===
                  reportContent.category.toLowerCase()
                : true) &&
              salesData[sale].parish.toLowerCase() === l.toLowerCase() &&
              (bracketRangeIsValid
                ? priceIsValid
                  ? salesData[sale].price
                    ? salesData[sale].price >= Number(priceRange[0]) &&
                      salesData[sale].price < Number(priceRange[1])
                    : false
                  : salesData[sale].combined_arv
                  ? salesData[sale].combined_arv >= Number(arvRange[0]) &&
                    salesData[sale].combined_arv < Number(arvRange[1])
                  : false
                : true) &&
              currentQuarter === quarter + 1 &&
              salesYear === years[y]
            ) {
              count = count + 1;
              priceCounter = priceCounter + salesData[sale].price;
              priceArr.push(salesData[sale].price);
            }
          }
          if (reportContent.indicator === NUMBER_OF_SALES) {
            yearCountArr.push(count);
          } else if (reportContent.indicator === AVERAGE_SALES_PRICE) {
            average = Math.round(priceCounter / count);
            yearAverageArr.push(average);
          } else if (reportContent.indicator === MEDIAN_SALES_PRICE) {
            const mid = Math.floor(priceArr.length / 2);
            const priceArrSorted = priceArr.sort((a, b) => a - b);
            if (priceArr.length % 2 === 0) {
              median = (priceArrSorted[mid - 1] + priceArrSorted[mid]) / 2;
              yearMedianArr.push(median);
            } else {
              median = priceArrSorted[mid];
              yearMedianArr.push(median);
            }
          } else if (reportContent.indicator === SALES_VOLUME) {
            let priceVolume = 0;
            for (let i = 0; i < priceArr.length; i++) {
              priceVolume = priceVolume + priceArr[i];
            }
            yearVolumeArr.push(Math.floor(priceVolume));
          }
        }
        if (reportContent.indicator === NUMBER_OF_SALES) {
          countArr.push(...yearCountArr);
        } else if (reportContent.indicator === AVERAGE_SALES_PRICE) {
          averageArr.push(...yearAverageArr);
        } else if (reportContent.indicator === MEDIAN_SALES_PRICE) {
          medianArr = [...medianArr, ...yearMedianArr];
        } else if (reportContent.indicator === SALES_VOLUME) {
          volumeArr = [...volumeArr, ...yearVolumeArr];
        }
      }
      if (reportContent.indicator === NUMBER_OF_SALES) {
        return countArr;
      } else if (reportContent.indicator === AVERAGE_SALES_PRICE) {
        return averageArr;
      } else if (reportContent.indicator === MEDIAN_SALES_PRICE) {
        return medianArr;
      } else if (reportContent.indicator === SALES_VOLUME) {
        return volumeArr;
      }
    });
    if (reportContent.location === REGION_ALL) {
      let result = [];
      let west = [];
      let central = [];
      let east = [];
      for (let acc = 0; acc <= 2; acc++) {
        west.push(data[acc]);
      }
      for (let acc = 3; acc <= 5; acc++) {
        central.push(data[acc]);
      }
      for (let acc = 6; acc <= 9; acc++) {
        east.push(data[acc]);
      }
      const westResult = sumIndexes(west).flat();
      result.push(westResult);
      const centralResult = sumIndexes(central).flat();
      result.push(centralResult);
      const eastResult = sumIndexes(east).flat();
      result.push(eastResult);
      // console.log("quarterly_Region", result);
      return { data: result, years: quartersLabels };
    } else {
      if (reportContent.timePeriod === QUARTERLY) {
        // console.log("quarterly_Parishes", data);
        return { data, years: quartersLabels };
      }
    }
  }
};
