import { Button } from "antd";
import * as React from "react";
import { useReactToPrint } from "react-to-print";
import { PDFDownloadLink } from "@react-pdf/renderer";
import classes from "./styles.module.css";
import MyDocument from "./GeneratePDF";
import { dateFormatHandler } from "configs/TableConstants";
import { ComponentToPrint } from "./ComponentToPrint";
const CollectionReportButtons = (props) => {
  const { data, dateValue, loading, handlePrint } = props;

  return (
    <div className={classes.buttonsWrapper}>
      <PDFDownloadLink
        document={<MyDocument data={data} dateValue={dateValue} />}
        fileName={`${data.title}-${Date.now()}.pdf`}
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            "Loading document..."
          ) : (
            <Button type="primary" className={classes.leftButton}>
              Download PDF
            </Button>
          )
        }
      </PDFDownloadLink>
      <Button
        type="primary"
        className={classes.rightButton}
        onClick={handlePrint}
        loading={loading}
      >
        Print
      </Button>
    </div>
  );
};

const FunctionalComponentWithHook = ({ collectionsDataById }) => {
  const componentRef = React.useRef(null);

  const onBeforeGetContentResolve = React.useRef(null);

  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called"); // tslint:disable-line no-console
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called"); // tslint:disable-line no-console
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
    //eslint-disable-next-line
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: `${collectionsDataById[0].title}-${Date.now()}`,
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
    pageStyle: "A4",
  });

  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
    //eslint-disable-next-line
  }, [onBeforeGetContentResolve.current, text]);

  return (
    <div>
      <CollectionReportButtons
        data={collectionsDataById[0]}
        dateValue={dateFormatHandler(
          new Date(collectionsDataById[0].created_on)
        )}
        handlePrint={handlePrint}
        loading={loading}
      />
      <ComponentToPrint
        ref={componentRef}
        text={text}
        collectionsDataById={collectionsDataById}
      />
    </div>
  );
};
export default FunctionalComponentWithHook;
