import { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { Bar } from "react-chartjs-2";
import { Button, Typography } from "antd";
import { CHART_2, CHART_3 } from "constants/ReortContentConstants";
import {
  getTrendReportDetails,
  setTrendReportDetailsNull,
} from "redux/actions/Report";
import { chartFilters } from "../NewReport/ChartFilters";
import {
  chartAboveLabelDecider,
  chartDecider,
  getChartDetails,
  getRandomColor,
  getReportIndicator,
} from "../NewReport/utils";
import { chartTwoFilters } from "../NewReport/ChartFilters/ChartTwo";
import { chartThreeFilters } from "../NewReport/ChartFilters/ChartThree";
import millify from "millify";
import ChartTable from "../NewReport/components/ChartTable";

const SingleReportDetails = ({ salesData, singleReportContent }) => {
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [tableIsValid, setTableIsValid] = useState(false);

  let chartDetails;
  if (singleReportContent) {
    chartDetails = getChartDetails(singleReportContent);
  }

  const data = useMemo(() => {
    const chartType = chartDecider(singleReportContent);
    if (singleReportContent) {
      return {
        labels: chartLabels,
        datasets: chartData.map((item, index) => ({
          label: chartAboveLabelDecider(singleReportContent, index),
          backgroundColor: getRandomColor(singleReportContent, index, chartType),
          borderColor: getRandomColor(singleReportContent, index, chartType),
          data: item,
        })),
      };
    }

    //eslint-disable-next-line
  }, [chartData, chartLabels]);

  const options = useMemo(() => {
    return {
      scales: {
        y: {
          ticks: {
            callback: function (value) {
              return millify(value);
            },
          },
          title: {
            display: true,
            text: getReportIndicator(singleReportContent?.indicator),
            font: {
              size: 12,
              weight: 500,
            },
          }
        },
      },
    }

    //eslint-disable-next-line
  }, [singleReportContent]);





  useEffect(() => {
    if (singleReportContent) {
      const chartType = chartDecider(singleReportContent);
      let labels, chartData;
      if (chartType === CHART_2) {
        const { data, years } = chartTwoFilters(salesData, singleReportContent);
        labels = years;
        chartData = data;
      } else if (chartType === CHART_3) {
        const { data, years } = chartThreeFilters(
          salesData,
          singleReportContent
        );
        labels = years;
        chartData = data;
      } else {
        const { data, years } = chartFilters(salesData, singleReportContent);
        labels = years;
        chartData = data;
      }
      setChartLabels(labels);
      setChartData(chartData);
    }
    //eslint-disable-next-line
  }, [singleReportContent]);
  return (
    <>
      {singleReportContent && (
        <>
          {chartData.length === 0 ? (
            <div>Generating chart.......</div>
          ) : (
            <>
              <div className="chart-styles">
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: "20px",
                  }}
                >
                  {singleReportContent.title}
                </Typography>
                <Typography className="chart-details-heading">
                  {chartDetails.titleOne}
                </Typography>
                <Typography className="chart-details-heading2">
                  {chartDetails.titleTwo}
                </Typography>

                <Bar data={data} options={options} />
                <Typography className="chart-details-heading2">
                  {singleReportContent.comment}
                </Typography>
              </div>
              {tableIsValid && (
                <ChartTable
                  singleChartContent={{
                    reportContent: singleReportContent,
                    chartData: chartData,
                    chartLabels: chartLabels,
                  }}
                />
              )}
              <div className="chart-btn-wrapper">
                <div className="chart-btn-div-1"></div>
                <div className="chart-btn-div-2">
                  {tableIsValid ? (
                    <Button
                      type="primary"
                      className="button-text"
                      onClick={() => setTableIsValid(false)}
                    >
                      Hide data table
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      className="button-text"
                      onClick={() => setTableIsValid(true)}
                    >
                      Show data table
                    </Button>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ report }) => {
  const { salesData } = report;
  return {
    salesData,
  };
};

const mapDispatchToProps = {
  getTrendReportDetails,
  setTrendReportDetailsNull,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleReportDetails);
