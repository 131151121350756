export const collectionFilters = (data, filteringData) => {
  return new Promise((resolve, reject) => {
    let filteredData = data.filter((item) => {
      let dateAdded = new Date(item.created_on);
      let dateMinValue = new Date(filteringData.date);

      return dateAdded >= dateMinValue;
    });
    resolve(filteredData);
  });
};
