export const generateYearsArray = (startYear, endYear) => {
  let years = [];
  for (let year = startYear; year <= endYear; year++) {
    years.push(year);
  }
  return years;
};

export const generateYOYArray = (startYear, endYear) => {
  let years = [];
  for (let year = startYear + 1; year <= endYear; year++) {
    years.push(`${year - 1} to ${year} `);
  }
  return years;
};

export const generateQuartersArray = (year) => {
  const quarters = [`Q1`, `Q2`, `Q3`, `Q4`];
  return quarters;
};

export const generateChartTwoQuartersArray = (startYear, endYear) => {
  let quarters = [];
  for (let year = startYear; year <= endYear; year++) {
    for (let q = 1; q <= 4; q++) {
      quarters.push(`${year}Q${q}`);
    }
  }
  return quarters;
};

export const getCurrentQuarter = (date) => {
  return Math.floor(date.getMonth() / 3 + 1);
};

export const charThreePriceData = [
  {
    type: "Starter",
    range: [0, 750000],
  },
  {
    type: "Mid market",
    range: [750000, 1500000],
  },
  {
    type: "High market",
    range: [1500000, 2500000],
  },
  {
    type: "Luxury",
    range: [2500000, 5000000],
  },
  {
    type: "Top-luxury",
    range: [5000000, Infinity],
  },
];

export const charThreeARVData = [
  {
    type: "Starter",
    range: [0, 33500],
  },
  {
    type: "Mid market",
    range: [33500, 63000],
  },
  {
    type: "High market",
    range: [63000, 126000],
  },
  {
    type: "Luxury",
    range: [126000, Infinity],
  },
];

export const sumIndexes = (array) => {
  const result = [];
  for (let i = 0; i < array[0].length; i++) {
    let sum = 0;
    for (let j = 0; j < array.length; j++) {
      sum += array[j][i];
    }
    result.push(sum);
  }
  return result;
};

export const getYearOverYearData = (data) => {
  let yearOverYear = [];
  console.log("Region_result", data);
  for (let yIndex = 0; yIndex < data.length; yIndex++) {
    let yearOverYearPerRegion = [];
    for (let kIndex = 1; kIndex < data[yIndex].length; kIndex++) {
      yearOverYearPerRegion.push(
        (data[yIndex][kIndex] - data[yIndex][kIndex - 1]) /
          data[yIndex][kIndex - 1]
      );
    }
    yearOverYear.push(yearOverYearPerRegion);
  }
  return yearOverYear;
};
