import { Table } from "antd";
import { Popover } from "antd";
import { dateFormatHandler } from "configs/TableConstants";
//RANDOM_DATA

const columns = [
  {
    key: "id",
    title: "Listing ID",
    render: (record) => (
      <p className="property-database-heading8">{record.listing_id}</p>
    ),
  },
  {
    key: "price",
    title: "Price",
    render: (record) => {
      if (record.price) {
        return (
          <p className="property-database-heading8">
            ${record.price.toLocaleString()}
          </p>
        );
      } else {
        return <></>;
      }
    },
  },

  {
    render: (record) => {
      let propertyStatus;
      if (record.is_rent) propertyStatus = "For Rent";
      else if (record.is_sale) propertyStatus = "For Sale";
      else propertyStatus = null;
      return <p className="property-database-heading8">{propertyStatus}</p>;
    },
  },
  {
    key: "date",
    title: "Date Added",
    render: (record) => {
      const date = dateFormatHandler(new Date(record.date_added));
      return <p className="property-database-heading8">{date}</p>;
    },
  },
  {
    key: "agents",
    title: "Agents",
    render: (record) => {
      return (
        <>
          {record.property_database_agents.map((item) => {
            const content = (
              <div key={item.agent_id}>
                <p className="property-database-heading8">{item.phone}</p>
                <p className="property-database-heading8">{item.email}</p>
                <p className="property-database-heading8">{item.company}</p>
              </div>
            );
            return (
              <Popover content={content} key={item.agent_id}>
                <p
                  className="property-database-heading8"
                  style={{ cursor: "pointer" }}
                >
                  {item.agent_name}
                </p>
              </Popover>
            );
          })}
        </>
      );
    },
  },
];
const PropertyDetailsListings = ({ propertyData }) => {
  const { property_database_listings } = propertyData;
  return (
    <div style={{ marginTop: "30px" }}>
      {property_database_listings.length > 0 && (
        <>
          <p className="property-database-heading7">Listings</p>
          <Table
            dataSource={propertyData.property_database_listings}
            columns={columns}
            rowKey="listing_id"
          />
        </>
      )}
    </div>
  );
};

export default PropertyDetailsListings;
