import { DatePicker } from "antd";
import moment from "moment";

const AntdDatePicker = (props) => {
  const { title, data, setData, name } = props;
  const dateFormat = "MM/DD/YYYY";
  const dobDateHandler = (date, dateString) => {
    const newDate = new Date(dateString);
    setData({ ...data, [name]: newDate });
  };

  return (
    <div className="sidenav-filters--div3">
      <p className="sidenav-heading1">{title}</p>
      <DatePicker
        defaultValue={
          data[name] ? moment(new Date(data[name]), dateFormat) : ""
        }
        format={dateFormat}
        onChange={dobDateHandler}
        allowClear={false}
        getPopupContainer={(trigger) => trigger.parentElement}
        style={{ width: "100%" }}
      />
    </div>
  );
};

export default AntdDatePicker;
