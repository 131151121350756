import { useEffect } from "react";
import { Button, Spin } from "antd";
import { connect } from "react-redux";
import AntdDatePicker from "./filters-componets/AntdDatePicker";
import SideNavCheckBox from "./filters-componets/SideNavCheckBox";
import SideNavSelectBox from "./filters-componets/SideNavSelectBox";
import SliderTitle from "./filters-componets/SliderTitle";
import SwitchFilter from "./filters-componets/SwitchFilter";
import RangeSlider from "react-range-slider-input";
import { manualReviewFilters } from "views/app-views/PropertyDatabase/PropertyFilters/ManualReviewFilters";
import InputValueBox from "./filters-componets/InputValueBox";

import {
  setManualReivewFilteredData,
  applyManualReivewFilters,
  resetManualReviewFilters,
  changeManualReviewFiltersData,
  setManualReviewFilterLoading,
} from "redux/actions/ManualReview";
import {
  citiesFilterOption,
  propertyTypeFilterOptions,
  statusFilterOptions,
  antIcon,
  valueInMillion,
  roomsOption,
} from "configs/FiltersConfig";

const ManualReviewFilters = (props) => {
  const {
    manualReviewData,
    manualReviewFiltersMaxValues,
    manualReviewFiltersLoading,
    manualReviewfilterResetMessage,
    manualReviewFiltersData,
    applyManualReviewFiltersIsValid,
    setManualReivewFilteredData,
    applyManualReivewFilters,
    resetManualReviewFilters,
    changeManualReviewFiltersData,
    setManualReviewFilterLoading,
  } = props;
  let priceMaxValue, bedroomsMaxValue, bathRoomsMaxValue;

  if (manualReviewFiltersMaxValues.length > 0) {
    priceMaxValue = Number(manualReviewFiltersMaxValues[0]);
    bedroomsMaxValue = Number(manualReviewFiltersMaxValues[3]);
    bathRoomsMaxValue = Number(manualReviewFiltersMaxValues[4]);
  }

  // To be used later

  // const [marks, setMarks] = useState({
  //   bedRoomMarks: {},
  //   bathRoomMarks: {},
  //   priceMarks: {},
  //   arvMarks: {},
  // });
  // useEffect(() => {
  //   if (manualReviewFiltersMaxValues.length > 0) {
  //     setMarks({
  //       bedRoomMarks: {
  //         0: "Min",
  //         [bedroomsMaxValue]: "Max",
  //       },
  //       bathRoomMarks: {
  //         0: "Min",
  //         [bathRoomsMaxValue]: "Max",
  //       },
  //       priceMarks: {
  //         0: "Min",
  //         [priceMaxValue]: "Max",
  //       },
  //       arvMarks: {
  //         0: "Min",
  //         [arvMaxVale]: "Max",
  //       },
  //     });
  //   }

  //eslint-disable-next-line
  // }, [manualReviewFiltersMaxValues.length]);

  const propertyFilterHandler = async (data, filters) => {
    applyManualReivewFilters();
    const filteredData = await manualReviewFilters(
      data,
      filters,
      bedroomsMaxValue,
      bathRoomsMaxValue
    );

    setManualReivewFilteredData(filteredData);
  };

  useEffect(() => {
    if (
      manualReviewFiltersData.price[1] !== null &&
      manualReviewData !== null &&
      applyManualReviewFiltersIsValid
    ) {
      propertyFilterHandler(manualReviewData, manualReviewFiltersData);
    }
    //eslint-disable-next-line
  }, [manualReviewFiltersData, applyManualReviewFiltersIsValid]);

  const resetManualReviewFiltersHanlder = () => {
    setManualReviewFilterLoading();
    setTimeout(() => {
      resetManualReviewFilters(manualReviewFiltersMaxValues);
    }, 300);
  };

  return (
    <>
      {manualReviewFiltersLoading ? (
        <div style={{ textAlign: "center", marginTop: "25px" }}>
          <Spin indicator={antIcon} />
        </div>
      ) : (
        <>
          {manualReviewFiltersMaxValues.length > 0 ? (
            <div className="sidenav-filters" style={{ position: "relative" }}>
              <div className="sidenav-filters--div1">
                <div
                  style={{ padding: "0px", cursor: "default" }}
                  className="sidenav-filter-button"
                >
                  Filter
                </div>

                <div
                  style={{ padding: "0px", cursor: "pointer" }}
                  className="sidenav-filter-button"
                  onClick={() => resetManualReviewFiltersHanlder()}
                >
                  Reset all
                </div>
              </div>

              <div className="sidenav-filters--div2">
                <p className="sidenav-heading1">Parish</p>
                <div className="sidenav-scrollbar">
                  <SideNavCheckBox
                    options={citiesFilterOption}
                    data={manualReviewFiltersData}
                    setData={changeManualReviewFiltersData}
                    name="cities"
                  />
                </div>
              </div>

              <SideNavSelectBox
                title="Property type"
                optionsList={propertyTypeFilterOptions}
                data={manualReviewFiltersData}
                setData={changeManualReviewFiltersData}
                name="propertyType"
                value={manualReviewFiltersData.propertyType}
                mode="multiple"
              />
              <SideNavSelectBox
                title="Status"
                optionsList={statusFilterOptions}
                data={manualReviewFiltersData}
                setData={changeManualReviewFiltersData}
                name="status"
                value={manualReviewFiltersData.status}
                mode="multiple"
              />

              <AntdDatePicker
                title="From date"
                data={manualReviewFiltersData}
                setData={changeManualReviewFiltersData}
                name="minDate"
              />
              <AntdDatePicker
                title="To date"
                data={manualReviewFiltersData}
                setData={changeManualReviewFiltersData}
                name="maxDate"
              />
              {/* To be added later
    
                  <div className="sidenav-filters--div4">
                    <p className="sidenav-heading1">Size (sq ft)</p>
                    <Slider
                      range
                      marks={marks}
                      defaultValue={[0, 1000]}
                      style={{ width: 200 }}
                    />
                  </div> */}
              <div className="sidenav-filters--div4">
                <SliderTitle
                  title="Price ($)"
                  minValue={valueInMillion(manualReviewFiltersData.price[0])}
                  maxValue={valueInMillion(manualReviewFiltersData.price[1])}
                />
                <RangeSlider
                  min={0}
                  max={priceMaxValue}
                  step={priceMaxValue / 200}
                  onInput={(value) =>
                    changeManualReviewFiltersData({
                      ...manualReviewFiltersData,
                      price: value,
                    })
                  }
                  defaultValue={[
                    manualReviewFiltersData.price[0],
                    manualReviewFiltersData.price[1] === null
                      ? priceMaxValue
                      : manualReviewFiltersData.price[1],
                  ]}
                  style={{ width: 200, marginTop: "20px" }}
                />
              </div>
              <InputValueBox
                minValue={manualReviewFiltersData.price[0]}
                maxValue={manualReviewFiltersData.price[1]}
                onValueChange={changeManualReviewFiltersData}
                propertyFiltersData={manualReviewFiltersData}
                name="price"
              />

              <div className="sidenav-filters--div8">
                <SliderTitle
                  title="Number of bedrooms"
                  minValue=""
                  maxValue=""
                />
                <SideNavCheckBox
                  options={roomsOption}
                  data={manualReviewFiltersData}
                  setData={changeManualReviewFiltersData}
                  name="bedrooms"
                />
              </div>
              <div className="sidenav-filters--div8">
                <SliderTitle
                  title="Number of bathrooms"
                  minValue=""
                  maxValue=""
                />
                <SideNavCheckBox
                  options={roomsOption}
                  data={manualReviewFiltersData}
                  setData={changeManualReviewFiltersData}
                  name="bathrooms"
                />
              </div>
              <div style={{ marginTop: "15px" }}>
                {/* <p className="sidenav-heading1">
                  Toggle to see already fixed properties
                </p> */}
                <SwitchFilter
                  title="Show already fixed"
                  data={manualReviewFiltersData}
                  setData={changeManualReviewFiltersData}
                  name="importedToPropertiesDatabase"
                  value={manualReviewFiltersData.importedToPropertiesDatabase}
                  marginTopIsValid={false}
                />
              </div>
            </div>
          ) : (
            <div className="filed-error-wrapper">
              {manualReviewfilterResetMessage ? (
                <div>
                  <p>Filters can't be applided</p>

                  <Button type="link" style={{ padding: "0px" }}>
                    Try Again
                  </Button>
                </div>
              ) : (
                <div className="filed-error-wrapper">
                  <p>Filters can't be applided</p>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ manualReview }) => {
  const {
    manualReviewData,
    manualReviewFiltersMaxValues,
    manualReviewFiltersLoading,
    manualReviewfilterResetMessage,
    manualReviewFiltersData,
    applyManualReviewFiltersIsValid,
  } = manualReview;
  return {
    manualReviewData,
    manualReviewFiltersMaxValues,
    manualReviewFiltersLoading,
    manualReviewfilterResetMessage,
    manualReviewFiltersData,
    applyManualReviewFiltersIsValid,
  };
};

const mapDispatchToProps = {
  setManualReivewFilteredData,
  applyManualReivewFilters,
  resetManualReviewFilters,
  changeManualReviewFiltersData,
  setManualReviewFilterLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManualReviewFilters);
