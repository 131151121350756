import React, { Fragment } from "react";
import { connect } from "react-redux";
import SideNav from "components/layout-components/SideNav";
import Loading from "components/shared-components/Loading";
import MobileNav from "components/layout-components/MobileNav";
import HeaderNav from "components/layout-components/HeaderNav";
import AppViews from "views/app-views";
import { Layout, Grid } from "antd";

import navigationConfig from "configs/NavigationConfig";
import {
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
} from "constants/ThemeConstant";
import utils from "utils";
import { useThemeSwitcher } from "react-css-theme-switcher";

const { useBreakpoint } = Grid;

export const AppLayout = ({ navType, location, children }) => {
  const currentRouteInfo = utils.getRouteInfo(
    navigationConfig,
    location.pathname
  );
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes("lg");
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;

  const { status } = useThemeSwitcher();

  if (status === "loading") {
    return <Loading cover="page" />;
  }


  return (
    <Fragment>
      <Layout>
        <HeaderNav isMobile={isMobile} />
        <div style={{ display: "flex" }}>
          {isNavSide && !isMobile ? (
            <SideNav routeInfo={currentRouteInfo} />
          ) : null}

          <div className={`app-content ${isNavTop ? "layout-top-nav" : ""}`}>
            {children ? <>{children}</> : <AppViews />}
          </div>
        </div>
        {isMobile && <MobileNav />}
      </Layout>
    </Fragment>

  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } = theme;
  return { navCollapsed, navType, locale };
};

export default connect(mapStateToProps)(React.memo(AppLayout));


