import { supabase } from "auth/SupabaseClient";

const SupabaseService = {};

SupabaseService.signInWithMagicLink = async (email) =>
  await supabase.auth.signIn(
    { email },
    {
      redirectTo: process.env.REACT_APP_SUPABASE_REDIRECT_URL_LOCAL,
    }
  );

SupabaseService.signOutRequest = async () => await supabase.auth.signOut();

SupabaseService.getAllData = async () =>
  await supabase.from("property_database").select(
    `*, sales (*),property_database_listings (
    *, property_database_agents (*)
  )`
  );
// .not("properties_skipper_id", "is", null);

SupabaseService.getSalesApplicationNumbers = async () =>
  await supabase.from("sales").select(`sales_id,
  application_number`);

SupabaseService.getUndefinedDataUnits = async () =>
  await supabase
    .from("property_database")
    .select(
      `*, sales (*),property_database_listings (
    *, property_database_agents (*)
  )`
    )
    .is("properties_skipper_id", null);

SupabaseService.getSoldInThePast = async () =>
  await supabase.from("property_database").select(
    `*, sales (*),property_database_listings (
      *, property_database_agents (*)
    )`
  );

SupabaseService.getAllManualData = async () =>
  await supabase.from("manual_review").select(
    `*,manual_review_listings (
    *, manual_review_agents (*)
  )`
  );

SupabaseService.getDataById = async (id) =>
  await supabase
    .from("property_database")
    .select(
      `*, sales (*),property_database_listings (
    *, property_database_agents (*)
  )`
    )
    .match({ assessment_number: id });

SupabaseService.getManualDataById = async (id) =>
  await supabase
    .from("manual_review")
    .select(
      `*,manual_review_listings (
      *, manual_review_agents (*)
    )`
    )
    .match({ properties_id: id });

SupabaseService.deleteManualReviewDataByID = async (id) =>
  await supabase.from("manual_review").delete().match({ properties_id: id });

SupabaseService.deleteDataByID = async (id) =>
  await supabase
    .from("property_database")
    .delete()
    .match({ assessment_number: id });

SupabaseService.createCollection = async (collectionData) =>
  await supabase.from("collections").insert([collectionData]);

SupabaseService.getAllCollections = async () =>
  await supabase
    .from("collections")
    .select(
      "*, collection_properties ( *, property_database ( *, property_database_listings (*) ) )"
    )
    .order("created_on", { ascending: false });

SupabaseService.getCollectionById = async (id) =>
  await supabase
    .from("collections")
    .select(
      "*, collection_properties ( *, property_database ( *, sales (*), property_database_listings (*) ) )"
    )
    .match({ collection_id: id });

SupabaseService.getCollectionsById = async (id) =>
  await supabase
    .from("collections")
    .select(
      "*, collection_properties ( *, property_database ( *, sales (*),property_database_listings (*) ) )"
    )
    .match({ collection_id: id });

SupabaseService.deleteReportsByID = async (id) =>
  await supabase.from("trend_report").delete().match({ trend_report_id: id });

SupabaseService.deleteCollectionByID = async (id) =>
  await supabase.from("collections").delete().match({ collection_id: id });

SupabaseService.getValuesForFilters = async () =>
  await supabase.rpc("get_max_values");

SupabaseService.getValuesForManualReviewFilters = async () =>
  await supabase.rpc("get_mr_max_values");

SupabaseService.getPriceMaxValue = async () =>
  await supabase.rpc("get_pd_price_max_values");

SupabaseService.getDateAddedMaxValue = async () =>
  await supabase.rpc("get_pd_date_added_max_values");

SupabaseService.getDateAddedMinValue = async () =>
  await supabase.rpc("get_pd_date_added_min_values");

SupabaseService.getBedroomsMaxValue = async () =>
  await supabase.rpc("get_pd_bedrooms_max_values");

SupabaseService.getBathroomsMaxValue = async () =>
  await supabase.rpc("get_pd_bathrooms_max_values");

SupabaseService.getARVMaxValue = async () =>
  await supabase.rpc("get_pd_new_arv_max_values");

SupabaseService.getValuesForManualReviewFilters = async () =>
  await supabase.rpc("get_mr_max_values");

SupabaseService.getMRPriceMaxValue = async () =>
  await supabase.rpc("get_mr_price_max_values");

SupabaseService.getMRDateAddedMaxValue = async () =>
  await supabase.rpc("get_mr_date_added_max_values");

SupabaseService.getMRDateAddedMinValue = async () =>
  await supabase.rpc("get_mr_date_added_min_values");

SupabaseService.getMRBedroomsMaxValue = async () =>
  await supabase.rpc("get_mr_bedrooms_max_values");

SupabaseService.getMRBathroomsMaxValue = async () =>
  await supabase.rpc("get_mr_bathrooms_max_values");

SupabaseService.getSalesPriceMaxValue = async () =>
  await supabase.rpc("get_sales_price_max_values");

SupabaseService.getSalesARVMaxValue = async () =>
  await supabase.rpc("get_sales_arv_max_values");

//Report-Content

SupabaseService.saveReportContent = async (data) =>
  await supabase.from("report_content").upsert(data);

SupabaseService.saveReport = async (data) =>
  await supabase.from("trend_report").insert([data]);

SupabaseService.getAllReportsData = async () =>
  await supabase.from("trend_report").select("*").order("created_on", { ascending: false });

SupabaseService.getAllSalesData = async () =>
  await supabase.from("sales_report");

SupabaseService.getTrendReportDetails = async (id) =>
  await supabase
    .from("trend_report")
    .select("*, report_content(*)")
    .match({ trend_report_id: id });

export default SupabaseService;
