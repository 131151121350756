import {
  GET_COLLECTIONS_DATA_BY_ID_FAILED,
  GET_COLLECTIONS_DATA_BY_ID_SUCCESS,
  MAKE_COLLECTIONS_DATA_BY_ID_NULL,
} from "redux/constants/CollectionReport";

const initState = {
  collectionsDataById: null,
  loading: true,
  errorMessage: null,
};
const collectionReport = (state = initState, action) => {
  switch (action.type) {
    case GET_COLLECTIONS_DATA_BY_ID_SUCCESS:
      return {
        ...state,
        collectionsDataById: action.data,
        loading: false,
      };
    case GET_COLLECTIONS_DATA_BY_ID_FAILED:
      return {
        ...state,
        errorMessage: action.message,
        loading: false,
      };
    case MAKE_COLLECTIONS_DATA_BY_ID_NULL: {
      return {
        ...state,
        collectionsDataById: null,
        loading: true,
        errorMessage: null,
      };
    }
    default:
      return state;
  }
};

export default collectionReport;
