import { FOR_RENT, FOR_SALE, RENTED, SOLD } from "./FiltersContants";
import { LoadingOutlined } from "@ant-design/icons";
import millify from "millify";

export const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

export const valueInMillion = (value) => {
  return millify(value, {
    precision: 2,
  });
};

export const propertyTypeOptions = [
  {
    title: " ",
    value: "house",
    label: "House",
  },
  {
    title: " ",
    value: "condo",
    label: "Condo",
  },
  {
    title: " ",
    value: "commercial",
    label: "Commercial",
  },
  {
    title: " ",
    value: "apartment",
    label: "Apartment",
  },
  {
    title: " ",
    value: "fractional",
    label: "Fractional",
  },
  {
    title: " ",
    value: "industrial",
    label: "Industrial",
  },
  {
    title: " ",
    value: "land",
    label: "Land",
  },
  {
    title: " ",
    value: "office",
    label: "Office",
  },
  {
    title: " ",
    value: "resort/hotel",
    label: "Resort/Hotel",
  },
  {
    title: " ",
    value: "social",
    label: "Social",
  },

  {
    title: " ",
    value: "tourism/house",
    label: "Tourism/House",
  },
  {
    title: " ",
    value: "vacation rental",
    label: "Vacation Rental",
  },
];

export const propertyTypeFilterOptions = [
  // {
  //   value: "All",
  //   label: "All",
  // },
  ...propertyTypeOptions,
];

export const statusFilterOptions = [
  {
    title: " ",
    value: FOR_SALE,
    label: "For Sale",
  },
  {
    title: " ",
    value: FOR_RENT,
    label: "For Rent",
  },
  {
    title: " ",
    value: RENTED,
    label: "Rented",
  },
  {
    title: " ",
    value: SOLD,
    label: "Sold",
  },
];

export const roomsOption = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4 or more",
  },
];

export const citiesOption = [
  {
    value: "Devonshire",
    label: "Devonshire",
  },
  {
    value: "Hamilton",
    label: "Hamilton",
  },
  {
    value: "Paget",
    label: "Paget",
  },
  {
    value: "Pembroke",
    label: "Pembroke",
  },
  {
    value: "Sandys",
    label: "Sandys",
  },
  {
    value: "Smiths",
    label: "Smith's",
  },
  {
    value: "Southampton",
    label: "Southampton",
  },
  {
    value: "St. George's",
    label: "St. George's",
  },
  {
    value: "Warwick",
    label: "Warwick",
  },
];

export const citiesFilterOption = [
  // {
  //   value: "All",
  //   label: "All",
  // },
  ...citiesOption,
];

export const FRACTIONAL_VALUE = "fractional";

export const usageOption = [
  {
    value: "commercial",
    label: "Commercial",
  },
  {
    value: "residential",
    label: "Residential",
  },
  {
    value: "house",
    label: "House",
  },
  {
    value: "condo",
    label: "Condo",
  },
  {
    title: " ",
    value: FRACTIONAL_VALUE,
    label: "Fractional",
  },
  {
    title: " ",
    value: "land",
    label: "Land",
  },
];
