import {
  GET_MANUAL_REVIEW_DATA,
  GET_MANUAL_REVIEW_DATA_SUCCESS,
  GET_MANUAL_REVIEW_DATA_FAILED,
  GET_MANUAL_REVIEW_FILTER_MAX_VALUES,
  GET_MANUAL_REVIEW_FILTER_MAX_VALUES_FAILED,
  SET_MANUAL_REVIEW_FILTER_MAX_VALUES,
  MANUAL_REVIEW_FILTERED_DATA,
  APPLY_MANUAL_REIVEW_FILTERS,
  RESET_MANUAL_REVIEW_FILTERS,
  CHANGE_MANUAL_REVIEW_FILTERS_DATA,
  SET_MANUAL_REIVEW_FILTER_LOADING,
  GET_MANUAL_REVIEW_DATA_BY_ID,
  GET_MANUAL_REVIEW_DATA_BY_ID_SUCCESS,
  GET_MANUAL_REVIEW_DATA_BY_ID_FAILED,
  PREVENT_MANUAL_REVIEW_APPLYING_FILTERS,
  SET_MANUAL_REVIEW_DATA_BY_ID_NULL,
  SET_MANUAL_REIVEW_PAGINATION,
  SHOW_DELETE_MODAL,
  HIDE_DELETE_MODAL,
  DELETE_MANUAL_REVIEW_DATA_BY_ID_SUCCESS,
  DELETE_MANUAL_REVIEW_DATA_BY_ID_FAILED,
  HIDE_DELETE_ERROR_MESSAGE,
  DELETE_MANUAL_REVIEW_DATA_BY_ID,
  CLEAR_MANUAL_REVIEW_DATA_BY_ID_SUCCESS,
  MAKE_MANUAL_REVIEW_DATA_NULL,
} from "../constants/ManualReview";

export const getManualReviewData = () => {
  return {
    type: GET_MANUAL_REVIEW_DATA,
  };
};

export const getManualReviewDataSuccess = (data) => {
  return {
    type: GET_MANUAL_REVIEW_DATA_SUCCESS,
    data,
  };
};

export const getManualReviewDataFailed = (message) => {
  return {
    type: GET_MANUAL_REVIEW_DATA_FAILED,
    message,
  };
};

export const getManualReviewMaxValues = () => {
  return {
    type: GET_MANUAL_REVIEW_FILTER_MAX_VALUES,
  };
};

export const setManualReviewMaxValues = (data) => {
  return {
    type: SET_MANUAL_REVIEW_FILTER_MAX_VALUES,
    data,
  };
};

export const getManualReviewMaxValuesFailed = () => {
  return {
    type: GET_MANUAL_REVIEW_FILTER_MAX_VALUES_FAILED,
  };
};

export const setManualReivewFilteredData = (data) => {
  return {
    type: MANUAL_REVIEW_FILTERED_DATA,
    data,
  };
};

export const preventManualReviewApplyingFilters = () => {
  return {
    type: PREVENT_MANUAL_REVIEW_APPLYING_FILTERS,
  };
};

export const applyManualReivewFilters = () => {
  return {
    type: APPLY_MANUAL_REIVEW_FILTERS,
  };
};

export const resetManualReviewFilters = (data) => {
  return {
    type: RESET_MANUAL_REVIEW_FILTERS,
    data,
  };
};

export const changeManualReviewFiltersData = (data) => {
  return {
    type: CHANGE_MANUAL_REVIEW_FILTERS_DATA,
    data,
  };
};

export const setManualReviewFilterLoading = () => {
  return {
    type: SET_MANUAL_REIVEW_FILTER_LOADING,
  };
};

export const getManualReviewDataById = (id) => {
  return {
    type: GET_MANUAL_REVIEW_DATA_BY_ID,
    id,
  };
};

export const getManualReviewDataByIdSuccess = (data) => {
  return {
    type: GET_MANUAL_REVIEW_DATA_BY_ID_SUCCESS,
    data,
  };
};
export const getManualReviewDataByIdFailed = (message) => {
  return {
    type: GET_MANUAL_REVIEW_DATA_BY_ID_FAILED,
    message,
  };
};

export const setManualReviewDataByIdNull = () => {
  return {
    type: SET_MANUAL_REVIEW_DATA_BY_ID_NULL,
  };
};
export const deleteManualReviewDataById = (id) => {
  return {
    type: DELETE_MANUAL_REVIEW_DATA_BY_ID,
    id,
  };
};

export const deleteManualReviewDataByIdSuccess = (message) => {
  return {
    type: DELETE_MANUAL_REVIEW_DATA_BY_ID_SUCCESS,
    message,
  };
};
export const deleteManualReviewDataByIdFailed = (message) => {
  return {
    type: DELETE_MANUAL_REVIEW_DATA_BY_ID_FAILED,
    message,
  };
};

export const clearManualReviewDataByIdSuccess = () => {
  return {
    type: CLEAR_MANUAL_REVIEW_DATA_BY_ID_SUCCESS,
  };
};

export const setManualReviewPagination = (data) => {
  return {
    type: SET_MANUAL_REIVEW_PAGINATION,
    data,
  };
};

export const showDeleteModal = () => {
  return {
    type: SHOW_DELETE_MODAL,
  };
};

export const hideDeleteModal = () => {
  return {
    type: HIDE_DELETE_MODAL,
  };
};

export const hideDeleteErrorMessage = () => {
  return {
    type: HIDE_DELETE_ERROR_MESSAGE,
  };
};

export const makeManualReviewDataNull = () => {
  return {
    type: MAKE_MANUAL_REVIEW_DATA_NULL,
  };
};
